import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const ContainerStyled = styled.div`
	@media only screen and (max-width: 960px) {
		padding: 20px;
	}
`;

export const FormContainerStyled = styled.form`
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media only screen and (max-width: 480px) {
		width: 90%;
	}
`;

export const ButtonContainerStyled = styled.div`
	width: 100%;
	text-align: right;
`;

export const useStyles = makeStyles((theme) => ({
	paper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "30px 0",
	},
	divider: {
		width: "100%",
		marginBottom: "40px",
		marginTop: "10px",
		height: "2px",
	},
	filesLabelContainer: {
		display: "flex",
		alignItems: "center",
	},
	documentationList: {
		listStyle: "none",
		padding: "20px",
	},
	listItemDocumentation: {
		display: "flex",
		alignItems: "center",
		marginBottom: "15px",
	},
	checkIcon: {
		marginRight: "8px",
		color: "green",
	},
	backdrop: {
		zIndex: "100 !important",
		color: "#fff",
	},
	nOperacionLabel: {
		fontSize: "17px",
		fontWeight: "600",
	},
	errorContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		padding: "2% 5% 5% 0%",
		color: "red"
	}
}));
