import {
	SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
	SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
	SINIESTRO_TIPO_ARCHIVO_TIPO_START,
	ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
	DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
	DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_START,
	GET_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
	ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
	DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
} from "./types";

import getLoggedClient from "./getLoggedClient";

const getSiniestroTipoArchivoTipo = () => async (dispatch, getState) => {
	dispatch({ type: SINIESTRO_TIPO_ARCHIVO_TIPO_START });
	const client = getLoggedClient();

	client
		.get(`/siniestroTipoArchivoTipo`)
		.then((response) => {
			dispatch({
				type: SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({
				type: SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
				payload: err,
			});
		});
};

const addMutipleSiniestroTipoArchivoTipo =
	(siniestroTipoId, archivosIds) => async (dispatch, getState) => {
		dispatch({ type: SINIESTRO_TIPO_ARCHIVO_TIPO_START });
		const client = getLoggedClient();

		client
			.post(`/tipoSiniestro/${siniestroTipoId}/archivoTipo`, {
				archivosTipo_ids: archivosIds,
			})
			.then((response) => {
				dispatch({
					type: ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
					payload: response.data,
				});
			})
			.catch((err) => {
				dispatch({
					type: SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
					payload: err,
				});
			});
	};

const deleteSiniestroTipoArchivoTipo =
	(siniestroTipo_id, archivoTipo_id) => async (dispatch, getState) => {
		dispatch({ type: DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_START });
		const client = getLoggedClient();

		client
			.delete(`/siniestroTipoArchivoTipo`, {
				data: {
					archivoTipo_id: archivoTipo_id,
					siniestroTipo_id,
				},
			})
			.then((response) => {
				dispatch({
					type: DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
					payload: response.data,
				});
			})
			.catch((err) => {
				dispatch({
					type: SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
					payload: err,
				});
			});
	};

const getDocExtraBySiniestro = (siniestroId) => async (dispatch, getState) => {
	dispatch({ type: SINIESTRO_TIPO_ARCHIVO_TIPO_START });
	const client = getLoggedClient();

	client
		.get(`/siniestro/${siniestroId}/documentacion-extra`)
		.then((response) => {
			dispatch({
				type: GET_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
				payload: response.data.result,
			});
		})
		.catch((err) => {
			dispatch({
				type: SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
				payload: err,
			});
		});
};

const addDocExtraBySiniestro =
	(siniestroId, data) => async (dispatch, getState) => {
		dispatch({ type: SINIESTRO_TIPO_ARCHIVO_TIPO_START });
		const client = getLoggedClient();

		client
			.post(`/siniestro/${siniestroId}/documentacion-extra`, data)
			.then((response) => {
				dispatch({
					type: ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
					payload: response.data.result,
				});
			})
			.catch((err) => {
				dispatch({
					type: SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
					payload: err,
				});
			});
	};

const deleteDocExtraBySiniestro =
	(siniestroId, data) => async (dispatch, getState) => {
		dispatch({ type: SINIESTRO_TIPO_ARCHIVO_TIPO_START });
		const client = getLoggedClient();

		client
			.delete(`/siniestro/${siniestroId}/documentacion-extra`, {
				data: data,
			})
			.then((response) => {
				dispatch({
					type: DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
					payload: response.data.result,
				});
			})
			.catch((err) => {
				dispatch({
					type: SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
					payload: err,
				});
			});
	};

const siniestroTipoArchivoTipoActions = {
	getSiniestroTipoArchivoTipo,
	addMutipleSiniestroTipoArchivoTipo,
	deleteSiniestroTipoArchivoTipo,
	getDocExtraBySiniestro,
	addDocExtraBySiniestro,
	deleteDocExtraBySiniestro,
};

export default siniestroTipoArchivoTipoActions;
