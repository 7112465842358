import {
  GET_COMMENTS_START,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_ERROR,
  CREATE_NEW_COMMENT_SUCCESS,
} from "../actions/types";

let initialState = {
  allComments: [],
  createdComment: [],
  isLoading: false,
  error: null,
};

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMENTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        allComments: action.payload,
        isLoading: false,
      };
    case GET_COMMENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case CREATE_NEW_COMMENT_SUCCESS:
      return {
        ...state,
        createdComment: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default commentsReducer;
