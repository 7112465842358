import {
	SEND_EMAIL_START,
	SEND_EMAIL_SUCCESS,
	SEND_EMAIL_ERROR,
	GET_THREAD_SUCCESS,
	SEND_EMAIL_COMPANY_START,
	SEND_EMAIL_COMPANY_SUCCESS,
	SEND_EMAIL_COMPANY_ERROR,
	COMPLAINT_FORM_SUCCESS,
	SEND_EMAIL_CLAIM_DOC_START,
	SEND_EMAIL_CLAIM_DOC_SUCCESS,
	SEND_EMAIL_CLAIM_DOC_ERROR
} from "./types";
import getLoggedClient from "./getLoggedClient";
import allActions from ".";

const sendEmail = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: SEND_EMAIL_START });

	client
		.post(`/email`, data)
		.then((response) => {
			dispatch({
				type: SEND_EMAIL_SUCCESS,
				payload: response.data.result,
			});

		})
		.catch((error) => {
			dispatch({ type: SEND_EMAIL_ERROR, payload: error });
		});
};

const sendEmailReportCompany = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: SEND_EMAIL_COMPANY_START });

	client
		.post(`/email`, data)
		.then((response) => {
			dispatch({
				type: SEND_EMAIL_COMPANY_SUCCESS,
				payload: response.data.result,
			});
			dispatch(
				allActions.siniestroActions.changeEstado(
					idSiniestro, { idEstado: 3 })
			);
			dispatch({
				type: COMPLAINT_FORM_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			dispatch({ type: SEND_EMAIL_COMPANY_ERROR, payload: error });
		});
};

const sendEmailClaimDoc = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: SEND_EMAIL_CLAIM_DOC_START });
	document.getElementById("reclamar").innerText = "Reclamando...";
	client
		.post(`/email`, data)
		.then((response) => {
			dispatch({
				type: SEND_EMAIL_CLAIM_DOC_SUCCESS,
				payload: response.data.result,
			});
			dispatch(
				allActions.siniestroActions.changeEstado(
					idSiniestro, { idEstado: 5 })
			);
		})
		.catch((error) => {
			dispatch({ type: SEND_EMAIL_CLAIM_DOC_ERROR, payload: error });
		});
};

const getThread = (threadId) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: SEND_EMAIL_START });

	client
		.get(`/emails?threadId=${threadId}`)
		.then((response) => {
			dispatch({
				type: GET_THREAD_SUCCESS,
				payload: response.data.result,
			});
		})
		.catch((error) => {
			dispatch({ type: SEND_EMAIL_ERROR, payload: error });
		});
};

const emailsActions = {
	sendEmail,
	sendEmailReportCompany,
	sendEmailClaimDoc,
	getThread,
};

export default emailsActions;
