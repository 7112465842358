import React from "react";
import PropTypes from "prop-types";
import InputGroupBootstrap from "react-bootstrap/InputGroup";
import FormControl from "../FormControl";

function InputGroup(props) {
  const {
    placeholder,
    sideElement,
    onChange,
    id,
    type,
    isDisabled,
    value,
    status,
    isRequired
  } = props;

  return (
    <InputGroupBootstrap data-testid="input-group">
      {sideElement && (
        <InputGroupBootstrap.Prepend className="input-group-addon">
          <InputGroupBootstrap.Text>{sideElement}</InputGroupBootstrap.Text>
        </InputGroupBootstrap.Prepend>
      )}
      <FormControl
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        type={type}
        isDisabled={isDisabled}
        value={value}
        status={status}
        required={isRequired}
      />
    </InputGroupBootstrap>
  );
}

InputGroup.propTypes = {
  placeholder: PropTypes.string,
  sideElement: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default InputGroup;
