import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	dateText: {
		marginLeft: "5px",
		color: "gray",
	},
	userText: {
		marginRight: "5px",
	},
	headerText: {
		marginBottom: "20px",
	},
	paper: {
		padding: "20px",
	},
	container: {
		display: "block",
		background: "#f2f2f2",
		maxHeight: "500px",
		overflowY: "scroll",
		padding: "20px",
	},
	inputNewComment: {
		width: "100%",
		outline: "none",
		border: "none",
	},
	buttonNewComment: {
		marginBottom: "20px",
		justifyContent: "flex-end",
		display: "flex",
	},
	iconsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	noInfoContainer: {
		width: "100%",
		padding: "90px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& img": {
			marginBottom: "20px",
			width: "90px",
			height: "90px",
		},
	},
	noInfoText: {
		fontSize: "16.5px !important",
	},
	seeMoreLink: {
		cursor: "pointer",
	},
	isBajaComment: {
		background: "#ffb2b25e",
	},
	isBajaText: {
		marginLeft: "30px",
	},
	isAltaComment: {
		background: "#edf7ed",
	},
}));

export default useStyles;
