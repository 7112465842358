import {
	CREATE_SINIESTRO_START,
	CREATE_SINIESTRO_SUCCESS,
	CREATE_SINIESTRO_ERROR,
	SEND_LIQUIDATE_SUCCESS,
	UPDATE_SINIESTRO_SUCCESS,
	GET_INDIVIDUAL_SINIESTRO_SUCCESS,
	BAJA_SINIESTRO_SUCCESS,
	LIQUIDACION_SINIESTRO_SUCCESS,
	UPDATE_SINIESTRO_DETAILS_SUCCESS,
} from "../actions/types";

let initialState = {
	createdSiniestro: [],
	updatedSiniestro: {},
	individualSiniestro: {},
	bajaSiniestro: [],
	liquidacionSiniestro: [],
	updateSiniestroDetails: [],
	isLoading: false,
	error: null,
};

const createSiniestroReducer = (state = initialState, action) => {

	switch (action.type) {
		case CREATE_SINIESTRO_START:
			return {
				...state,
				isLoading: true,
			};
		case CREATE_SINIESTRO_SUCCESS:
			return {
				...state,
				createdSiniestro: action.payload,
				isLoading: false,
			};
		case CREATE_SINIESTRO_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case SEND_LIQUIDATE_SUCCESS:
			return {
				...state,
				liquidateSiniestro: action.payload,
				isLoading: false,
			}
		case UPDATE_SINIESTRO_SUCCESS:
			return {
				...state,
				updatedSiniestro: action.payload,
				isLoading: false,
			};
		case GET_INDIVIDUAL_SINIESTRO_SUCCESS:
			return {
				...state,
				individualSiniestro: action.payload,
				isLoading: false,
			};
		case BAJA_SINIESTRO_SUCCESS:
			return {
				...state,
				bajaSiniestro: action.payload,
				isLoading: false,
			};
		case LIQUIDACION_SINIESTRO_SUCCESS:
			return {
				...state,
				liquidacionSiniestro: action.payload,
				isLoading: false,
			};
		case UPDATE_SINIESTRO_DETAILS_SUCCESS:
			return {
				...state,
				updateSiniestroDetails: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default createSiniestroReducer;
