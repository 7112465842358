import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "../../../../components/Button";
import ButtonMUI from "@material-ui/core/Button";
import {
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "20px"
	},
	dialog: {
		"& .MuiDialog-paper": {
			overflowX: "hidden",
		},
	},
	buttonContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		padding: "0px 20px 20px",
	},
	closeButton: {
		marginRight: "10px",
	},
	closeIcon: {
		fontSize: "20px",
	},
	modalTitle: {
		"& > h2": {
			fontSize: "16px",
		},
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
	const { children, title, open, setOpen, onSubmit, isLoading } = props;
	const classes = useStyles();
	const [openAlert, setOpenAlert] = useState(false);

	const handleClose = () => {
		setOpen(!open);
	};

	const onSave = () => {
		setOpenAlert(!openAlert);
	};

	useEffect(() => {
		if (!open) setOpenAlert(false);
	}, [open]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				className={classes.dialog}
				fullWidth
				maxWidth={"lg"}
			>
				<div className={classes.header}>
					<Typography variant="h4" className={classes.title}>
						{title}
					</Typography>
					<IconButton onClick={handleClose}>
						<CloseIcon className={classes.closeIcon} />
					</IconButton>
				</div>
				{children}
				<div className={classes.buttonContainer}>
					<Button
						text="Cancelar"
						variant="secondary"
						onClick={handleClose}
					/>
					&nbsp;&nbsp;
					<Button text="Guardar" onClick={onSave} />
				</div>
			</Dialog>

			<Dialog open={open && openAlert} onClose={onSave}>
				<DialogTitle className={classes.modalTitle}>
					¿Estás seguro de realizar los cambios?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description"></DialogContentText>
				</DialogContent>
				<DialogActions>
					<ButtonMUI
						onClick={onSave}
						color="primary"
						disabled={isLoading}
					>
						Cancelar
					</ButtonMUI>
					<ButtonMUI
						onClick={onSubmit}
						color="primary"
						autoFocus
						disabled={isLoading}
					>
						{isLoading ? <CircularProgress size={20} /> : "Aceptar"}
					</ButtonMUI>
				</DialogActions>
			</Dialog>
		</div>
	);
}
