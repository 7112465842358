import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ErrorIcon from "@material-ui/icons/Error";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CopyToClipboard from "react-copy-to-clipboard";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
	dialogTitle: {
		display: "flex",
		alignItems: "center",
		color: "red",
		"& > .MuiTypography-root": {
			fontSize: "20px !important",
		},
	},
	errorIcon: { marginRight: "10px" },
	checkCircleIcon: {
		color: "green",
		marginRight: "10px",
	},
	correctText: {
		display: "flex",
		alignItems: "center",
	},
}));

export default function CustomDialogFiles({
	open,
	setOpen,
	filesNames,
	isSize,
	setFilesMaxSize,
	setFilesWithError,
	setUploadedFiles,
}) {
	const classes = useStyles();

	const [toolTipTitle, setToolTipTitle] = useState("Copiar");
	const handleClose = () => {
		setFilesMaxSize([]);
		setFilesWithError([]);
		setUploadedFiles([]);
		setOpen(!open);
	};

	const handleToolTip = () => {
		setToolTipTitle("Copiado");
		setTimeout(() => setToolTipTitle("Copiar"), 2000);
	};

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
			>
				<DialogTitle className={classes.dialogTitle}>
					<ErrorIcon className={classes.errorIcon} /> ¡Error!
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Los siguientes archivos{" "}
						{isSize
							? "superan el límite permitido (6MB)"
							: "no se puedieron cargar"}
						:{" "}
						<CopyToClipboard
							text={filesNames?.join("\n")}
							onCopy={handleToolTip}
						>
							<Tooltip title={toolTipTitle}>
								<IconButton>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						</CopyToClipboard>
						<ul>
							{filesNames?.map((fileName) => {
								return <li key={fileName}>{fileName}</li>;
							})}
						</ul>
					</DialogContentText>
					<br />
					<DialogContentText className={classes.correctText}>
						<CheckCircleIcon className={classes.checkCircleIcon} />
						Los demás archivos{" "}
						{isSize
							? "pueden ser subidos correctamente"
							: "y cambios se guardaron correctamente"}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
