import {
    GET_AJUSTADORES_START,
    GET_AJUSTADORES_SUCCESS,
    GET_AJUSTADORES_ERROR,
    GET_AJUSTADOR_START,
    GET_AJUSTADOR_SUCCESS,
    GET_AJUSTADOR_ERROR,
    NEW_AJUSTADOR_START,
    NEW_AJUSTADOR_SUCCESS,
    NEW_AJUSTADOR_ERROR,
    PUT_AJUSTADOR_START,
    PUT_AJUSTADOR_SUCCESS,
    PUT_AJUSTADOR_ERROR,
    DELETE_AJUSTADOR_START,
    DELETE_AJUSTADOR_SUCCESS,
    DELETE_AJUSTADOR_ERROR,
    LOW_AJUSTADOR_START,
    LOW_AJUSTADOR_SUCCESS,
    LOW_AJUSTADOR_ERROR,
    UP_AJUSTADOR_START,
    UP_AJUSTADOR_SUCCESS,
    UP_AJUSTADOR_ERROR,
    GET_AJUSTADORESIDESTUDIO_START,
    GET_AJUSTADORESIDESTUDIO_SUCCESS,
    GET_AJUSTADORESIDESTUDIO_ERROR,
    SET_AJUSTADOR_START,
    SET_AJUSTADOR_SUCCESS,
    SET_AJUSTADOR_ERROR,
} from "./types";

import getLoggedClient from "./getLoggedClient";
import Swal from "sweetalert2";

const getAllAjustadores = () => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: GET_AJUSTADORES_START });

    client
        .get("/ajustadores")
        .then((response) => {
            dispatch({
                type: GET_AJUSTADORES_SUCCESS,
                payload: response.data.result,
            })

        })
        .catch((err) => {

            dispatch({
                type: GET_AJUSTADORES_ERROR,
                payload: err,
            });
        });
};

const getAjustador = (ajustadorId) => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: GET_AJUSTADOR_START })

    client
        .get(`/ajustador/${ajustadorId}`)
        .then((response) => {
            dispatch({
                type: GET_AJUSTADOR_SUCCESS,
                payload: response.data.result
            })
        })
        .catch((err) => {
            dispatch({
                type: GET_AJUSTADOR_ERROR,
                payload: err,
            })
        })
}
const newAjustador = (data) => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: NEW_AJUSTADOR_START })

    client
        .post("/newAjustador", data)
        .then((response) => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Creado',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch({
                type: NEW_AJUSTADOR_SUCCESS,
                payload: response.data.result,
            })
        })
        .catch((err) => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch({
                type: NEW_AJUSTADOR_ERROR,
                payload: err
            })
        })
}
const putAjustador = (data) => (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: PUT_AJUSTADOR_START })

    client
        .put("/putAjustador", data)
        .then((response) => {
            dispatch({
                type: PUT_AJUSTADOR_SUCCESS,
                payload: response.data.result
            })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Modificado',
                showConfirmButton: false,
                timer: 1500
            })
        })
        .catch((err) => {
            dispatch({
                type: PUT_AJUSTADOR_ERROR,
                payload: err
            })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
            })
        })
}

const deleteAjustador = (data) => (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: DELETE_AJUSTADOR_START })

    client
        .post("/deleteAjustador", data)
        .then((response) => {
            dispatch({
                type: DELETE_AJUSTADOR_SUCCESS,
                payload: response.data.result
            })
        })
        .catch((err) => {
            dispatch({
                type: DELETE_AJUSTADOR_ERROR,
                payload: err
            })
        })
}
const lowAjustador = (data) => (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: LOW_AJUSTADOR_START })
    client
        .put("/lowAjustador", data)
        .then((response) => {
            dispatch({
                type: LOW_AJUSTADOR_SUCCESS,
                payload: response.data.result
            })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Baja Exitosa',
                showConfirmButton: false,
                timer: 1500
            })
        })
        .catch((err) => {
            dispatch({
                type: LOW_AJUSTADOR_ERROR,
                payload: err
            })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
            })
        })
}
const upAjustador = (data) => (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: UP_AJUSTADOR_START })
    client
        .put("/upAjustador", data)
        .then((response) => {
            dispatch({
                type: UP_AJUSTADOR_SUCCESS,
                payload: response.data.result
            })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Alta Exitosa',
                showConfirmButton: false,
                timer: 1500
            })
        })
        .catch((err) => {
            dispatch({
                type: UP_AJUSTADOR_ERROR,
                payload: err
            })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
            })
        })
}
const getAjustadoresIdEstudio = (data) => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: GET_AJUSTADORESIDESTUDIO_START });

    client
        .post("/ajustadoresIdEstudio", data)
        .then((response) => {
            dispatch({
                type: GET_AJUSTADORESIDESTUDIO_SUCCESS,
                payload: response.data.result,
            })

        })
        .catch((err) => {

            dispatch({
                type: GET_AJUSTADORESIDESTUDIO_ERROR,
                payload: err,
            });
        });
};

const setAjustador = (data) => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({ type: SET_AJUSTADOR_START })

    client
        .post("/setAjustador", data)
        .then((response) => {
            dispatch({
                type: SET_AJUSTADOR_SUCCESS,
                payload: response.data.result,
            })
            window.location.reload()
        })
        .catch((err) => {
            dispatch({
                type: SET_AJUSTADOR_ERROR,
                payload: err,
            });
        });
}
const ajustadoresActions = {
    getAllAjustadores,
    newAjustador,
    putAjustador,
    deleteAjustador,
    lowAjustador,
    upAjustador,
    getAjustadoresIdEstudio,
    getAjustador,
    setAjustador,
};

export default ajustadoresActions;