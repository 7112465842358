import {
	SINIESTROS_REPORTE_START,
	SINIESTROS_REPORTE_SUCCESS,
	SINIESTROS_REPORTE_ERROR,
} from "../actions/types";

let initialState = {
	reporte: [],
	isLoading: false,
	error: null,
};

const siniestrosReporteReducer = (state = initialState, action) => {
	switch (action.type) {
		case SINIESTROS_REPORTE_START:
			return {
				...state,
				isLoading: true,
			};
		case SINIESTROS_REPORTE_SUCCESS:
			return {
				...state,
				reporte: action.payload,
				isLoading: false,
			};
		case SINIESTROS_REPORTE_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default siniestrosReporteReducer;
