import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  title: {
    "& h2": {
      fontSize: "16px !important",
    },
  },
  text: {
    fontSize: "14px !important",
  },
  button: {
    fontSize: "14px !important",
  },
}));

export default useStyles;
