import { combineReducers } from "redux";
import operationReducer from "./operationReducer";
import altaSiniestroReducer from "./altaSiniestroReducer";
import sessionReducer from "./sessionReducer";
import clientsReducer from "./clientsReducer";
import createSiniestroReducer from "./createSiniestroReducer";
import siniestroReducer from "./siniestroReducer";
import comunicationsReducer from "./comunicationsReducer";
import siniestroNumberReducer from "./siniestroNumber";
import siniestroEstadoReducer from "./siniestroEstadoReducer";
import commentsReducer from "./commentsReducer";
import siniestroReservaReducer from "./siniestroReservaReducer";
import denunciaReducer from "./denunciaReducer";
import archivoReducer from "./archivoReducer";
import archivoTipoReducer from "./archivoTipoReducer";
import siniestroTipoArchivoTipoReducer from "./siniestroTipoArchivoTipo";
import mailsReducer from "./mailsReducer";
import siniestrosReporteReducer from "./siniestrosReporteReducer";
import ajustadoresReducer from "./ajustadoresReducer";
import estudiosReducer from "./estudiosReducer";
import templatesReducer from "./templatesReducer";
const reducers = combineReducers({
	operationReducer,
	altaSiniestroReducer,
	sessionReducer,
	clientsReducer,
	createSiniestroReducer,
	siniestroReducer,
	comunicationsReducer,
	siniestroNumberReducer,
	siniestroEstadoReducer,
	commentsReducer,
	siniestroReservaReducer,
	denunciaReducer,
	archivoReducer,
	archivoTipoReducer,
	siniestroTipoArchivoTipoReducer,
	mailsReducer,
	siniestrosReporteReducer,
	ajustadoresReducer,
	estudiosReducer,
	templatesReducer,
});

export default reducers;
