import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useStyles from "./styles";
import { createBreadcrumbData } from "../../common/constants";
import Row from "./row";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonAddAndInput from "../../components/ButtonAddAndInput";
import SkeletonLoader from "../../components/SkeletonLoader";
import { Snackbar, TableFooter } from "@material-ui/core";
import TablePaginationComponent from "../../components/PaginationTable";
import TransferList from "../../components/TransferList";
import FullScreenDialog from "./components/FullScreenDialog";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS, ADD_SINIESTRO_TIPO_SUCCESS } from "../../actions/types";
import { isEmpty, isEqual } from "lodash";
import allActions from "../../actions";

const Documentation = (props) => {
    const { setBreadcrumbs } = props;
    const classes = useStyles();
    const history = useHistory();
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const dispatch = useDispatch();
    const [newSiniestroTipo, setNewSiniestroTipo] = useState("");
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [siniestro, setSiniestro] = useState({});
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [filesIds, setFilesIds] = useState([]);
    const [alertFeedback, setAlertFeedback] = useState({
        message: "Exitosamente creado el tipo de siniestro",
        title: "Titulo de la alerta",
        type: "success",
        open: false,
    });

    const siniestroTipoArchivoTipoReducer = useSelector((state) => state.siniestroTipoArchivoTipoReducer);
    const archivoTipoReducer = useSelector((state) => state.archivoTipoReducer);
    const altaSiniestroReducer = useSelector((state) => state.altaSiniestroReducer);

    useEffect(() => {
        setBreadcrumbs([
            createBreadcrumbData(`/parametrizacion`, `Parametrización`),
            createBreadcrumbData(`/parametrizacion/documentacion`, `Documentacion`
            ),
        ]);
    }, [setBreadcrumbs]);

    useEffect(() => {
        dispatch(allActions.altaSiniestroActions.getSiniestros());
        dispatch(allActions.siniestroTipoArchivoTipoActions.getSiniestroTipoArchivoTipo());
        dispatch(allActions.archivoTipoActions.getAllArchivoTipo());
    }, [dispatch]);

    const onAddDocumentation = (siniestroTipo) => {
        setSiniestro(siniestroTipo);
        setLeft(siniestroTipo.docRequerida);
        setOpenDialog(!openDialog);
    };

    useEffect(() => {
        setRight(archivoTipoReducer.archivos?.filter((value) =>
            left.map((bb) => bb.archivoTipo_id)
                .indexOf(value.archivoTipo_id) === -1)
        );

        if (!isEmpty(siniestroTipoArchivoTipoReducer.siniestroArchivosAdded) && !siniestroTipoArchivoTipoReducer.isLoading
        ) {
            setOpenDialog(false);
            setAlertFeedback({
                message: "¡Cambios guardados exitosamente!",
                title: "¡YEY!",
                type: "success",
                open: true,
            });
            dispatch({
                type: ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
                payload: [],
            });
            dispatch(allActions.siniestroTipoArchivoTipoActions.getSiniestroTipoArchivoTipo());
        } else if (!isEmpty(siniestroTipoArchivoTipoReducer.error)) {
            setAlertFeedback({
                message: "No se pudieron guardar los cambios solicitados. Intentelo más tarde",
                title: "Hubo un error",
                type: "error",
                open: true,
            });
        }
    }, [archivoTipoReducer, left, siniestroTipoArchivoTipoReducer, dispatch]);

    const onSubmitFiles = (e) => {
        dispatch(allActions.siniestroTipoArchivoTipoActions.addMutipleSiniestroTipoArchivoTipo(
            siniestro.siniestroTipo_id,
            filesIds.map((file) => file.archivoTipo_id))
        );
    };

    const handleAlertFeedbackOpen = () => {
        setAlertFeedback({ ...alertFeedback, open: !alertFeedback.open });
    };

    const onAddSiniestroTipo = (e) => {
        e.preventDefault();
        dispatch(allActions.altaSiniestroActions.newSiniestroTipo(newSiniestroTipo));
    };

    useEffect(() => {
        if (!isEmpty(altaSiniestroReducer.siniestroTipoAdded) &&
            isEqual(altaSiniestroReducer.siniestroTipoAdded?.status, "success")
        ) {
            setNewSiniestroTipo("");
            setAlertFeedback({
                message: "Exitosamente creado el tipo de siniestro",
                title: "¡YEY!",
                type: "success",
                open: true,
            });
            dispatch({ type: ADD_SINIESTRO_TIPO_SUCCESS, payload: [] });
            dispatch(allActions.siniestroTipoArchivoTipoActions.getSiniestroTipoArchivoTipo());
        } else if (isEqual(altaSiniestroReducer.siniestroTipoAdded?.status, "error") || !isEmpty(altaSiniestroReducer.error)) {
            setAlertFeedback({
                message: "Hubo un error en la creación del siniestro",
                title: "Error",
                type: "error",
                open: true,
            });
        }
    }, [altaSiniestroReducer, dispatch]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell />
                            <TableCell className={classes.tableHead}>
                                Nombre del siniestro
                            </TableCell>
                            <TableCell>
                                <Button
                                    onClick={() => history.push("/parametrizacion/nueva-documentacion")}
                                >
                                    {" "}
                                    Nueva documentación
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {siniestroTipoArchivoTipoReducer.isLoading ? (
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <SkeletonLoader
                                        numberOfSkeletons={6}
                                        customStyles={classes.skeletonLoader}
                                    />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {(rowsPerPage > 0
                                    ? siniestroTipoArchivoTipoReducer.allSiniestrosArchivos?.result?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    : siniestroTipoArchivoTipoReducer.allSiniestrosArchivos?.result
                                )?.map((row) => (
                                    <Row
                                        key={row.siniestroTitulo}
                                        row={row}
                                        onAddDocumentation={onAddDocumentation}
                                    />
                                ))}
                            </>
                        )}
                    </TableBody>
                    <TableFooter className={classes.tablePagination}>
                        <TablePaginationComponent
                            setRowsPerPage={setRowsPerPage}
                            rows={siniestroTipoArchivoTipoReducer.allSiniestrosArchivos?.result || []}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            setPage={setPage}
                            customStyles={classes.tableHeader}
                        />
                    </TableFooter>
                    <TableFooter style={{ paddingLeft: "30px" }}>
                        <ButtonAddAndInput
                            value={newSiniestroTipo}
                            setValue={setNewSiniestroTipo}
                            customStylesInput={classes.customStylesInput}
                            onSubmit={onAddSiniestroTipo}
                            isLoading={altaSiniestroReducer.isLoading}
                        />
                    </TableFooter>
                </Table>
            </TableContainer>

            <FullScreenDialog
                open={openDialog}
                setOpen={setOpenDialog}
                title={siniestro.siniestroTitulo}
                onSubmit={onSubmitFiles}
                isLoading={siniestroTipoArchivoTipoReducer.isLoading}
            >
                <TransferList
                    onChange={(left, _) => setFilesIds(left)}
                    left={left}
                    right={right}
                    setLeft={setLeft}
                    setRight={setRight}
                />
            </FullScreenDialog>

            <Snackbar
                open={alertFeedback.open}
                autoHideDuration={6000}
                onClose={handleAlertFeedbackOpen}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleAlertFeedbackOpen}
                    severity={alertFeedback.type}
                >
                    <AlertTitle>
                        <strong>{alertFeedback.title}</strong>
                    </AlertTitle>
                    {alertFeedback.message}
                </Alert>
            </Snackbar>
        </>
    )
};

export default Documentation;