import {
  LOAD_SINIESTRO_ESTADO_START,
  LOAD_SINIESTRO_ESTADO_SUCCESS,
  LOAD_SINIESTRO_ESTADO_ERROR,
  UPDATE_SINIESTRO_ESTADO_SUCCESS,
  CHANGE_ESTADO_SUCCESS
} from "../actions/types";

let initialState = {
  allStates: [],
  estadoUpdated: [],
  isLoading: false,
  error: null,
};

const siniestroEstadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SINIESTRO_ESTADO_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_SINIESTRO_ESTADO_SUCCESS:
      return {
        ...state,
        allStates: action.payload,
        isLoading: false,
      };
    case LOAD_SINIESTRO_ESTADO_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case UPDATE_SINIESTRO_ESTADO_SUCCESS:
      return {
        ...state,
        estadoUpdated: action.payload,
        isLoading: false,
      };
    case CHANGE_ESTADO_SUCCESS:
      return {
        ...state,
        changeEstado: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default siniestroEstadoReducer;
