import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    textContainer: {
        textAlign: "center",
        width: "100%",
        margin: "20px 0px 20px 0px",
    },
    buttons: {
        display: "flex",
        justifyContent: "center"
    }
}))