import React from "react";
import PropTypes from "prop-types";
import FormControl from "react-bootstrap/FormControl";
import isEqual from "lodash/isEqual";
import find from "lodash/find";
import { CircularProgress } from "@material-ui/core";

function Select(props) {
	const {
		id,
		onChange,
		options = [],
		keyToShow,
		isRequired,
		isDisabled,
		isJustArray,
		value,
		isLoading,
		customStyles,
	} = props;

	const handleChange = (id) => (ev) =>
		onChange &&
		onChange(
			id,
			find(options, (opt) =>
				isEqual(isJustArray ? opt : opt[keyToShow], ev.target.value)
			),
			"select"
		);

	return (
		<div
			data-testid="select-input"
			style={{ display: "flex", alignItems: "center" }}
			className={customStyles}
		>
			<FormControl
				id={id}
				as="select"
				onChange={handleChange(id)}
				required={isRequired}
				disabled={isDisabled || isLoading}
				value={value}
			>
				<option value={null}>Seleccionar</option>
				{options.map((option) => (
					<option key={isJustArray ? option : option[keyToShow]}>
						{isJustArray ? option : option[keyToShow]}
					</option>
				))}
			</FormControl>
			{isLoading && <CircularProgress size={20} />}
		</div>
	);
}

Select.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	keyToShow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	isDisabled: PropTypes.bool,
};

export default Select;
