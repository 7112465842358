import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Typography, TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import { Button, CircularProgress } from "@mui/material"
import {
	editableInput, getDataSiniestro, getDataTitulo, getEstadoRender, getIdByTitle, getOptionsSelectEstados, handleFinalizacionByEstado,
	onComunicationClick, onLiquidarSiniestro, onNewCommunicationClick
} from "./functions";
import { ESTADOS_ID, getDaysDifference, toLocaleDateString } from "../../../../common/constants";
import allActions from "../../../../actions";
import ComposedFormGroup from "../../../../components/ComposedFormGroup";
import FormLabel from "../../../../components/FormLabel";
import Select from "../../../../components/Select";
import SideBar from "../../../../components/SideBar";
import AutohideAlert from "../../../../components/AutohideAlert";
import { isEmpty, isEqual, isString } from "lodash";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { useEffect } from "react";
import useSiniestroNumberFeedback from "./useSiniestroNumberFeedback";
import AlertDialog from "../../../../components/Dialog";
import CustomDialog from "./CustomDialog";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { GET_INDIVIDUAL_SINIESTRO_SUCCESS, UPDATE_SINIESTRO_DETAILS_SUCCESS } from "../../../../actions/types";
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import 'moment/locale/es-mx'
import DialogThread from "../../../../components/DialogThreads";
import DialogStandby from "../../../../components/DialogStandby";
import Liquidate from "../../../../components/Liquidate";

const FirstForm = (props) => {
	const { isEverythingDisabled, setModalFormData, modalFormData } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const [editNro, setEditNro] = useState(false);
	const [siniestroNumber, setSiniestroNumber] = useState("");
	const [showNOperacion, setShowNOperacion] = useState(false);
	const history = useHistory();
	const [isOpenFinishDialog, setIsOpenFinishDialog] = useState(false);
	const [openThreads, setOpenThreads] = useState(false)
	const [openLiquidate, setOpenLiquidate] = useState(false)
	const [openDialogLiquidar, setOpenDialogLiquidar] = useState(false);
	const [openStandby, setOpenStandby] = useState(false);
	const [data, setData] = useState({
		siniestroTipo: "",
		siniestroFecha: "",
		siniestroAlta: "",
		siniestroDenuncia: "",
		montoReclamo: "",
		reserva: "",
		estado: "",
	});

	const siniestroId = new URLSearchParams(window.location.search).get("id");
	const createSiniestroReducer = useSelector((state) => state.createSiniestroReducer);
	const comunicationsReducer = useSelector((state) => state.comunicationsReducer);
	const siniestroNumberReducer = useSelector((state) => state.siniestroNumberReducer);
	const siniestroEstadoReducer = useSelector((state) => state.siniestroEstadoReducer);
	const altaSiniestroReducer = useSelector((state) => state.altaSiniestroReducer);
	const [alertAutohidde] = useSiniestroNumberFeedback(
		siniestroNumberReducer,
		setEditNro,
		siniestroEstadoReducer,
		setOpenDialogLiquidar,
		siniestroId
	);
	const SD = createSiniestroReducer.individualSiniestro?.result?.siniestro || "";
	const OD = createSiniestroReducer.individualSiniestro?.result?.operacion || "";
	const gtm = createSiniestroReducer?.individualSiniestro?.result?.siniestro?.gtm
	let gtmSelected = ""
	if (gtm) {
		if (gtm === "-03:00") gtmSelected = "Argentina"
		if (gtm === "-06:00") gtmSelected = "México"
		if (gtm === "-05:00") gtmSelected = "Perú"
		if (gtm === "+01:00") gtmSelected = "España"
	}

	const handleEditSiniestroNumber = (isToSave) => () => {
		if (isToSave) {
			dispatch(allActions.siniestroActions.createSiniestroNumber({
				siniestroNro: siniestroNumber,
				siniestroId: siniestroId,
			}));
			dispatch(allActions.siniestroActions.getInidividualSiniestro(siniestroId));
		} else {
			setEditNro(!editNro);
			setSiniestroNumber("");
		}
	};

	const isBaja = isEqual(SD?.siniestroEstado_id, ESTADOS_ID.baja);
	const liquidado = isEqual(SD?.siniestroEstado_id, ESTADOS_ID.liquidado);
	const isRechazado = isEqual(SD?.siniestroEstado_id, ESTADOS_ID.rechazado);
	const enProceso = isEqual(SD?.siniestroEstado_id, ESTADOS_ID.enProceso);
	const pagado = isEqual(SD?.siniestroEstado_id, ESTADOS_ID.pagado);
	const standby = isEqual(SD?.siniestroEstado_id, ESTADOS_ID.standby);

	useEffect(() => {
		setModalFormData({
			...modalFormData,
			tipoSiniestro: SD.tipoSiniestro,
			siniestroFecha: SD.fechaSiniestro || data.siniestroFecha,
			reserva: SD.reserva || data.reserva,
			mercaderiaTransportada: OD.mercaderiaTransportada,
			siniestroDenuncia: SD.fechaDenuncia ? SD.fechaDenuncia : SD.fechaAlta || data.siniestroDenuncia,
		});
	}, [SD.tipoSiniestro, data.siniestroFecha, data.reserva, OD.mercaderiaTransportada, data.siniestroDenuncia]);

	useEffect(() => {
		if (SD) {
			setData({
				siniestroTipo: SD.idTipoSiniestro,
				siniestroFecha: SD.fechaSiniestro,
				siniestroAlta: SD.fechaAlta,
				siniestroDenuncia: SD.fechaDenuncia ? SD.fechaDenuncia : SD.fechaAlta,
				montoReclamo: SD.montoReclamo,
				reserva: SD.reserva,
				estado: SD.siniestroEstado_id,
			});
		}
	}, [SD, OD]);

	useEffect(() => {
		const s = createSiniestroReducer.updateSiniestroDetails;
		if (!isEmpty(s)) {
			if (isEqual(s.status, "success")) {
				dispatch({ type: GET_INDIVIDUAL_SINIESTRO_SUCCESS, payload: [] });
				dispatch(allActions.siniestroActions.getInidividualSiniestro(siniestroId));
				dispatch({ type: UPDATE_SINIESTRO_DETAILS_SUCCESS, payload: [] });
			}
		}
	}, [createSiniestroReducer, dispatch, siniestroId]);

	const onChange = (prop, value, isSelect) => {
		let val = value;
		if (isSelect) {
			val = value?.titulo;
		}
		setData({ ...data, [prop]: val });
	};

	const onSubmit = (e) => {
		let dat = { ...data };
		e.preventDefault();

		if (isString(dat.estado)) {
			dat["siniestroEstado_id"] = getIdByTitle(
				siniestroEstadoReducer.allStates?.result,
				"siniestroEstado_id",
				data.estado
			);
		};
		if (isString(dat.siniestroTipo)) {
			dat["siniestroTipo_id"] = getIdByTitle(
				altaSiniestroReducer.siniestros,
				"siniestroTipo_id",
				data.siniestroTipo
			);
		};
		dispatch(allActions.siniestroActions.updateSiniestroDetails(siniestroId, dat));
		dispatch(allActions.siniestroTipoArchivoTipoActions.getDocExtraBySiniestro(siniestroId));
	};
	const reactivate = () => {
		dispatch(allActions.siniestroActions.changeEstado(siniestroId, { idEstado: 2 }))
	};
	const HandleThreads = () => {
		setOpenThreads(false)
	};

	const renderNumberOfDays = (state, text, date1, date2) => {
		return (
			state &&
			<Grid>
				<Typography className={classes.typography}>
					{text} {getDaysDifference(date1, date2)}{" "} días
				</Typography>
			</Grid>
		)
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} className={classes.initialInfo}>
				<Grid>
					<Typography variant="body2">{OD.tomador}</Typography>
					<Typography variant="h3">
						{SD.siniestroNro?.numero
							? SD.siniestroNro?.numero
							: editableInput(
								editNro,
								siniestroNumber,
								setSiniestroNumber,
								handleEditSiniestroNumber,
								siniestroNumberReducer.isLoading,
								classes
							)}
					</Typography>
					<Typography
						variant="subtitle1"
						className={classes.idSubtitle}
					>
						#{siniestroId}
					</Typography>
				</Grid>
				{liquidado &&
					<Grid className={classes.buttonLiquidar}>
						<Button
							variant="contained"
							aria-label="add"
							color="primary"
							onClick={() => { setOpenLiquidate(true) }}
						>
							Ingresar pago
						</Button>
					</Grid>}
				{renderNumberOfDays(pagado, 'Resuelto en', SD.fechaPago, SD.fechaDenuncia || data.siniestroDenuncia)}
				{renderNumberOfDays(isRechazado, 'En', SD.fechaFinalizacion, SD.fechaDenuncia || data.siniestroDenuncia)}
				{renderNumberOfDays(isBaja, 'En', SD.fechaFinalizacion, SD.fechaDenuncia || data.siniestroDenuncia)}
				{renderNumberOfDays(!pagado && !isBaja && !isRechazado, 'Hace', new Date(), SD.fechaDenuncia || data.siniestroDenuncia)}
			</Grid>
			<Grid item xs={12} md={6}>
				<FormLabel text="Tipo de siniestro" />
				<Select
					options={altaSiniestroReducer.siniestros}
					keyToShow={"titulo"}
					value={getDataTitulo(altaSiniestroReducer.siniestros, data.siniestroTipo, "siniestroTipo_id")}
					id={"siniestroTipo"}
					onChange={onChange}
					isDisabled={isEverythingDisabled}
					isLoading={altaSiniestroReducer.isLoading}
				/>
				<Grid className={classes.tipoSiniestroContainer}>
					<ComposedFormGroup
						placeholder="Fecha de carga operación"
						isDisabled
						value={toLocaleDateString(OD.fechaCarga)}
					/>
				</Grid>
				<ComposedFormGroup
					placeholder="Fecha de salida"
					isDisabled
					value={toLocaleDateString(OD.fechaSalida)}
				/>
				<ComposedFormGroup
					ampm={false}
					placeholder="Fecha de alta"
					value={SD.fechaAlta ? moment(SD.fechaAlta).format('DD/MM/YYYY HH:mm') : ""}
					isDisabled
					id={"siniestroAlta"}
				/>
				<Grid className={classes.containerFechas}>
					<Grid>
						<FormLabel text={"Fecha del siniestro"} />
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								ampm={false}
								inputFormat='dd/MM/yyyy HH:mm'
								value={moment(data.siniestroFecha).format()}
								onChange={(e) => {
									setData({
										...data,
										siniestroFecha: moment(e).format('YYYY-MM-DDTHH:mm:ssZ')
									});
								}}
								renderInput={(params) => <TextField {...params} />}
								disabled={pagado ? true : false}
							/>
						</LocalizationProvider>

						<FormLabel text={"Fecha de denuncia"} />
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								ampm={false}
								inputFormat='dd/MM/yyyy HH:mm'
								value={moment(data.siniestroDenuncia).format()}
								className={classes.fechas}
								onChange={(e) => {
									setData({
										...data,
										siniestroDenuncia: moment(e).format('YYYY-MM-DDTHH:mm:ssZ')
									});
								}}
								renderInput={(params) => <TextField {...params} />}
								disabled={pagado ? true : false}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid>
						<FormLabel text={`Hora de ${gtmSelected}`} />
					</Grid>
				</Grid>
				<Divider className={classes.divider} />

				{getDataSiniestro(OD, showNOperacion, setShowNOperacion, classes)}

				<Grid className={classes.amountsContainer}>
					<ComposedFormGroup
						placeholder="Monto del reclamo"
						typeInput="numbe event.preventDefault();r"
						sideElement="USD"
						value={data.montoReclamo}
						id="montoReclamo"
						onChange={onChange}
						isDisabled={isEverythingDisabled}
					/>

					&nbsp;&nbsp;&nbsp;
					<ComposedFormGroup
						placeholder="Monto liquidado"
						typeInput="number"
						sideElement="USD"
						isDisabled
						value={SD.montoLiquidado || 0}
					/>
				</Grid>
				<ComposedFormGroup
					placeholder="Reserva"
					typeInput="number"
					sideElement="USD"
					value={data.reserva}
					id={"reserva"}
					onChange={onChange}
					isDisabled={isEverythingDisabled}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				{isEverythingDisabled ? (
					getEstadoRender(isBaja, pagado, isRechazado,
						getDataTitulo(
							siniestroEstadoReducer.allStates?.result,
							data.estado,
							"siniestroEstado_id"
						), classes)
				) : (
					<>
						<FormLabel text="Estado del siniestro" />
						<Select
							options={getOptionsSelectEstados(siniestroEstadoReducer)}
							keyToShow={"titulo"}
							value={getDataTitulo(
								siniestroEstadoReducer.allStates?.result,
								data.estado,
								"siniestroEstado_id"
							)}
							isLoading={siniestroEstadoReducer.isLoading}
							id={"estado"}
							onChange={onChange}
							isDisabled={isEverythingDisabled}
						/>
					</>
				)}
				{pagado &&
					<Grid item xs={12} md={6} className={classes.liquidateContainer}>
						<FormLabel text={`Liquidador: ${SD.liquidador}`} />
						<FormLabel text={`Fecha de pago: ${moment(SD.fechaPago).format("DD-MM-YYYY")}`} />
						<FormLabel text={`Monto liquidado: $${SD.montoLiquidado}`} />
					</Grid>
				}
				<SideBar
					title="Comunicación con Cliente"
					customStylesPaper={classes.firstFormCommunicationPaper}
					data={comunicationsReducer.comunicationsBySiniestro?.result?.filter((comunicacion) =>
						comunicacion.entidad_comunicacion === "cliente" || "ajustador" || "compania")}
					isLoading={comunicationsReducer.isLoading}
					unreadMessages={comunicationsReducer.messagesUnread}
					setOpenThreads={setOpenThreads}
					openThreads={openThreads}
					handleFabButtonClick={onNewCommunicationClick(history, siniestroId, "cliente")}
					tipo="Label_74064150515699339" //ID LABEL CLIENTE
					handleListItemClick={onComunicationClick(history, siniestroId)}
				/>

				{!pagado && !liquidado && !isRechazado && (
					<Button
						variant="contained"
						className={classes.buttonFinish}
						startIcon={<AssignmentTurnedInIcon />}
						onClick={handleFinalizacionByEstado(
							pagado,
							isBaja,
							setIsOpenFinishDialog,
							setOpenDialogLiquidar,
							openDialogLiquidar,
							isOpenFinishDialog
						)}
					>
						{enProceso ? "Liquidar siniestro" : isBaja ? "Dar de alta" : "Finalizar siniestro"}
					</Button>
				)}
				<Grid className={classes.containsMany}>
					{!standby && !pagado && !isRechazado && !isBaja &&
						<Grid className={classes.standbyLink}>
							<a href="#standby"
								onClick={() => { setOpenStandby(true) }}>Standby</a>
						</Grid>}

					<Grid className={classes.reactivateButton}>
						{liquidado &&
							<Button
								variant="contained"
								onClick={reactivate}
								color="primary"
							>
								Reactivar
							</Button>}
					</Grid>

					{!isEverythingDisabled && (
						<Grid className={classes.submitButton}>
							<Button
								variant="contained"
								onClick={onSubmit}
								color="primary"
							>
								{createSiniestroReducer.isLoading
									? <CircularProgress size={20} color={"inherit"} />
									: "Guardar"}
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>
			<CustomDialog
				isOpen={isOpenFinishDialog}
				handleOpen={() => setIsOpenFinishDialog(!isOpenFinishDialog)}
			/>
			<AlertDialog
				isOpen={openDialogLiquidar}
				title={"¡Alerta! ¿Estás seguro?"}
				text={"Liquidando el siniestro no podras volver a editar datos"}
				handleOpen={handleFinalizacionByEstado(
					pagado,
					isBaja,
					setIsOpenFinishDialog,
					setOpenDialogLiquidar,
					openDialogLiquidar,
					isOpenFinishDialog
				)}
				onClickAccept={onLiquidarSiniestro(dispatch, siniestroId)}
				isLoading={siniestroEstadoReducer.isLoading}
			/>
			<AutohideAlert {...alertAutohidde} />
			<DialogThread
				isOpen={openThreads}
				siniestroId={siniestroId}
				onClose={HandleThreads}
			/>
			<DialogStandby
				openStandby={openStandby}
				onClose={() => setOpenStandby(false)}
			/>
			<Liquidate
				openLiquidate={openLiquidate}
				onClose={() => setOpenLiquidate(false)}
				reserva={data.reserva}
			/>
		</Grid >
	);
};

export default FirstForm;
