import {
	LOAD_FILE_START,
	LOAD_FILE_SUCCESS,
	LOAD_FILE_ERROR,
	GET_ALL_FILES_SUCCESS,
	CATEGORIZE_ARCHIVO_SUCCESS,
	DELETE_ARCHIVO_SUCCESS,
	DELETE_ARCHIVO_START,
	DELETE_ARCHIVO_ERROR
} from "./types";
import getLoggedClient from "./getLoggedClient";
import { CANTIDAD_ARCHIVOS } from "../common/constants"

const newFile = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: LOAD_FILE_START });
	client
		.post(`/archivo`, data.data)
		.then((response) => {
			fileUpload(false, data.indiceSubida, data.archivos, dispatch)
		})
		.catch((error) => {
			dispatch({
				type: LOAD_FILE_ERROR,
				payload: error.response.data.result,
			});
			sessionStorage.setItem('errorSubidaDeArchivos', true)
			fileUpload(false, data.indiceSubida, data.archivos, dispatch)
		});
};

const newFiles = (files) => (dispatch) => {
	sessionStorage.setItem('errorSubidaDeArchivos', false)
	fileUpload(true, 0, files, dispatch)
};

const fileUpload = (iniciaSubida, indiceSubida, archivos, dispatch) => {
	if (iniciaSubida) {
		for (let i = 0; i < archivos?.length; i++) {
			if (i == 0) {
				dispatch({ type: LOAD_FILE_START });
			}
			if (i == CANTIDAD_ARCHIVOS) {
				break
			}
			dispatch(newFile({ data: archivos[i], indiceSubida: i + CANTIDAD_ARCHIVOS, archivos: archivos }, dispatch))
		}
	} else {
		if (indiceSubida < archivos?.length) {
			dispatch(newFile({ data: archivos[indiceSubida], indiceSubida: indiceSubida + CANTIDAD_ARCHIVOS, archivos: archivos }, dispatch))
		} else if
			(indiceSubida >= archivos?.length) {
			const error = sessionStorage.getItem('errorSubidaDeArchivos');
			if (error != 'true') {
				dispatch({
					type: LOAD_FILE_SUCCESS,
				});
				window.location.reload()
			}
		}
	}
}

const newFileLiquidate = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: LOAD_FILE_START });
	client
		.post(`/archivo`, data.data)
		.then((response) => {
			let saveFileLiquidate = document.getElementById('saveFileLiquidate')
			if (saveFileLiquidate !== null) {
				let sendFileLiquidate = document.getElementById('sendFileLiquidate')
				sendFileLiquidate.style.display = 'block'
				saveFileLiquidate.style.display = 'block'
			}
			fileUploadLiquidate(false, data.indiceSubida, data.archivos, dispatch)
		})
		.catch((error) => {
			dispatch({
				type: LOAD_FILE_ERROR,
				payload: error.response.data.result,
			});
			sessionStorage.setItem('errorSubidaDeArchivos', true)
			fileUploadLiquidate(false, data.indiceSubida, data.archivos, dispatch)
		});
};

const newFilesLiquidate = (files) => (dispatch) => {
	sessionStorage.setItem('errorSubidaDeArchivos', false)
	fileUploadLiquidate(true, 0, files, dispatch)
};

const fileUploadLiquidate = (iniciaSubida, indiceSubida, archivos, dispatch) => {
	if (iniciaSubida) {
		for (let i = 0; i < archivos?.length; i++) {
			if (i == 0) {
				dispatch({ type: LOAD_FILE_START });
			}
			if (i == CANTIDAD_ARCHIVOS) {
				break
			}
			dispatch(newFileLiquidate({ data: archivos[i], indiceSubida: i + CANTIDAD_ARCHIVOS, archivos: archivos }, dispatch))
		}
	} else {
		if (indiceSubida < archivos?.length) {
			dispatch(newFileLiquidate({ data: archivos[indiceSubida], indiceSubida: indiceSubida + CANTIDAD_ARCHIVOS, archivos: archivos }, dispatch))
		} else if
			(indiceSubida >= archivos?.length) {
			const error = sessionStorage.getItem('errorSubidaDeArchivos');
			if (error != 'true') {
				dispatch({
					type: LOAD_FILE_SUCCESS,
				});
			}
		}
	}
}

const getAllFilesBySiniestro = (siniestroId) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: LOAD_FILE_START });

	client
		.get(`/archivo?siniestroId=${siniestroId}`)
		.then((response) => {
			dispatch({
				type: GET_ALL_FILES_SUCCESS,
				payload: response.data.result,
			});
		})
		.catch((error) => {
			dispatch({
				type: LOAD_FILE_ERROR,
				payload: error,
			});
		});
};

const categorizeArchivo =
	(archivoId, archivoTipoId) => async (dispatch, getState) => {
		const client = getLoggedClient();
		dispatch({ type: LOAD_FILE_START });
		client
			.put(`/archivo/${archivoId}`, {
				archivoTipo_id: archivoTipoId,
			})
			.then((response) => {
				dispatch({
					type: CATEGORIZE_ARCHIVO_SUCCESS,
					payload: response.data.result,
				});
			})
			.catch((error) => {
				dispatch({
					type: LOAD_FILE_ERROR,
					payload: error,
				});
			});
	};

const deleteArchivo =
	(archivoId, archivoRoute) => async (dispatch, getState) => {
		const client = getLoggedClient();
		dispatch({ type: DELETE_ARCHIVO_START });

		client
			.delete(`/archivo/${archivoId}`, {
				data: {
					archivoRuta: archivoRoute,
				},
			})
			.then((response) => {
				dispatch({
					type: DELETE_ARCHIVO_SUCCESS,
					payload: response.data.result,
				});
			})
			.catch((error) => {
				dispatch({
					type: DELETE_ARCHIVO_ERROR,
					payload: error,
				});
			});
	};

const clientsActions = {
	newFile,
	newFileLiquidate,
	newFiles,
	newFilesLiquidate,
	getAllFilesBySiniestro,
	categorizeArchivo,
	deleteArchivo,
};
export default clientsActions;
