import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
	FormControlContainer,
	FormControlExtensionStatus,
	FormControlInput,
} from "./styles";
import { CircularProgress } from "@material-ui/core";
import { isEqual } from "lodash";

function FormControl(props) {
	const {
		type,
		placeholder,
		as,
		rows,
		onChange,
		id,
		isRequired,
		onBlur,
		status,
		isDisabled,
		value,
		maxLength,
		customStyles,
	} = props;

	const [statusVariation, setStatusVariation] = useState(status);

	useEffect(() => {
		setStatusVariation(status);
	}, [status]);

	const handleChange = (prop) => (ev) =>
		onChange && onChange(prop, ev.target.value);

	const onFocus = () => {
		setStatusVariation(null);
	};

	const handleInputStatus = (type) => {
		switch (type) {
			case "loading":
				return <CircularProgress color="secondary" size={20} />;
			case "error":
				return <ErrorIcon />;
			case "success":
				return <CheckCircleIcon />;
			default:
				return;
		}
	};

	return (
		<FormControlContainer className={customStyles}>
			<FormControlInput
				id={id}
				data-testid="form-control"
				type={type}
				placeholder={placeholder}
				as={as}
				rows={rows}
				onChange={handleChange(id)}
				onBlur={onBlur}
				onFocus={onFocus}
				required={isRequired}
				disabled={isDisabled || isEqual(status, "loading")}
				min="0"
				className="form-control"
				status={statusVariation}
				value={value}
				step={isEqual(type, "number") ? "any" : undefined}
				maxLength={maxLength}
			/>
			<FormControlExtensionStatus status={statusVariation}>
				{handleInputStatus(statusVariation)}
			</FormControlExtensionStatus>
		</FormControlContainer>
	);
}

FormControl.propTypes = {
	type: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	as: PropTypes.string,
	rows: PropTypes.number,
	onChange: PropTypes.func,
	isRequired: PropTypes.bool,
	isValid: PropTypes.bool,
	isInvalid: PropTypes.bool,
	onBlur: PropTypes.func,
	status: PropTypes.string,
	isDisabled: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormControl;
