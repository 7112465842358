import {
  GET_COMUNICATIONS_START,
  GET_COMUNICATIONS_SUCCESS,
  GET_COMUNICATIONS_ERROR,
  GET_COMUNICATIONS_TYPES_SUCCESS,
  NEW_COMUNICATION_SUCCESS,
  GET_SINGLE_COMUNICATION_SUCCESS,
  GET_UNREAD_MESSAGES_ERROR,
  GET_UNREAD_MESSAGES_SUCCES,
  GET_NEW_THREADS_START,
  GET_NEW_THREADS_SUCCES,
  GET_NEW_THREADS_ERROR,
  SEND_REPLY_START,
  SEND_REPLY_SUCCESS,
  SEND_REPLY_ERROR
} from "./types";
import getLoggedClient from "./getLoggedClient";

const getComunicationsBySiniestro =
  (idSiniestro) => async (dispatch, getState) => {
    dispatch({ type: GET_COMUNICATIONS_START });
    const client = getLoggedClient();

    client
      .get(`/comunicaciones?idSiniestro=${idSiniestro}`)
      .then((response) => {
        dispatch({ type: GET_COMUNICATIONS_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: GET_COMUNICATIONS_ERROR, payload: err });
      });
  };

const getComunicationsTypes = () => async (dispatch, getState) => {
  dispatch({ type: GET_COMUNICATIONS_START });
  const client = getLoggedClient();

  client
    .get(`/tipoComunicaciones`)
    .then((response) => {
      dispatch({
        type: GET_COMUNICATIONS_TYPES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_COMUNICATIONS_ERROR, payload: err });
    });
};

const newComunication = (data) => async (dispatch, getState) => {
  dispatch({ type: GET_COMUNICATIONS_START });
  const client = getLoggedClient();

  client
    .post("/comunicacion", data)
    .then((response) => {
      dispatch({
        type: NEW_COMUNICATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_COMUNICATIONS_ERROR, payload: err });
    });
};

const getComunicationById = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_COMUNICATIONS_START });
  const client = getLoggedClient();

  client
    .get(`/comunicacion/${id}`)
    .then((response) => {
      dispatch({
        type: GET_SINGLE_COMUNICATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_COMUNICATIONS_ERROR, payload: err });
    });
};

const getUnreadMessages = (labelId) => async (dispatch, getState) => {
  dispatch({ type: GET_COMUNICATIONS_START });
  const client = getLoggedClient();

  client
    .get(`/checkEmail/${labelId}`)
    .then((response) => {
      dispatch({
        type: GET_UNREAD_MESSAGES_SUCCES,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_UNREAD_MESSAGES_ERROR, payload: err });
    });
};

const getThreadsUncreated = (labelId, idSiniestro, tipo, entidad) => (dispatch, getState) => {
  dispatch({ type: GET_NEW_THREADS_START })
  const client = getLoggedClient()

  client.get(`/checkThreads/${labelId}/${idSiniestro}/${tipo}/${entidad}`)
    .then((response) => {
      dispatch({
        type: GET_NEW_THREADS_SUCCES,
        payload: response.data,
      })
    })
    .catch((e) => {
      dispatch({ type: GET_NEW_THREADS_ERROR, payload: e })
    })
}

const sendEmailReply = (data) => async (dispatch, getState) => {
  dispatch({ type: SEND_REPLY_START })
  const client = getLoggedClient()

  client
    .post("/emailReply", data)
    .then((response) => {
      dispatch({
        type: SEND_REPLY_SUCCESS,
        payload: response.data
      });
    })
    .catch((e) => {
      dispatch({ type: SEND_REPLY_ERROR, payload: e })
    });
};

const comunicationsActions = {
  getComunicationsBySiniestro,
  getComunicationsTypes,
  newComunication,
  getComunicationById,
  getUnreadMessages,
  getThreadsUncreated,
  sendEmailReply
};

export default comunicationsActions;