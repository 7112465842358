import React from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "../../components/Loader";
import logoSiw from "../../assets/images/logoSiw.png";

function LoadingView(props) {
  return (
    <Grid
      container
      style={{ display: "flex", justifyContent: "center", marginTop: "70px" }}
    >
      <img src={logoSiw} alt="SIW Cargo Logo" />
      <Loader />
    </Grid>
  );
}

export default LoadingView;
