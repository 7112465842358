import {
    GET_ESTUDIOS_START,
    GET_ESTUDIOS_SUCCES,
    GET_ESTUDIOS_ERROR,
} from "../actions/types";

let initialState = {
    allEstudios: [],
    isLoading: false,
    error: null,
}

const estudiosReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_ESTUDIOS_START:
            return {
                ...state,
                isLoading: true,
            }
        case GET_ESTUDIOS_SUCCES:
            return {
                ...state,
                allEstudios: action.payload,
                isLoading: false,
            }
        case GET_ESTUDIOS_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        default: 
            return state;
    }
}

export default estudiosReducer;