import {
	GET_DOCUMENTATION_START,
	GET_DOCUMENTATION_SUCCESS,
	GET_DOCUMENTATION_ERROR,
	ADD_ARCHIVO_TIPO_SUCCESS,
} from "../actions/types";

let initialState = {
	archivos: [],
	archivoCreated: [],
	isLoading: false,
	error: null,
};

const archivoTipoReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_DOCUMENTATION_START:
			return {
				...state,
				isLoading: true,
			};
		case GET_DOCUMENTATION_SUCCESS:
			return {
				...state,
				archivos: action.payload,
				isLoading: false,
			};
		case GET_DOCUMENTATION_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case ADD_ARCHIVO_TIPO_SUCCESS:
			return {
				...state,
				archivoCreated: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default archivoTipoReducer;
