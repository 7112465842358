import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

function AutohideAlert(props) {
	const { open, title, type, message, onClose } = props;

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
		>
			<Alert onClose={onClose} severity={type}>
				<AlertTitle>
					<strong>{title}</strong>
				</AlertTitle>
				{message}
			</Alert>
		</Snackbar>
	);
}

AutohideAlert.propTypes = {};

export default AutohideAlert;
