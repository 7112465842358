import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	buttonSendContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "60px",
	},
}));

export default useStyles;
