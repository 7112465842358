import axios from "axios";
import { BASE_URL } from "../common/constants";

window.BASE_URL = BASE_URL;

const loggedClient = (token) =>
	axios.create({
		baseURL: BASE_URL,
		timeout: 300000,
		headers: {
			"Content-Type": "application/json",
			Authorization: token,
		},
	});

loggedClient().interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		return Promise.reject(error.response);
	}
);

const getLoggedClient = () => {
	let JWT = sessionStorage.getItem("JWT");
	return loggedClient(JWT);
};

export default getLoggedClient;
