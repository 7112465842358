import { forEach, isEmpty } from "lodash";
import allActions from "../../../../actions";
import { getBase64 } from "../../../../common/constants";

export const onUploadFilesCompañia = (
	files,
	setFilesMaxSize,
	siniestroId,
	dispatch
) => {
	const filesMaxSize = files.filter((file) => file.size > 15000000);
	if (!isEmpty(filesMaxSize)) {
		setFilesMaxSize(filesMaxSize.map((file) => file.name));
	} else {
		const filesReadyToPost = [];
		forEach(files, (file) => {
			getBase64(file).then((base64) => {
				filesReadyToPost.push({
					idEntidad: Number(siniestroId),
					nombre: file.name,
					archivo: base64,
					entidad: "siniestro",
				})
				if (files.length === filesReadyToPost.length) {
					dispatch(allActions.archivoActions.newFiles(filesReadyToPost, dispatch))
				}
			});
		});
	}
};

export const onUploadFilesAjustador = (
	files,
	setFilesMaxSize,
	siniestroId,
	dispatch
) => {
	const filesMaxSize = files.filter((file) => file.size > 15000000);
	if (!isEmpty(filesMaxSize)) {
		setFilesMaxSize(filesMaxSize.map((file) => file.name));
	} else {
		const filesReadyToPost = [];
		forEach(files, (file) => {
			getBase64(file).then((base64) => {
				filesReadyToPost.push({
					idEntidad: Number(siniestroId),
					nombre: file.name,
					archivo: base64,
					entidad: "siniestro",
				})
				if (files.length === filesReadyToPost.length) {
					dispatch(allActions.archivoActions.newFiles(filesReadyToPost, dispatch))
				}
			});
		});
	}
};

export const onUploadFilesGeneral = (
	files,
	setFilesMaxSize,
	siniestroId,
	dispatch
) => {
	const filesMaxSize = files.filter((file) => file.size > 15000000);
	if (!isEmpty(filesMaxSize)) {
		setFilesMaxSize(filesMaxSize.map((file) => file.name));
	} else {
		const filesReadyToPost = [];
		forEach(files, (file) => {
			getBase64(file).then((base64) => {
				filesReadyToPost.push({
					idEntidad: Number(siniestroId),
					nombre: file.name,
					archivo: base64,
					entidad: "siniestro",
				})
				if (files.length === filesReadyToPost.length) {
					dispatch(allActions.archivoActions.newFiles(filesReadyToPost, dispatch))
				}
			});
		});
	}
};

export const onUploadFilesLiquidate = (
	files,
	setFilesMaxSize,
	siniestroId,
	dispatch
) => {
	const filesMaxSize = files.filter((file) => file.size > 15000000);
	let saveFileLiquidate = document.getElementById('saveFileLiquidate')
	if (saveFileLiquidate !== null && files.length > 0) {
		let sendFileLiquidate = document.getElementById('sendFileLiquidate')
		sendFileLiquidate.style.display = 'none'
		saveFileLiquidate.style.display = 'none'
	}
	if (!isEmpty(filesMaxSize)) {
		setFilesMaxSize(filesMaxSize.map((file) => file.name));
	} else {
		const filesReadyToPost = [];
		forEach(files, (file) => {
			getBase64(file).then((base64) => {
				filesReadyToPost.push({
					idEntidad: Number(siniestroId),
					nombre: file.name,
					archivo: base64,
					entidad: "siniestro",
				})
				if (files.length === filesReadyToPost.length) {
					dispatch(allActions.archivoActions.newFilesLiquidate(filesReadyToPost, dispatch))
				}
			});
		});
	}
};


