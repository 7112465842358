import {
	SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
	SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR,
	SINIESTRO_TIPO_ARCHIVO_TIPO_START,
	ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
	DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS,
	DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_START,
	GET_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
	ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
	DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS,
} from "../actions/types";

let initialState = {
	allSiniestrosArchivos: [],
	siniestroArchivosAdded: [],
	deletedAsociation: [],
	docExtraSiniestro: [],
	newDocExtra: [],
	deletedDocExtra: [],
	isLoading: false,
	isLoadingDelete: false,
	error: null,
};

const siniestroTipoArchivoTipoReducer = (state = initialState, action) => {
	switch (action.type) {
		case SINIESTRO_TIPO_ARCHIVO_TIPO_START:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_START:
			return {
				...state,
				isLoadingDelete: true,
			};
		case SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS:
			return {
				...state,
				allSiniestrosArchivos: action.payload,
				isLoading: false,
			};
		case SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS:
			return {
				...state,
				siniestroArchivosAdded: action.payload,
				isLoading: false,
			};
		case DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS:
			return {
				...state,
				deletedAsociation: action.payload,
				isLoadingDelete: false,
			};
		case GET_SINIESTRO_ARCHIVO_TIPO_SUCCESS:
			return {
				...state,
				docExtraSiniestro: action.payload,
				isLoading: false,
			};
		case ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS:
			return {
				...state,
				newDocExtra: action.payload,
				isLoading: false,
			};
		case DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS:
			return {
				...state,
				deletedDocExtra: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default siniestroTipoArchivoTipoReducer;
