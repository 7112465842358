import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Ingresa from "./views/Ingresa";
import Denuncia from "./views/Denuncia";
import IndividualSiniestro from "./views/IndividualSiniestro";
import { ContextProvider } from "./context";
import Siniestros from "./views/Siniestros";
import NewCommuncation from "./views/NewCommunication";
import Parametrizacion from "./views/Parametrizacion";
import Documentation from "./views/ParametrizacionDocumentation";
import Templates from "./views/Templates";
import TemplateActions from "./views/TemplateActions";
import Mails from "./views/Mails";
import Navbar from "./components/NavBar";
import NewDocumentation from "./views/NewDocumentation";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import Inicio from "./views/Inicio";
import Ajustadores from "./views/Ajustadores";
import Dashboard from "./views/Dashboard";

function App() {
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	return (
		<ContextProvider>
			<Router>
				<Navbar breadcrumbs={breadcrumbs} />
				<Grid
					container
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						paddingBottom: "20px",
					}}
				>
					<Grid item xs={12} md={9}>
						<Switch>
							<Route exact path="/">
								<Inicio setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/alta-siniestro">
								<Ingresa setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/denuncia">
								<Denuncia setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/siniestro">
								<IndividualSiniestro
									setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/siniestros">
								<Siniestros setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/nueva-comunicacion">
								<NewCommuncation
									setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/parametrizacion">
								<Parametrizacion
									setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/parametrizacion/documentacion">
								<Documentation setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/parametrizacion/nueva-documentacion">
								<NewDocumentation
									setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/parametrizacion/plantillas">
								<Templates setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/parametrizacion/visualizar-plantilla">
								<TemplateActions setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/parametrizacion/editar-plantilla">
								<TemplateActions setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/parametrizacion/nueva-plantilla">
								<TemplateActions
									setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/mails">
								<Mails setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/ajustadores">
								<Ajustadores setBreadcrumbs={setBreadcrumbs} />
							</Route>
							<Route exact path="/dashboard">
								<Dashboard setBreadcrumbs={setBreadcrumbs} />
							</Route>
						</Switch>
					</Grid>
				</Grid>
			</Router>
		</ContextProvider>
	);
}

export default App;
