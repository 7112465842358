import {
    GET_ESTUDIOS_START,
    GET_ESTUDIOS_SUCCES,
    GET_SINIESTROS_ERROR,
    NEW_ESTUDIO_ERROR,
    NEW_ESTUDIO_START,
    NEW_ESTUDIO_SUCCESS,
    PUT_ESTUDIO_START,
    PUT_ESTUDIO_SUCCESS,
    PUT_ESTUDIO_ERROR,
    DELETE_ESTUDIO_START,
    DELETE_ESTUDIO_SUCCESS,
    DELETE_ESTUDIO_ERROR,
    LOW_ESTUDIO_START,
    LOW_ESTUDIO_SUCCESS,
    LOW_ESTUDIO_ERROR,
    UP_ESTUDIO_START,
    UP_ESTUDIO_SUCCESS,
    UP_ESTUDIO_ERROR,
} from "./types"
import Swal from "sweetalert2";
import getLoggedClient from "./getLoggedClient";

const getAllEstudios = () => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({type: GET_ESTUDIOS_START});

    client
        .get("/estudiosAjustadores")
        .then((response) =>{
            dispatch({
                type: GET_ESTUDIOS_SUCCES,
                payload: response.data.result,
            })
        })
        .catch((err) => {
            dispatch({
                type:GET_SINIESTROS_ERROR,
                payload: err,
            })
        })
}

const newEstudio = (data) => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch ({type: NEW_ESTUDIO_START});

    client
        .post("/newEstudio", data)
        .then((response) => {
            dispatch ({
                type: NEW_ESTUDIO_SUCCESS,
                payload: response.data.result,
            })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Creado',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch((err) => {
            dispatch({
                type: NEW_ESTUDIO_ERROR,
                payload: err,
            })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
              })
        })
}

const putEstudio = (id,data) => async (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({type: PUT_ESTUDIO_START})
    
    client
        .put("/putEstudio",id,data)
        .then((response) => {
            dispatch({
                type: PUT_ESTUDIO_SUCCESS,
                payload: response.data.result
            })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Modificado',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch((err) => {
            dispatch({
                type: PUT_ESTUDIO_ERROR,
                payload: err
            })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
              })
        })
}
const deleteEstudio = (data) => (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch ({type: DELETE_ESTUDIO_START})

    client
        .post("/deleteEstudio",data)
        .then((response) => {
            dispatch({
                type: DELETE_ESTUDIO_SUCCESS,
                payload: response.data.result
            })
            
        })
        .catch((err) => {
            dispatch({
                type: DELETE_ESTUDIO_ERROR,
                payload: err
            })
        })
}
const lowEstudio = (data) => (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({type: LOW_ESTUDIO_START})
    client
        .put("/lowEstudio",data)
        .then((response) => {
            dispatch ({
                type: LOW_ESTUDIO_SUCCESS,
                payload: response.data.result
            })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Baja Exitosa',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch((err) => {
            dispatch({
                type: LOW_ESTUDIO_ERROR,
                payload: err
            })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
              })
        })
}
const upEstudio = (data) => (dispatch, getState) => {
    const client = getLoggedClient();
    dispatch({type: UP_ESTUDIO_START})
    client
        .put("/upEstudio",data)
        .then((response) => {
            dispatch ({
                type: UP_ESTUDIO_SUCCESS,
                payload: response.data.result
            })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Alta Exitosa',
                showConfirmButton: false,
                timer: 1500
              })
        })
        .catch((err) => {
            dispatch({
                type: UP_ESTUDIO_ERROR,
                payload: err
            })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Algo salió mal',
                text: 'Intenta de nuevo',
                showConfirmButton: false,
                timer: 1500
              })
        })
}
const estudiosActions = {
    getAllEstudios,
    newEstudio,
    putEstudio,
    deleteEstudio,
    upEstudio,
    lowEstudio,
}

export default estudiosActions;