import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    tagsContainer: {
        fontSize: "14px",
        border: "1px solid #e3e3e3",
        backgroundColor: "#f5f5f5",
        padding: "19px",
        borderRadius: "4px",
        marginTop: "2%",
        "& > span": {
            fontWeight: "700"
        },
    },
    tagsItemContainer: {
        marginTop: "2%",
        width: "100%",
        "& > p": {
            "& > input": {
                cursor: "pointer",
                outline: 'none',
                border: 'none',
                backgroundColor: "whitesmoke",
                width: "16%",
            },
        }
    },
}))