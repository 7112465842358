
import {
    GET_AJUSTADORES_START,
    GET_AJUSTADORES_SUCCESS,
    GET_AJUSTADORES_ERROR,
    GET_AJUSTADORESIDESTUDIO_START,
    GET_AJUSTADORESIDESTUDIO_SUCCESS,
    GET_AJUSTADORESIDESTUDIO_ERROR,
    GET_AJUSTADOR_START,
    GET_AJUSTADOR_SUCCESS,
    GET_AJUSTADOR_ERROR,
} from "../actions/types";

let initialState = {
    allAjustadores: [],
    ajustadoresIdEstudio: [],
    ajustadorAct : [],
    isLoading: false,
    error: null,
};

const ajustadoresReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AJUSTADORES_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_AJUSTADORES_SUCCESS:
            return {
                ...state,
                allAjustadores: action.payload,
                isLoading: false,
                
            }
        case GET_AJUSTADORES_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case GET_AJUSTADORESIDESTUDIO_START:
            return {
                ...state,
                isLoading: true,
            }
        case GET_AJUSTADORESIDESTUDIO_SUCCESS:
            return {
                ...state,
                ajustadoresIdEstudio: action.payload,
                isLoading: false
            }
        case GET_AJUSTADORESIDESTUDIO_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        case GET_AJUSTADOR_START:
            return {
                ...state,
                isLoading: true,
            }
        case GET_AJUSTADOR_SUCCESS:
            return {
                ...state,
                ajustadorAct: action.payload,
                isLoading: false,
            }
        case GET_AJUSTADOR_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        default:
			return state;
    }
};

export default ajustadoresReducer;