import {
  GET_CLIENTS_START,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
} from "../actions/types";

let initialState = {
  allClients: [],
  isLoading: false,
  error: null,
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        allClients: action.payload,
        isLoading: false,
      };
    case GET_CLIENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default clientsReducer;
