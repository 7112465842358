import {
	CircularProgress,
	Grid,
	IconButton,
	Link,
	Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { gt, isEqual } from "lodash";

import {
	ESTADOS_ID,
	getItemSessionStorage,
} from "../../../../common/constants";
import clsx from "clsx";
import allActions from "../../../../actions";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import WarningIcon from "@material-ui/icons/Warning";

export const editableInput = (
	editNro,
	siniestroNumber,
	setSiniestroNumber,
	handleEdit,
	isLoading,
	classes
) => (
	<Grid>
		{editNro ? (
			<input
				className={classes.inputEditable}
				maxLength={15}
				autoFocus
				value={siniestroNumber}
				onChange={(e) => setSiniestroNumber(e.target.value)}
			/>
		) : (
			"NN"
		)}
		{editNro ? (
			<>
				{isLoading ? (
					<CircularProgress size={20} />
				) : (
					<>
						<IconButton
							onClick={handleEdit(true)}
							disabled={!siniestroNumber}
						>
							<CheckCircleIcon
								style={{
									color: siniestroNumber && "green",
									fontSize: "16px",
								}}
							/>
						</IconButton>
						<IconButton onClick={handleEdit(false)}>
							<CancelIcon
								style={{ color: "red", fontSize: "16px" }}
							/>
						</IconButton>
					</>
				)}
			</>
		) : (
			<IconButton onClick={handleEdit(false)}>
				<EditIcon />
			</IconButton>
		)}
	</Grid>
);

export const getOptionsSelectEstados = (siniestroEstadoReducer) => {
	return (
		siniestroEstadoReducer.allStates?.result?.filter(
			(estado) =>
				!isEqual(estado.siniestroEstado_id, ESTADOS_ID.baja) &&
				!isEqual(estado.siniestroEstado_id, ESTADOS_ID.liquidado) &&
				!isEqual(estado.siniestroEstado_id, ESTADOS_ID.enProcesoDePago) &&
				!isEqual(estado.siniestroEstado_id, ESTADOS_ID.rechazado) &&
				!isEqual(estado.siniestroEstado_id, ESTADOS_ID.pagado)
		) || []
	);
};

export const getDataSiniestro = (
	OD,
	showNOperacion,
	setShowNOperacion,
	classes
) => {
	return (
		<>
			<Typography className={classes.typographyData}>
				<strong>Operacion</strong>:
				<Link
					href={`http://sistema.siwcargo.com/adm/cert_.php?ID=${OD.idCert}`}
					target="_blank"
					className={clsx(classes.link, classes.typographyData)}
					onMouseOver={() => setShowNOperacion(!showNOperacion)}
					onMouseLeave={() => setShowNOperacion(!showNOperacion)}
				>
					&nbsp;
					{showNOperacion
						? OD.nOperacion
						: gt(OD.nOperacion?.length, 16)
							? OD.nOperacion?.slice(0, 16) + "..."
							: OD.nOperacion}
				</Link>
			</Typography>
			<Typography className={classes.typographyData}>
				<strong>Riesgo</strong>: {OD.tipoRiesgo}
			</Typography>
			<Typography className={classes.typographyData}>
				<strong>Suma Asegurada</strong>: ${OD.sat}
			</Typography>
			<Typography className={classes.typographyData}>
				<strong>Mercaderia:</strong>
				{OD.mercaderiaTransportada}
			</Typography>
			<Typography className={classes.typographyData}>
				<strong>Reclamante: </strong>
				{OD.consignee}
			</Typography>
		</>
	);
};

export const getDataTitulo = (data, id, key) => {
	const titulo = data?.find((es) => isEqual(es?.[key], id))?.titulo;
	return titulo;
};

export const onComunicationClick = (history, siniestroId) => (comunicacion) => {
	let redirectTo = comunicacion.titulo === 'e-mail'
		? `/mails?threadId=${comunicacion.email.threadId}&emailId=undefined`
		: `/nueva-comunicacion?id=${comunicacion.comunicacion_id}`;
	history.push(redirectTo, { siniestroId });
};

export const onNewCommunicationClick = (history, siniestroId, entidad) => () => {
	history.push(`/nueva-comunicacion/?entidad=${entidad}`, { siniestroId });
};

export const handleFinalizacionByEstado =
	(
		enProcesoDePago,
		isBaja,
		setIsOpenFinishDialog,
		setOpenDialogLiquidar,
		openDialogLiquidar,
		isOpenFinishDialog
	) =>
		() => {
			if (enProcesoDePago) {
				setOpenDialogLiquidar(!openDialogLiquidar);
			} else if (isBaja) {
				setIsOpenFinishDialog("bajaRollback");
			} else {
				setIsOpenFinishDialog(!isOpenFinishDialog);
			}
		};

export const onLiquidarSiniestro = (dispatch, siniestroId) => () => {
	dispatch(
		allActions.siniestroActions.updateSiniestroEstado({
			userId: getItemSessionStorage("idUsuario"),
			estadoId: ESTADOS_ID.liquidado,
			siniestroId,
		})
	);
};

export const getEstadoRender = (
	isBaja,
	enProcesoDePago,
	isRechazo,
	estado,
	classes
) => {
	return (
		<Typography
			variant="h4"
			className={clsx(
				isBaja || isRechazo
					? classes.bajaText
					: enProcesoDePago
						? classes.procesoDePagoText
						: classes.liquidacionText
			)}
		>
			<span className={classes.iconsEstadosContainer}>
				{isBaja || enProcesoDePago || isRechazo ? (
					<WarningIcon className={classes.iconsEstados} />
				) : (
					<MonetizationOnIcon className={classes.iconsEstados} />
				)}
			</span>
			{estado}
		</Typography>
	);
};



export const getIdByTitle = (data, id, title) => {
	const ans = data?.find((es) => isEqual(es?.titulo, title))?.[id];

	return ans;
};
