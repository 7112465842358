import {
	SEND_EMAIL_ERROR,
	SEND_EMAIL_SUCCESS,
	SEND_EMAIL_START,
	GET_THREAD_SUCCESS,
	SEND_EMAIL_COMPANY_START,
	SEND_EMAIL_COMPANY_SUCCESS,
	SEND_EMAIL_COMPANY_ERROR,
	SEND_EMAIL_CLAIM_DOC_START,
	SEND_EMAIL_CLAIM_DOC_SUCCESS,
	SEND_EMAIL_CLAIM_DOC_ERROR
} from "../actions/types";

let initialState = {
	sentEmail: [],
	sendEmailCompany: [],
	sendEmailClaimDoc: [],
	thread: [],
	isLoading: false,
	error: null,
};

const clientsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SEND_EMAIL_START:
			return {
				...state,
				isLoading: true,
			};
		case SEND_EMAIL_SUCCESS:
			return {
				...state,
				sentEmail: action.payload,
				isLoading: false,
				error: null,
			};
		case SEND_EMAIL_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case SEND_EMAIL_COMPANY_START:
			return {
				...state,
				isLoading: true,
			};
		case SEND_EMAIL_COMPANY_SUCCESS:
			return {
				...state,
				sendEmailCompany: action.payload,
				isLoading: false,
				error: null,
			};
		case SEND_EMAIL_COMPANY_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case SEND_EMAIL_CLAIM_DOC_START:
			return {
				...state,
				isLoading: true,
			};
		case SEND_EMAIL_CLAIM_DOC_SUCCESS:
			return {
				...state,
				sendEmailClaimDoc: action.payload,
				isLoading: false,
				error: null,
			};
		case SEND_EMAIL_CLAIM_DOC_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case GET_THREAD_SUCCESS:
			return {
				...state,
				thread: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default clientsReducer;
