import React, {useState} from "react";
import {Box,Collapse,IconButton,Table,TableBody,TableHead,TableRow,Typography, TableCell} from '@material-ui/core';
import useStyles from "../styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DialogEditEstudio from "./DialogEditEstudio"
import EditIcon from "@material-ui/icons/Edit"
import DialogEditAjustador from "./DialogEditAjustador";
function Row(props) {

    const { estudio, ajustadores, load, setLoad } = props
    const [open, setOpen] = useState(false);
    const [openEditA, setOpenEditA] = useState(false)
    const [openEditE, setOpenEditE] = useState(false)
    const [estudioAct, setEstudioAct] = useState ({})
    const [ajustadorAct, setAjustadorAct] = useState ({})
    const classes = useStyles();
    const handleActiveE = (e) => {
      setOpenEditE(true)
      setEstudioAct(e) 
    }
    const handleActiveA = (e) => {
      setOpenEditA(true)
      setAjustadorAct(e)
    }
    return (
      <React.Fragment>
        <TableRow className={classes.rootRow} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell className={classes.tableCellRow}>
            <IconButton
              aria-label="expand row"
              size="small" Row
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.tableHeaderRow} component="th" scope="row">
            {estudio.titulo}
          </TableCell>
          <TableCell className={classes.tableHeaderRow} >{estudio.email}</TableCell>
          <TableCell className={classes.tableHeaderRow} >{estudio.telefono}</TableCell>
          <TableCell className={classes.tableHeaderRow} >{estudio.direccion}</TableCell>
          <TableCell className={classes.tableHeaderRow} align="right">
            <EditIcon onClick={() => {handleActiveE(estudio)}} style={{ fontSize: "25px" }} />
          </TableCell>
          <TableCell className={classes.tableHeaderRow} align="right">{estudio.activo}</TableCell>
          
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography className={classes.tableHead}  variant="h6" gutterBottom component="div">
                  Ajustadores
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell className={classes.tableCellRow}>Nombre</TableCell>
                      <TableCell className={classes.tableCellRow}>Email</TableCell>
                      <TableCell className={classes.tableCellRow}align="right">Telefono</TableCell>
                      <TableCell className={classes.tableCellRow} align="right">Estado</TableCell>
                      <TableCell className={classes.tableCellRow} align="right">Editar</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ajustadores.map((ajustador) => (
                      <TableRow key={ajustador.ajustador_id}>
                        <TableCell className={classes.tableCellRowItem} component="th" scope="row">
                          {ajustador.nombre + " " + ajustador.apellido}
                        </TableCell>
                        <TableCell className={classes.tableCellRowItem}>{ajustador.email}</TableCell>
                        <TableCell className={classes.tableCellRowItem} align="right">{ajustador.telefono}</TableCell>
                        <TableCell className={classes.tableCellRowItem} align="right">
                          {ajustador.activo}
                        </TableCell>
                        <TableCell className={classes.tableCellRowItem}  align="right">
                        <EditIcon onClick={() => {handleActiveA(ajustador)}} style={{ fontSize: "25px" }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <DialogEditEstudio load={load} setLoad={setLoad} openEditE={openEditE} setOpenEditE={setOpenEditE} estudio={estudioAct}></DialogEditEstudio>
        <DialogEditAjustador load={load} setLoad={setLoad} openEditA={openEditA} setOpenEditA={setOpenEditA} ajustador={ajustadorAct}></DialogEditAjustador>
      </React.Fragment>
    );
  }

  export default Row;