import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
	buttonAdd: {
		fontSize: "13px !important",
		color: "#2930FF",
		margin: "15px 8px",
		padding: "0px 10px",
		borderColor: "#2930FF",
		borderRadius: "100px",
		textTransform: "none",
		marginLeft: "20px",
	},
	addIcon: {
		marginRight: "6px",
	},
	cancelIcon: {
		marginLeft: "20px",
		fontSize: "16px",
		color: "red",
		cursor: "pointer",
	},
	checkIcon: {
		color: "green",
	},
	checkIconDisabled: {
		color: "rgba(0, 0, 0, 0.26)",
		cursor: "inherit",
	},
	input: { width: "70%" },
	inputContainer: {
		display: "flex",
		alignItems: "center",
		padding: "10px 0",
	},
	inputEditable: {
		width: "100%",
		outline: "none",
		"& .MuiFormLabel-root": {
			fontSize: "11.5px !important",
		},
	},
});

export default useStyles;
