import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "../../components/FormGroup";
import FormLabel from "../../components/FormLabel";
import ButtonBootstrap from "../../components/Button";
import Select from "../../components/Select";
import allActions from "../../actions";
import { ContainerStyled, FormStyled, ButtonContainerStyled, useStyles } from "./styles";
import { isEqual } from "lodash";
import { Snackbar, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ComposedFormGroup from "../../components/ComposedFormGroup";
import RadioForm from "../../components/RadioGroup";
import { Alert } from "@material-ui/lab";
import FormControl from "../../components/FormControl";
import { createBreadcrumbData, toLocaleDateString } from "../../common/constants";
import FilesList from "./components/FileList";
import RichTextEditor from "../../components/RichTextEditor"

const RADIO_OPTIONS = [
	{ label: "Entrante", value: "receptor" },
	{ label: "Saliente", value: "emisor" },
];

function NewCommunication(props) {
	const { setBreadcrumbs } = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const comunicationsReducer = useSelector((state) => state.comunicationsReducer);
	const idComunicacion = useSelector((state) => state.comunicationsReducer.newComunication?.result?.insertId)
	const archivoReducer = useSelector((state) => state.archivoReducer);
	const templatesReducer = useSelector((state) => state.templatesReducer);
	const siniestroId = history.location.state?.siniestroId;
	const comunicationId = new URLSearchParams(window.location.search).get("id");
	const entidad = new URLSearchParams(window.location.search).get("entidad");
	const arrayAllTemplates = templatesReducer?.allTemplates?.result;
	const individualTemplate = templatesReducer?.individualTemplate?.result;
	const singleComunication = comunicationsReducer.comunication?.result || {};
	const [countOfCharacters, setCountOfCharacters] = useState(0);
	const [alertAutohide, setAlertAutohide] = useState({
		open: false,
		type: "error",
		message: "",
	});
	const [checked, setChecked] = useState([])
	const handleAlertOpen = () => setAlertAutohide({ ...alertAutohide, open: !alertAutohide.open });
	const [dataIdComunicacion, setDataIdComunicacion] = useState({
		comunicacionTipo_id: "",
	});
	const [emailSubjectData, setEmailSubjectData] = useState({
		subject: "",
	});
	const [defaultData, setDefaultData] = useState({
		asunto: '',
		comunicacionFecha: "",
		contacto: "",
		resumen: "",
		tipoComunicador: "emisor",
		comunicacionTipo_id: ""
	});
	const [emailData, setEmailData] = useState({
		comunicacionFecha: "",
		message: "",
		recipient: "",
		comunicacionTipo_id: ""
	});
	const isThereTemplateSelected = individualTemplate?.plantilla_id;

	useEffect(() => {
		setDefaultData({ ...defaultData, comunicacionTipo_id: dataIdComunicacion.comunicacionTipo_id })
		setEmailData({ ...emailData, comunicacionTipo_id: dataIdComunicacion.comunicacionTipo_id })
	}, [dataIdComunicacion]);

	useEffect(() => {
		if (siniestroId && comunicationId && !(siniestroId || comunicationId)) {
			history.push("/siniestros");
		}
		if (comunicationId) {
			dispatch(allActions.comunicationsActions.getComunicationById(comunicationId));
		} else {
			dispatch(allActions.comunicationsActions.getComunicationsTypes());
		}
	}, [siniestroId, comunicationId, history, dispatch]);

	useEffect(() => {
		if (isEqual(comunicationsReducer.newComunication?.status, "success")) {
			setAlertAutohide({
				type: "success",
				message: "Comunicacion creada con exito",
				open: true,
			});
			setTimeout(() => {
				window.location.reload(true)
			}, 1000);

		} else if (isEqual(comunicationsReducer.newComunication?.status, "error") || comunicationsReducer.error) {
			setAlertAutohide({
				type: "error",
				message: comunicationsReducer.error?.data?.result || "Hubo un error",
				open: true,
			});
		}
	}, [comunicationsReducer, dispatch]);

	useEffect(() => {
		setBreadcrumbs([
			createBreadcrumbData(`/siniestros`, `Siniestros`),
			createBreadcrumbData(
				`/siniestro?id=${siniestroId}`,
				`Siniestro #${siniestroId}`
			),
			createBreadcrumbData(`/nueva-comunicacion`, "Nueva comunicación"),
		]);
	}, [siniestroId, setBreadcrumbs]);

	useEffect(() => {
		dispatch(allActions.templatesActions.getAllTemplates())
	}, []);

	useEffect(() => {
		if (individualTemplate) {
			setEmailData({ ...emailData, message: individualTemplate?.cuerpo })
			setEmailSubjectData({ ...emailSubjectData, subject: individualTemplate?.asunto })
		}
	}, [isThereTemplateSelected]);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value.archivo_id);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value.archivo_id);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	const onChangeDefaultData = (prop, val) => {
		if (isEqual(prop, "resumen")) {
			setCountOfCharacters(val.length);
		};
		setDefaultData({ ...defaultData, [prop]: val });
	};
	const onChangeEmailData = (prop, val) => {
		setEmailData({ ...emailData, [prop]: val });
	};

	const onChangeEmailSubject = (prop, val) => {
		setEmailSubjectData({ ...emailSubjectData, [prop]: val });
	};

	const onChangeEmailMessage = (prop, val) => {
		setEmailData({ ...emailData, [prop]: val });
	};

	const onChangeTemplates = (prop, val) => {
		dispatch(allActions.templatesActions.getTemplate(val.plantilla_id))
	};

	const onSubmit = (e) => {
		e.preventDefault();

		if (siniestroId) {
			let dataToSend = {
				...defaultData,
				entidad_comunicacion: entidad,
				siniestro_id: siniestroId,
			};
			dispatch(allActions.comunicationsActions.newComunication(dataToSend));
		} else if (comunicationId) {
			dispatch(allActions.comunicationsActions.updateComunication());
		}
	};

	const SubmitEmail = (e) => {
		e.preventDefault();

		const emailDataReadyToPost = {
			asunto: emailSubjectData.subject,
			contacto: emailData.recipient,
			resumen: emailData.message,
			comunicacionFecha: emailData.comunicacionFecha,
			comunicacionTipo_id: emailData.comunicacionTipo_id,
			siniestro_id: siniestroId,
			entidad_comunicacion: entidad,
		};
		dispatch(allActions.comunicationsActions.newComunication(emailDataReadyToPost));
	};

	const defaultForm = () => {
		return (
			<>
				<ComposedFormGroup
					placeholder="Asunto de la comunicación"
					id="asunto"
					onChange={onChangeDefaultData}
					value={comunicationId ? singleComunication.asunto : defaultData.asunto}
					isDisabled={Boolean(comunicationId)}
					isRequired
				/>
				<ComposedFormGroup
					placeholder="Fecha de la comunicación"
					typeInput={comunicationId ? "text" : "date"}
					id="comunicacionFecha"
					onChange={onChangeDefaultData}
					isRequired
					value={comunicationId ? toLocaleDateString(singleComunication.comunicacionFecha) : defaultData.comunicacionFecha}
					isDisabled={Boolean(comunicationId)}
				/>
				<ComposedFormGroup
					placeholder="Contacto"
					id="contacto"
					onChange={onChangeDefaultData}
					isRequired
					value={comunicationId ? singleComunication.contacto : defaultData.contacto}
					isDisabled={Boolean(comunicationId)}
				/>
				<ComposedFormGroup
					placeholder="Resumen"
					id="resumen"
					onChange={onChangeDefaultData}
					isRequired
					inputAs="textarea"
					helperText={!comunicationId && `${countOfCharacters}/1000 carácteres`}
					rows={4}
					value={comunicationId ? singleComunication.resumen : defaultData.resumen}
					isDisabled={Boolean(comunicationId)}
					maxLength={3000}
				/>
				<RadioForm
					value={comunicationId ? "emisor" : defaultData.tipoComunicador}
					data={RADIO_OPTIONS}
					label="Tipo de comunicador"
					id={"tipoComunicador"}
					onChange={onChangeDefaultData}
					customStyles={classes.radioGroup}
					isDisabled={Boolean(comunicationId)}
				/>
			</>
		)
	};

	const emailForm = () => {
		return (
			<>
				<FormGroup className={classes.selectTemplate}>
					<FormLabel text="Plantillas" />
					<Select
						customStyles={classes.selectTemplate}
						options={arrayAllTemplates || []}
						keyToShow="titulo"
						onChange={onChangeTemplates}
						isRequired
					/>
				</FormGroup>
				<ComposedFormGroup
					placeholder="Asunto de la comunicación"
					id="subject"
					onChange={onChangeEmailSubject}
					value={comunicationId ? singleComunication.asunto : emailSubjectData.subject || individualTemplate?.asunto}
					isDisabled={Boolean(comunicationId)}
					isRequired
				/>
				<ComposedFormGroup
					placeholder="Fecha de la comunicación"
					typeInput={comunicationId ? "text" : "date"}
					id="comunicacionFecha"
					onChange={onChangeEmailData}
					isRequired
					value={comunicationId ? toLocaleDateString(singleComunication.comunicacionFecha) : emailData.comunicacionFecha}
					isDisabled={Boolean(comunicationId)}
				/>
				<ComposedFormGroup
					placeholder="Receptor"
					typeInput="email"
					id="recipient"
					onChange={onChangeEmailData}
				/>
				<RichTextEditor
					onChange={onChangeEmailMessage}
					id="message"
					value={emailData.message || individualTemplate?.cuerpo}
					customStyles={classes.quill}
				/>
				<FilesList
					customStyles={classes.flieList}
					files={archivoReducer.allFiles?.filter((fi) => !fi.segmentacionEntidad)}
					checked={checked}
					handleToggle={handleToggle}
				/>
			</>

		)
	};

	const comunicationsTypes = comunicationsReducer.comunicationsTypes?.result || [];

	return (
		<ContainerStyled data-testid="NewCommunication-view">
			<FormStyled onSubmit={dataIdComunicacion.comunicacionTipo_id === 1 ? SubmitEmail : onSubmit}>
				<FormLabel text="Tipo de comunicación" />
				{comunicationId ? (
					<FormControl
						value={singleComunication.tipoComunicacionTitulo}
						isDisabled
					/>
				) : (
					<Grid className={classes.buttonsContainer}>
						{comunicationsTypes.map(item => (
							<Button
								onClick={() => setDataIdComunicacion({ ...dataIdComunicacion, comunicacionTipo_id: item.comunicacionTipo_id })}
								variant={dataIdComunicacion.comunicacionTipo_id === item.comunicacionTipo_id ? 'contained' : 'outlined'}
								color={dataIdComunicacion.comunicacionTipo_id === item.comunicacionTipo_id ? 'primary' : 'primary'}
								id="comunicacionTipo_id"
							>{item.titulo}</Button>
						))}
					</Grid>
				)}
				{dataIdComunicacion.comunicacionTipo_id === 1 ? emailForm() : defaultForm()}
				<ButtonContainerStyled>
					<ButtonBootstrap
						text="Enviar y registrar"
						type="submit"
						isLoading={comunicationsReducer.isLoading}
						isDisabled={Boolean(comunicationId)}
					/>
				</ButtonContainerStyled>
			</FormStyled>
			<Snackbar
				open={alertAutohide.open}
				autoHideDuration={6000}
				onClose={handleAlertOpen}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert onClose={handleAlertOpen} severity={alertAutohide.type}>
					{alertAutohide.message}
				</Alert>
			</Snackbar>
		</ContainerStyled>
	);
}

export default NewCommunication;

