import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "../../components/FormGroup";
import FormLabel from "../../components/FormLabel";
import Button from "../../components/Button";
import Select from "../../components/Select";
import allActions from "../../actions";
import { ContainerStyled, CardStyled, FormStyled, ButtonContainerStyled } from "./styles";
import AutocompleteInput from "../../components/Autocomplete";
import { isArray, isEqual } from "lodash";
import { FormHelperText, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { createBreadcrumbData } from "../../common/constants";

function Ingresa(props) {
	const { setBreadcrumbs } = props;
	const dispatch = useDispatch();
	const history = useHistory();
	//estados desde redux
	const operationReducer = useSelector((state) => state.operationReducer);
	const altaSiniestroReducer = useSelector((state) => state.altaSiniestroReducer);
	const clientsReducer = useSelector((state) => state.clientsReducer);
	const createSiniestroReducer = useSelector((state) => state.createSiniestroReducer);
	const [helperText, setHelperText] = useState("");
	const [data, setData] = useState({
		nOperation: "",
		tipoSiniestro: "",
		client: "",
	});

	useEffect(() => {
		setBreadcrumbs([
			createBreadcrumbData(`/siniestros`, `Siniestros`),
			createBreadcrumbData(`/alta-siniestro`, `Nuevo siniestro`),
		]);
	}, [setBreadcrumbs]);

	const onChange = (prop, val, typeOperation) => {
		setData({ ...data, [prop]: val });
	};
	//carga de nuevo siniestro
	const onSubmit = (e) => {
		e.preventDefault();

		if (data.tipoSiniestro && data.nOperation) {
			setHelperText("");
			dispatch(
				allActions.altaSiniestroActions.registerSiniestro({
					numeroOperacion: data.nOperation.numeroOperacion,
					tipoSiniestro: data.tipoSiniestro.siniestroTipo_id,
					isAdmin: true,
				})
			);
		} else {
			setHelperText("Complete todos los campos para proseguir");
		}
	};

	useEffect(() => {
		if (isEqual(createSiniestroReducer.createdSiniestro?.status, "succcess")) {
			setHelperText("");
			history.push("/denuncia");
		}
		if (createSiniestroReducer.error) {
			setHelperText(createSiniestroReducer.error.data?.result);
		}
		dispatch(allActions.altaSiniestroActions.getSiniestros());
	}, [dispatch, createSiniestroReducer, history]);

	const dispatchToUse = (id, newValue) => {
		if (id === "client")
			dispatch(allActions.clientsActions.getAllClients(newValue));
		else
			dispatch(
				allActions.altaSiniestroActions.getOperationByNumber(
					data.client.idCliente,
					newValue
				)
			);
	};

	const onAutocompleteChange = (prop) => (_, newValue, typeOperation) => {
		newValue.length >= 3 &&
			!isEqual(typeOperation, "reset") &&
			setTimeout(dispatchToUse(prop, newValue), 300);
	};

	return (
		<ContainerStyled data-testid="ingresa-view">
			<CardStyled>
				<FormStyled onSubmit={onSubmit}>
					<FormGroup>
						<FormLabel text="Nombre del cliente" />
						<AutocompleteInput
							id="client"
							onChange={onChange}
							onInputChange={onAutocompleteChange("client")}
							value={data.client}
							isLoading={clientsReducer.isLoading}
							options={
								(isArray(clientsReducer.allClients) &&
									clientsReducer.allClients) ||
								[]
							}
							getOptionLabel={(val) => val.cliente}
							placeholder="Nombre del cliente"
							helperText="Ingrese los 3 primeros caracteres"
						/>
					</FormGroup>
					<FormGroup>
						<FormLabel text="Número de operación" />
						<AutocompleteInput
							id="nOperation"
							onChange={onChange}
							onInputChange={onAutocompleteChange("nOperation")}
							value={data.nOperation}
							isLoading={operationReducer.isLoading}
							options={operationReducer.allOperations}
							getOptionLabel={({
								numeroOperacion,
								posicion,
								tomador,
							}) => `${numeroOperacion}, ${posicion}, ${tomador}`}
							placeholder="Número de operación"
							isRequired
							helperText="Ingrese los 3 primeros caracteres"
							isDisabled={!data.client}
						/>
					</FormGroup>
					<FormGroup>
						<Grid style={{ marginBottom: "0.5%" }} >
							<FormLabel text="Tipo de siniestro" />
						</Grid>
						<Select
							options={
								(isArray(altaSiniestroReducer.siniestros) &&
									altaSiniestroReducer.siniestros) || []
							}
							keyToShow="titulo"
							onChange={onChange}
							id="tipoSiniestro"
							isRequired
							isDisabled={!data.client}
						/>
					</FormGroup>
					{helperText && (
						<FormHelperText
							style={{ fontSize: "13px", marginBottom: "15px" }}
							error
						>
							{helperText}
						</FormHelperText>
					)}
					<ButtonContainerStyled>
						<Button
							text="Enviar y registrar"
							type="submit"
							isLoading={createSiniestroReducer.isLoading}
						/>
					</ButtonContainerStyled>
				</FormStyled>
			</CardStyled>
		</ContainerStyled>
	);
}

export default Ingresa;
