import React, {useState, useEffect} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import allActions from "../../../actions";
export default function DialogEditEstudio (props) {
    const {openEditE, setOpenEditE, estudio,load, setLoad} = props
    const [estudioActivo, setEstudioActivo] = useState({})
    const dispatch = useDispatch();
    useEffect(() => {
      setEstudioActivo({
        id:estudio.id_estudio,
        titulo: estudio.titulo,
        direccion: estudio.direccion,
        email: estudio.email,
        telefono: estudio.telefono
      })
      
    },[estudio])
    const handleClose = () => {
        setOpenEditE(false)
    }
    const handleSave = (estudioActivo) => {

        const body = { 
            id: estudio.id_estudio,
            titulo: estudioActivo.titulo,
            direccion: estudioActivo.direccion,
            email: estudioActivo.email,
            telefono: estudioActivo.telefono
        }
        
        dispatch(allActions.estudiosActions.putEstudio(body))
        setLoad(!load)
        setOpenEditE(false)
    }
    const handleChange = (e) => {
        setEstudioActivo({
            ...estudioActivo,
            [e.target.name] : e.target.value
        })
    }
    const handleState = (estudio) => {
      const id = estudio.id_estudio
      if(estudio.activo === "activo"){
        dispatch(allActions.estudiosActions.lowEstudio({id}))
        setLoad(!load)
        setOpenEditE(false)
      }else{
        dispatch(allActions.estudiosActions.upEstudio({id}))
        setLoad(!load)
        setOpenEditE(false)
      }
    }
    return (
        <Dialog open={openEditE} onClose={handleClose}>
        <DialogTitle>Editar estudio {estudio.titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edita la información de un estudio.
          </DialogContentText>
          <TextField
            defaultValue={estudio.titulo}
            onChange={handleChange}
            margin="dense"
            name="titulo"
            label="Nombre del estudio"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            defaultValue={estudio.email}
            onChange={handleChange}
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            defaultValue={estudio.telefono}
            onChange={handleChange}
            margin="dense"
            name="telefono"
            label="Teléfono"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            defaultValue={estudio.direccion}
            onChange={handleChange}
            margin="dense"
            name="direccion"
            label="Dirección"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => {handleState(estudio)}}>{estudio.activo === "activo" ? "Dar de baja" : "Dar de alta"}</Button>
          <Button onClick={() => {handleSave(estudioActivo)}}>Guardar</Button>
        </DialogActions>
      </Dialog>
    )
}
