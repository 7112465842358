import {
  GET_CLIENTS_START,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
} from "./types";
import getLoggedClient from "./getLoggedClient";

const getAllClients = (nombreSearch) => async (dispatch, getState) => {
  const client = getLoggedClient();
  dispatch({ type: GET_CLIENTS_START });

  client
    .get(`/clientes?nombre=${nombreSearch}`)
    .then((response) => {
      dispatch({ type: GET_CLIENTS_SUCCESS, payload: response.data.result });
    })
    .catch((error) => {
      dispatch({ type: GET_CLIENTS_ERROR, payload: error });
    });
};

const clientsActions = {
  getAllClients,
};
export default clientsActions;
