import altaSiniestroActions from "./altaSiniestroActions";
import sessionActions from "./sessionActions";
import clientsActions from "./clientsActions";
import siniestroActions from "./siniestroActions";
import comunicationsActions from "./comunicationsActions";
import commentsActions from "./commentsActions";
import archivoActions from "./archivoActions";
import archivoTipoActions from "./archivoTipoActions";
import siniestroTipoArchivoTipoActions from "./siniestroTipoArchivoTipoActions";
import emailsActions from "./mailsActions";
import ajustadoresActions from "./ajustadorActions";
import estudiosActions from "./estudioActions";
import templatesActions from "./templatesActions";

const allActions = {
	altaSiniestroActions,
	sessionActions,
	clientsActions,
	siniestroActions,
	comunicationsActions,
	commentsActions,
	archivoActions,
	archivoTipoActions,
	siniestroTipoArchivoTipoActions,
	emailsActions,
	ajustadoresActions,
	estudiosActions,
	templatesActions
};

export default allActions;
