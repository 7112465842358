import React, { useState } from "react";
import { CircularProgress, Grid, IconButton, SwipeableDrawer } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import logo from "../../assets/images/logoSiw.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import SimpleBreadcrumbs from "../Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_SINIESTRO_SUCCESS, SINIESTROS_REPORTE_SUCCESS } from "../../actions/types";
import allActions from "../../actions";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { exportToExcel } from "../../common/constants";

function NavBar(props) {
	const { breadcrumbs } = props;
	const dispatch = useDispatch();
	const classes = useStyles();
	const [sideMenu, setSideMenu] = useState(false);

	const toggleDrawer = () => setSideMenu(!sideMenu);
	const siniestrosReporteReducer = useSelector(
		(state) => state.siniestrosReporteReducer
	);

	const handleDownloadReport = () => {
		dispatch(allActions.siniestroActions.getSiniestroReporte());
	};

	useEffect(() => {
		if (
			!isEmpty(siniestrosReporteReducer.reporte) &&
			siniestrosReporteReducer.reporte.status === "success"
		) {
			exportToExcel(
				siniestrosReporteReducer.reporte.result,
				"reporte-siniestro"
			);
			dispatch({ type: SINIESTROS_REPORTE_SUCCESS, payload: [] });
		}
	}, [siniestrosReporteReducer, dispatch]);

	const menuList = (
		<ul className={classes.list}>
			<Link className={classes.link} to="/">
				<li className={classes.navBarItem}>Inicio</li>
			</Link>
			<Link className={classes.link} to="/siniestros">
				<li className={classes.navBarItem}>Siniestros</li>
			</Link>
			<Link
				className={classes.link}
				to="/alta-siniestro"
				onClick={() =>
					dispatch({ type: CREATE_SINIESTRO_SUCCESS, payload: [] })
				}
			>
				<li className={classes.navBarItem}>Alta siniestros</li>
			</Link>
			<Link className={classes.link} to="/parametrizacion">
				<li className={classes.navBarItem}>Parametrizacion</li>
			</Link>
			{siniestrosReporteReducer.isLoading ? (
				<CircularProgress />
			) : (
				<li
					className={classes.navBarItem}
					onClick={handleDownloadReport}
				>
					Descargar Reporte
				</li>
			)}
			<Link className={classes.link} to="/ajustadores">
				<li className={classes.navBarItem}>Ajustadores</li>
			</Link>
			<Link className={classes.link} to="/dashboard">
				<li className={classes.navBarItem}>Dashboard</li>
			</Link>
		</ul>
	);

	return (
		<div className={classes.container}>
			<Grid container className={classes.navBarContainer}>
				<div className={classes.navBar}>
					<a href="http://sistema.siwcargo.com/adm/">
						<img src={logo} alt="SIW" className={classes.logo} />
					</a>
					<IconButton
						className={classes.mobileDisplayTrue}
						onClick={toggleDrawer}
					>
						<MenuIcon className={classes.menuIcon} />
					</IconButton>
					<div className={classes.mobileDisplayFalse}>{menuList}</div>
				</div>
				<SwipeableDrawer
					anchor={"left"}
					open={sideMenu}
					onClose={toggleDrawer}
					onOpen={toggleDrawer}
					className={classes.sideMenu}
				>
					<div className={classes.closeContainer}>
						<IconButton onClick={toggleDrawer}>
							<CloseIcon className={classes.menuIcon} />
						</IconButton>
					</div>
					<a
						href="https://sistemas.siwcargo.com"
						className={classes.imageSidebar}
					>
						<img src={logo} alt="SIW" />
					</a>
					{menuList}
				</SwipeableDrawer>
			</Grid>
			<div className={classes.breadcrumb}>
				<SimpleBreadcrumbs data={breadcrumbs || []} />
			</div>
		</div>
	);
}

NavBar.propTypes = {};

export default NavBar;
