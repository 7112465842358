import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	container: {
		"& .MuiButton-label": {
			fontSize: "13px",
			marginRight: "10px",
		},
		"& .MuiTypography-root": {
			fontSize: "14px",
		},
		display: "flex",
		justifyContent: "flex-end",
		[theme.breakpoints.down("sm")]: {
			padding: "40px 20px",
		},
	},
	ovalContainer: {
		height: "75vh",
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	buttonAdd: {
		color: "#3f51b5",
		fontWeight: "500",
	},
	customToolbar: {
		display: "flex",
		justifyContent: "space-between",
		backgroundColor: "#f8f8f8 !important",
	},
	addIcon: {
		marginRight: "5px",
	},
	title: {
		fontSize: "17px !important",
		fontWeight: 700,
		margin: "25px 0",
		width: "100%",
	},
	actionsLink: {
		width: "100%",
		height: "100%",
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
	},
}));

export default useStyles;
