import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const DragNDropZoneStyled = styled.div`
	width: 100%;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	margin: 18px 0;
	text-align: center;
`;

export const FormContainerStyled = styled.form`
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media only screen and (max-width: 480px) {
		width: 90%;
	}
`;

export const useStyles = makeStyles(() => ({
	selectContainer: {
		fontWeight: '700'
	},
	select: {
		marginTop: "5px"
	},
	title: {
		fontSize: "25px",
		textAlign: "center"
	},
	montoPagado: {
		paddingTop: "20px",
	},
	fechaPago: {
		paddingTop: "10px",
	},
	añadirComentarios: {
		paddingTop: "20px",
	},
	añadirArchivos: {
		paddingTop: "15px",
		paddingBottom: "10px"
	},
	errorContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		padding: "5% 5% 5% 0%",
		color: "red"
	}
}
));

