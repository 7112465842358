import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const FormContainerStyled = styled.form`
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media only screen and (max-width: 480px) {
		width: 90%;
	}
`;

export const useStyles = makeStyles((theme) => ({
    templateItemsContainer: {
        width: "100%",
    },
    template: {
        fontWeight: "700",
        fontSize: "16px"
    },
    titleContainer: {
        marginTop: "5%",
        width: "100%",
    },
    subjectContainer: {
        width: "100%",
    },
    bodyContainer: {
        width: "100%",
        marginBottom: "2%"
    },
    selectContainer: {
        width: "100%",
        fontWeight: "700",
        marginBottom: "2%"
    },
    select: {
        marginTop: "1%"
    },
}))