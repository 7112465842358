import { GET_JWT_START, GET_JWT_SUCCESS, GET_JWT_ERROR } from "./types";
import getLogoutClient from "./getLogoutClient";

const getJWTByKey = (body) => async (dispatch, getState) => {
  const client = getLogoutClient();
  dispatch({ type: GET_JWT_START });
  client
    .post("autenticacion/obtenerToken", body)
    .then((response) => {
      dispatch({ type: GET_JWT_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_JWT_ERROR, payload: error });
    });
};

const sessionActions = { getJWTByKey };

export default sessionActions;
