import {
	GET_ALL_SINIESTROS_START,
	GET_ALL_SINIESTROS_SUCCESS,
	GET_ALL_SINIESTROS_ERROR,
	GET_ALL_SINIESTROSFILTER_START,
	GET_ALL_SINIESTROSFILTER_SUCCESS,
	GET_ALL_SINIESTROSFILTER_ERROR,
	COMPLAINT_FORM_START,
	COMPLAINT_FORM_SUCCESS,
	COMPLAINT_FORM_ERROR
} from "../actions/types";

let initialState = {
	allSiniestros: [],
	siniestrosFilter: [],
	complaintForm: [],
	isLoading: false,
	error: null,
	reserva: null,
};

const siniestroReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_SINIESTROS_START:
			return {
				...state,
				isLoading: true,
			};
		case GET_ALL_SINIESTROS_SUCCESS:
			return {
				...state,
				allSiniestros: action.payload,
				siniestrosFilter: action.payload,
				isLoading: false,
			};
		case GET_ALL_SINIESTROS_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case GET_ALL_SINIESTROSFILTER_START:
			return {
				...state,
				isLoading: true,
			};
		case GET_ALL_SINIESTROSFILTER_SUCCESS:
			return {
				...state,
				siniestrosFilter: action.payload,
				isLoading: false,
			};
		case GET_ALL_SINIESTROSFILTER_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case COMPLAINT_FORM_START:
			return {
				...state,
				isLoading: true,
			};
		case COMPLAINT_FORM_SUCCESS:
			return {
				...state,
				complaintForm: action.payload,
				isLoading: false,
			};
		case COMPLAINT_FORM_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default siniestroReducer;
