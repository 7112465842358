import {
  GET_OPERATION_START,
  GET_OPERATION_SUCCESS,
  GET_OPERATION_ERROR,
} from "../actions/types";

let initialState = {
  allOperations: [],
  isLoading: false,
  error: null,
};

const operationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OPERATION_SUCCESS:
      return {
        ...state,
        allOperations: action.payload,
        isLoading: false,
      };
    case GET_OPERATION_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default operationReducer;
