import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    tableHeaderTemplates: {
        background: "#f8f8f8",
        width: "100%",
    },
    tableCellTemplates: {
        fontSize: "16px",
        fontWeight: "700",
        width: "25%",
        marginTop: "1%",
        textAlign: "center",
    },
    fontSize: {
        fontSize: "14px",
        "& .MuiTypography-h6": {
            fontSize: "14px"
        }
    },
    actionsSearchLink: {
        margin: "3% 5% 0% 0%",
    },
    iconsTemplate: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    skeletonLoader: {
        width: "700px",
        marginLeft: "30px",
    },
}));

export default useStyles;