import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function RichTextEditor({ value, onChange, id, customStyles }) {
	const handleChange = (prop) => (value) => {
		onChange && onChange(prop, value);
	};

	return (
		
			<ReactQuill
				theme="snow"
				value={value || ""}
				onChange={handleChange(id)}
				style={{ height: "200px" }}
				id={id}
				placeholder={"Mensaje"}
				className={customStyles}
			/>
	
	);
}
