import { isEqual } from "lodash";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "./actions";
import LoadingView from "./views/LoadingView";
import jwt_decode from "jwt-decode";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { GET_JWT_SUCCESS } from "./actions/types";
import {
    clearDispatchs,
    setItemSessionStorage,
    SIWCARGO_URL,
    SIWCARGO_URL_ORIGIN
} from "./common/constants";

const AppContext = createContext();

const ContextProvider = (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const sessionReducer = useSelector((state) => state.sessionReducer);
    const [key, setKey] = useState("");
    const [jwt, setJwt] = useState("");
    const [alertError, setAlertError] = useState({
        open: false,
        type: "error",
        message: "",
    });
    const handleAlertOpen = () =>
        setAlertError({ ...alertError, open: !alertError.open });

    useEffect(() => {
        let iframe = document.createElement("iframe");
        iframe.setAttribute(
            "src",
            `${SIWCARGO_URL}/app/acceso/ingreso/usuario`
        );
        iframe.style.display = "none";
        document.body.appendChild(iframe);
    }, []);

    useEffect(() => {
        window.addEventListener("message", function (event) {
        
            if (event.origin === SIWCARGO_URL) {
                if (typeof event.data == "string" && typeof event.data.length != "undefined" && event.data.length == 40) {
                    setKey(event.data);
                    if (isEqual(sessionStorage.getItem("keyUser"), event.data))
                        return;
                    sessionStorage.setItem("keyUser", event.data);

                } else {
                    sessionStorage.clear();
                    clearDispatchs(dispatch);
                    window.location.replace(
                        `${SIWCARGO_URL}/app/acceso/ingreso?vuelta=${window.location.href}`
                    );
                }

            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (key) {
            dispatch(
                allActions.sessionActions.getJWTByKey({
                    llave: key,
                    userAgent: navigator.userAgent,
                })
            );
        }
    }, [key, dispatch]);

    useEffect(() => {
        if (sessionReducer.error) {
            
            setAlertError({
                type: "error",
                message: sessionReducer.error.data.result,
                open: true,
            });

            dispatch({ type: GET_JWT_SUCCESS, payload: {} });

            // sessionStorage.clear();
            // window.location.replace(
            //     `${SIWCARGO_URL}/app/acceso/ingreso?vuelta=${window.location.pathname}`
            // );
        }
        if (sessionReducer.jwt && sessionReducer.jwt.status === "success") {
            let splittedToken =
                sessionReducer.jwt.result.token.split("JWT ")[1];
            setJwt(splittedToken);
            sessionStorage.setItem("JWT", splittedToken);
            const { idUsuario, nombre } = jwt_decode(splittedToken);
            sessionStorage.setItem("idUsuario", idUsuario);
            setItemSessionStorage("nombreUsuario", nombre);
        }
    }, [sessionReducer, dispatch]);

    const value = { key };

    return (
        <AppContext.Provider value={value}>
            {key && jwt ? children : <LoadingView />}
            <Snackbar
                open={alertError.open}
                autoHideDuration={6000}
                onClose={handleAlertOpen}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleAlertOpen} severity={alertError.type}>
                    {alertError.message}
                </Alert>
            </Snackbar>
        </AppContext.Provider>
    );
};

export { ContextProvider, AppContext };
