import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';

const DynamicContents = () => {
    const classes = useStyles();
    const templatesReducer = useSelector((state) => state.templatesReducer);
    const allVariables = templatesReducer?.allVariables?.result;
    const arrayVariables = Object.entries(allVariables || {})
    return (
        <Grid container className={classes.tagsContainer}>
            <span>CONTENIDOS DINÁMICOS</span>
            <div className={classes.tagsItemContainer}>
                {arrayVariables.map(item => (
                    <CopyToClipboard text={item[0]}>
                        <p><input
                            onClick={() => toast('Copiado')}
                            type="text"
                            key={item}
                            value={item[0]}
                        >
                        </input>: {item[1]}</p>
                    </CopyToClipboard>
                ))}
                <Toaster />
            </div>
        </Grid >
    )
}

export default DynamicContents;
