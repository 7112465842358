import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SkeletonLoader from "../../components/SkeletonLoader";
import { FormControlLabel, Switch, TableFooter } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";
import { ADD_ARCHIVO_TIPO_SUCCESS } from "../../actions/types";
import ButtonAddAndInput from "../../components/ButtonAddAndInput";
import PaginationTable from "../../components/PaginationTable";
import { createBreadcrumbData } from "../../common/constants";

function NewDocumentation(props) {
	const { setBreadcrumbs } = props;
	const classes = useStyles();
	const [newDoc, setNewDoc] = useState("");
	const dispatch = useDispatch();
	const archivoTipoReducer = useSelector((state) => state.archivoTipoReducer);
	const [isDense, setIsDense] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(7);

	const handleNewDoc = (e) => {
		e.preventDefault();
		dispatch(
			allActions.archivoTipoActions.newArchivoTipo({
				tituloArchivo: newDoc,
			})
		);
	};

	useEffect(() => {
		setBreadcrumbs([
			createBreadcrumbData(`/parametrizacion`, `Parametrización`),
			createBreadcrumbData(`/parametrizacion/documentacion`, `Documentación`),
			createBreadcrumbData(`/parametrizacion/nueva-documentacion`, `Nueva documentación`),
		]);
	}, [setBreadcrumbs]);

	useEffect(() => {
		if (Boolean(archivoTipoReducer.archivoCreated)) {
			dispatch(allActions.archivoTipoActions.getAllArchivoTipo());
			dispatch({ type: ADD_ARCHIVO_TIPO_SUCCESS, payload: false });
			setNewDoc("");
		}
	}, [dispatch, archivoTipoReducer]);

	return (
		<Grid className={classes.container}>
			<TableContainer
				component={Paper}
				className={classes.tableContainer}
			>
				<Table className={classes.table}>
					<TableHead className={classes.tableHeader}>
						<TableRow>
							<TableCell className={classes.tableHead}>
								Documentaciones creadas
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{archivoTipoReducer.isLoading &&
							!Boolean(archivoTipoReducer.archivoCreated) ? (
							<SkeletonLoader numberOfSkeletons={2} />
						) : (
							<>
								{(rowsPerPage > 0
									? archivoTipoReducer?.archivos.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									: archivoTipoReducer?.archivos
								).map((row) => (
									<TableRow key={row.archivoTipo_id}>
										<TableCell
											component="th"
											scope="row"
											className={clsx(
												classes.tableCellRowItem,
												isDense &&
												classes.tableCellRowDense
											)}
											align="justify"
										>
											{row.titulo}
										</TableCell>
									</TableRow>
								))}
							</>
						)}
					</TableBody>
					<TableRow>
						<TableCell
							component="th"
							scope="row"
							className={classes.tableCellRowItem}
						>
							<ButtonAddAndInput
								value={newDoc}
								setValue={setNewDoc}
								isLoading={archivoTipoReducer.isLoading}
								onSubmit={handleNewDoc}
							/>
						</TableCell>
					</TableRow>
					<TableFooter className={classes.tableHeader}>
						<PaginationTable
							setRowsPerPage={setRowsPerPage}
							rows={archivoTipoReducer?.archivos}
							rowsPerPage={rowsPerPage}
							page={page}
							setPage={setPage}
							setIsDense={setIsDense}
						/>
					</TableFooter>
				</Table>
			</TableContainer>

			<div className={classes.switchContainer}>
				<FormControlLabel
					control={
						<Switch
							checked={isDense}
							onChange={() => setIsDense(!isDense)}
						/>
					}
					label="Comprimir"
				/>
			</div>
		</Grid>
	);
}

export default NewDocumentation;
