import React from 'react'
import { Chart } from 'react-google-charts'

export default function Graph(props) {
    const { data, options } = props
    return (
        <Chart
            chartType='PieChart'
            data={data}
            options={options}
            width={"600px"}
            height={"400px"}
        />
    )
}