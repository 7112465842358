import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import allActions from "../../../../actions";
import FormLabel from "../../../../components/FormLabel"
import SideBar from '../../../../components/SideBar';
import { FormControl } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import { useStyles } from "./styles";
import { isEqual, isEmpty } from "lodash";
import { onComunicationClick, onNewCommunicationClick } from "../FirstForm/functions"
import ComposedFormGroup from '../../../../components/ComposedFormGroup';
import Dropzone from '../../../../components/Dropzone';
import FilesList from '../Files';
import { CircularProgress, Button } from '@mui/material';
import { LOAD_FILE_SUCCESS } from "../../../../actions/types";
import { onUploadFilesAjustador } from '../FourthForm/functions';
import DialogThread from '../../../../components/DialogThreads';

export default function AjustadorForm() {
    const classes = useStyles();
    const [estudio, setEstudio] = useState({ id: null })
    const [ajustador, setAjustador] = useState({})
    const [files, setFiles] = useState([]);
    const [filesWithError, setFilesWithError] = useState([]);
    const [filesMaxSize, setFilesMaxSize] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [ajustadorAct, setAjustadorAct] = useState({
        idAjustador: null,
    })
    const [openThreads, setOpenThreads] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();
    //estados de redux
    const estudiosReducer = useSelector((state) => state.estudiosReducer)
    const ajustadoresReducer = useSelector((state) => state.ajustadoresReducer)
    const archivoReducer = useSelector((state) => state.archivoReducer);
    const siniestroReducer = useSelector((state) => state.createSiniestroReducer);
    const comunicationsReducer = useSelector((state) => state.comunicationsReducer);
    const siniestroId = new URLSearchParams(window.location.search).get("id");
    useEffect(() => {
        //trae todos los estudios
        dispatch(allActions.estudiosActions.getAllEstudios())
    }, [])
    useEffect(() => {
        //si hay idEstudio trae los ajustadores del estudio correspondiente
        if (estudio.id !== null) {
            dispatch(allActions.ajustadoresActions.getAjustadoresIdEstudio(estudio))
        }
    }, [estudio])
    useEffect(() => {
        if (siniestroReducer?.individualSiniestro?.result?.siniestro?.idAjustador) {
            setAjustadorAct({
                ...ajustadorAct,
                idAjustador: siniestroReducer.individualSiniestro.result.siniestro.idAjustador
            })
            dispatch(allActions.ajustadoresActions.getAjustador(siniestroReducer?.individualSiniestro?.result?.siniestro?.idAjustador))
        }
    }, [siniestroReducer])


    useEffect(() => {
        setAjustador({
            ...ajustador,
            ajustadorAct: ajustadoresReducer.ajustadorAct[0]
        })
    }, [ajustadoresReducer])
    useEffect(() => {
        if (archivoReducer.error && isEmpty(filesWithError.find((e) => e === archivoReducer?.error?.response?.data?.result))) {
            setFilesWithError((prevState) => [
                ...prevState,
                archivoReducer.error?.response?.data?.result,
            ]);
        };

        if (!isEmpty(archivoReducer.uploadedFile) && isEmpty(uploadedFiles.find((e) => e.nombre === archivoReducer.uploadedFile?.nombre))) {
            setUploadedFiles((prevState) => [
                ...prevState,
                archivoReducer.uploadedFile,
            ]);
        }
    }, [archivoReducer, dispatch, uploadedFiles, filesWithError]);

    useEffect(() => {
        if (isEqual(uploadedFiles.length + filesWithError.length, files.length) && files.length > 0) {
            if (!isEmpty(filesWithError)) {
                setOpenDialog(true);
                setFiles([]);
            } else {
                setUploadedFiles([]);
                setFiles([]);
            };

            dispatch({ type: LOAD_FILE_SUCCESS, payload: [] });
            dispatch(allActions.archivoActions.getAllFilesBySiniestro(siniestroId));
        }
        if (!isEmpty(filesMaxSize)) {
            setOpenDialog(true);
        }
    }, [filesMaxSize, uploadedFiles, filesWithError, files, siniestroId, dispatch]);

    useEffect(() => {
        if (!isEmpty(filesMaxSize)) {
            setOpenDialog(true);
        }
    }, [filesMaxSize]);
    const onChangeE = (e) => {
        //onChange estudio
        setEstudio({
            id: e.target.value
        })
    }
    const onChangeA = (e) => {
        //on change ajustadores
        const info = JSON.parse(e.target.value)
        setAjustador(info)
    }
    const handleUploadFiles = (e) => {
        onUploadFilesAjustador(files, setFilesMaxSize, siniestroId, dispatch);
        setTimeout(() => {
            setFiles([])
        }, 3000);
    };
    const HandleThreads = () => {
        setOpenThreads(false)
    };
    const handleSetAjustador = () => {
        const data = {
            ajustadorId: ajustador.ajustador_id,
            siniestroId: siniestroId
        }
        if (ajustador.ajustador_id) {
            dispatch(allActions.ajustadoresActions.setAjustador(data))
        }
        setAjustador({
            ...ajustador,
            ajustadorAct: ajustador
        })
    }
    const selectAjustador = () => {
        return (
            <>
                <FormLabel customStyles={classes.subtitle} text="Seleccione Estudio de ajustadores" />
                <FormControl
                    id='estudio'
                    as="select"
                    onChange={onChangeE}
                    disabled={ajustador.ajustadorAct}
                >
                    <option value={null}>Seleccionar</option>
                    {estudiosReducer.allEstudios?.map((option) => (
                        <option value={option.id_estudio} key={option.id_estudio}>
                            {option.titulo}
                        </option>
                    ))}
                </FormControl>
            </>
        )
    }
    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                {ajustador.ajustadorAct === undefined ? selectAjustador() : <div></div>}
                <FormLabel customStyles={classes.subtitle} text={ajustador.ajustadorAct ? "Ajustador" : "Seleccione ajustador"} />
                <FormControl
                    className={classes.input}
                    id='ajustadores'
                    as="select"
                    onChange={onChangeA}
                    disabled={ajustador.ajustadorAct}
                >
                    {ajustador.ajustadorAct ? <option disabled value={null}>{ajustador.ajustadorAct.nombre + " " + ajustador.ajustadorAct.apellido}</option> : <option value={null}>Seleccionar</option>}

                    {ajustadoresReducer.ajustadoresIdEstudio?.map((option) => (
                        <option value={JSON.stringify(option)} key={option.id_estudio}>
                            {option.nombre + " " + option.apellido}
                        </option>
                    ))}
                </FormControl>
                <ComposedFormGroup
                    placeholder="Email Ajustador"
                    isDisabled
                    value={ajustador.ajustadorAct ? ajustador.ajustadorAct.email : ajustador.email ? ajustador.email : ""}
                />
                <ComposedFormGroup
                    placeholder="Teléfono Ajustador"
                    isDisabled
                    value={ajustador.ajustadorAct ? ajustador.ajustadorAct.telefono : ajustador.telefono ? ajustador.telefono : ""}
                />

                <Dropzone md={6}
                    files={files}
                    setFiles={setFiles}
                />

                <Grid className={classes.btnSubirArch}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => handleUploadFiles(e)}
                    >
                        {archivoReducer.isLoading ? (
                            <CircularProgress size={20} color={"inherit"} />
                        ) : (
                            "Subir archivos"
                        )}
                    </Button>
                </Grid>
                {archivoReducer.error &&
                    <Grid className={classes.errorContainer}>
                        {archivoReducer.error ? archivoReducer.error : ''}
                    </Grid>}
            </Grid >
            <Grid className={classes.chat} item xs={12} md={6}>
                <SideBar
                    title='Comunicación con Ajustador'
                    data={comunicationsReducer.comunicationsBySiniestro?.result?.filter((comunicacion) => comunicacion.entidad_comunicacion === "ajustador")}
                    isLoading={comunicationsReducer.isLoading}
                    unreadMessages={comunicationsReducer.messagesUnread}
                    handleFabButtonClick={onNewCommunicationClick(history, siniestroId, "ajustador")}
                    handleListItemClick={onComunicationClick(history, siniestroId)}
                    setOpenThreads={setOpenThreads}
                    openThreads={openThreads}
                    tipo="Label_4180776293837815080" //ID LABEL AJUSTADOR
                />
            </Grid>
            <FilesList md={12}
                files={archivoReducer.allFiles?.filter((fi) => isEqual(fi.entidad, "ajustador"))}
            />
            <div className={classes.btnGuardar}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSetAjustador}
                >
                    {ajustadoresReducer.isLoading ? (
                        <CircularProgress size={20} color={"inherit"} />
                    ) : (
                        "Guardar"
                    )}
                </Button>

            </div>
            <DialogThread
                isOpen={openThreads}
                onClose={HandleThreads}
            />
        </Grid>
    )
};