import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 10px)",
      right: "10px",
      position: "absolute",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "13px !important",
    },
    "& .MuiOutlinedInput-root": {
      background: "white",
    },
  },
  inputStyles: {
    fontSize: "14px !important",
    color: "#555",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "35px !important",
    },
    "& ::placeholder": {
      color: "#000",
    },
  },
  option: {
    fontSize: "14px",
  },
}));

export default useStyles;
