import React from "react";
import PropTypes from "prop-types";
import ButtonBootstrap from "react-bootstrap/Button";
import { CircularProgress } from "@material-ui/core";

function Button(props) {
  const { variant, type, text, onClick, isLoading, isDisabled } = props;
  return (
    <div data-testid="button">
      <ButtonBootstrap
        variant={variant}
        type={type}
        onClick={onClick}
        disabled={isLoading || isDisabled}
      >
        {!isLoading ? (
          text
        ) : (
          <CircularProgress size={20} style={{ color: "white" }} />
        )}
      </ButtonBootstrap>
    </div>
  );
}

Button.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default Button;
