import {
	GET_ALL_SINIESTROS_START,
	GET_ALL_SINIESTROS_SUCCESS,
	GET_ALL_SINIESTROS_ERROR,
	CREATE_SINIESTRO_START,
	SEND_LIQUIDATE_SUCCESS,
	CHANGE_ESTADO_SUCCESS,
	CREATE_SINIESTRO_ERROR,
	UPDATE_SINIESTRO_SUCCESS,
	GET_INDIVIDUAL_SINIESTRO_SUCCESS,
	CREATE_SINIESTRO_NUMBER_START,
	CREATE_SINIESTRO_NUMBER_SUCCESS,
	CREATE_SINIESTRO_NUMBER_ERROR,
	LOAD_SINIESTRO_ESTADO_START,
	LOAD_SINIESTRO_ESTADO_SUCCESS,
	LOAD_SINIESTRO_ESTADO_ERROR,
	UPDATE_SINIESTRO_ESTADO_SUCCESS,
	SINIESTRO_RESERVA_START,
	SINIESTRO_RESERVA_SUCCESS,
	SINIESTRO_RESERVA_ERROR,
	UPDATE_DENUNCIA_INFO_START,
	UPDATE_DENUNCIA_INFO_SUCCESS,
	UPDATE_DENUNCIA_INFO_ERROR,
	BAJA_SINIESTRO_SUCCESS,
	LIQUIDACION_SINIESTRO_SUCCESS,
	UPDATE_SINIESTRO_DETAILS_SUCCESS,
	SINIESTROS_REPORTE_START,
	SINIESTROS_REPORTE_SUCCESS,
	SINIESTROS_REPORTE_ERROR,
	GET_TOTALRESERVA_START,
	GET_TOTALRESERVA_SUCCES,
	GET_TOTALRESERVA_ERROR,
	GET_ALL_SINIESTROSFILTER_START,
	GET_ALL_SINIESTROSFILTER_SUCCESS,
	GET_ALL_SINIESTROSFILTER_ERROR,
	COMPLAINT_FORM_START,
	COMPLAINT_FORM_ERROR
} from "./types";
import getLoggedClient from "./getLoggedClient";
import allActions from ".";

const getAllSiniestros = () => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: GET_ALL_SINIESTROS_START });

	client
		.get("/siniestros")
		.then((response) => {
			dispatch({
				type: GET_ALL_SINIESTROS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: GET_ALL_SINIESTROS_ERROR, payload: err });
		});
};

const getAllSiniestrosFilter = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: GET_ALL_SINIESTROSFILTER_START });

	client
		.post(`/siniestrosFilter`, data)
		.then((response) => {
			dispatch({
				type: GET_ALL_SINIESTROSFILTER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: GET_ALL_SINIESTROSFILTER_ERROR, payload: err });
		});
};

const getReserva = () => async (dispatch, getState) => {
	const client = getLoggedClient()
	dispatch({ type: GET_TOTALRESERVA_START });

	client
		.get("/getTotalReserva")
		.then((response) => {
			dispatch({
				type: GET_TOTALRESERVA_SUCCES,
				payload: response.data.result[0]
			})
		})
		.catch((err) => {
			dispatch({ type: GET_TOTALRESERVA_ERROR, payload: err });
		});
}

const updateSiniestro = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });

	client
		.put("/siniestro", data)
		.then((response) => {
			dispatch({
				type: UPDATE_SINIESTRO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: err });
		});
};

const sendDataLiquidate = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });

	client
		.put(`/siniestro/${idSiniestro}/pago`, data)
		.then((response) => {
			dispatch({
				type: SEND_LIQUIDATE_SUCCESS,
				payload: response.data,
			});
			window.location.reload(true)
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: err });
		});
};

const changeEstado = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });

	client
		.put(`/siniestro/${idSiniestro}/cambiarEstado`, data)
		.then((response) => {
			dispatch({
				type: CHANGE_ESTADO_SUCCESS,
				payload: response.data,
			});
			window.location.reload(true)
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: err });
		});
};

const getInidividualSiniestro = (siniestroId) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });

	client
		.get(`/siniestro/${siniestroId}`)
		.then((response) => {
			dispatch({
				type: GET_INDIVIDUAL_SINIESTRO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: err });
		});
};

const createSiniestroNumber = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_NUMBER_START });

	client
		.post("numeroSiniestro", data)

		.then((response) => {
			dispatch({
				type: CREATE_SINIESTRO_NUMBER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_NUMBER_ERROR, payload: err });
		});
};

const getSiniestroEstados = () => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: LOAD_SINIESTRO_ESTADO_START });

	client
		.get("siniestroEstados")
		.then((response) => {
			dispatch({
				type: LOAD_SINIESTRO_ESTADO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: LOAD_SINIESTRO_ESTADO_ERROR, payload: err });
		});
};

const updateSiniestroEstado = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: LOAD_SINIESTRO_ESTADO_START });

	client
		.put("siniestroEstados", data)
		.then((response) => {
			dispatch({
				type: UPDATE_SINIESTRO_ESTADO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: LOAD_SINIESTRO_ESTADO_ERROR, payload: err });
		});
};

const updateReserva = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: SINIESTRO_RESERVA_START });

	client
		.put(`/siniestro/${idSiniestro}/reserva`, data)
		.then((response) => {
			dispatch({
				type: SINIESTRO_RESERVA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: SINIESTRO_RESERVA_ERROR, payload: err });
		});
};

const updateDenuncia = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: UPDATE_DENUNCIA_INFO_START });

	client
		.put(`/siniestro/${idSiniestro}/denuncia`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_DENUNCIA_INFO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: UPDATE_DENUNCIA_INFO_ERROR, payload: err });
		});
};

const bajaSiniestro = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });

	client
		.put(`/siniestro/${idSiniestro}/baja`, data)
		.then((response) => {
			dispatch({
				type: BAJA_SINIESTRO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: err });
		});
};

export const liquidacionSiniestro = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });

	client
		.put(`/siniestro/${idSiniestro}/liquidar`, data)
		.then((response) => {
			dispatch({
				type: LIQUIDACION_SINIESTRO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: err });
		});
};

const updateSiniestroDetails = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });

	client
		.put(`/siniestro/${idSiniestro}/detalles`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_SINIESTRO_DETAILS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: err });
		});
};

const getSiniestroReporte = () => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: SINIESTROS_REPORTE_START });

	client
		.get(`/siniestros/reporte`)
		.then((response) => {
			dispatch({
				type: SINIESTROS_REPORTE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((err) => {
			dispatch({ type: SINIESTROS_REPORTE_ERROR, payload: err });
		});
};

const complaintForm = (idSiniestro, data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: COMPLAINT_FORM_START });
	document.getElementById("denunciar").innerText = "Denunciando...";
	client
		.post(`/formulariodeDeDenuncia`, data.formData)
		.then((response) => {
			data.formDataReport.files.push(response.data.result.idArchivo);
			dispatch(
				allActions.emailsActions.sendEmailReportCompany(idSiniestro, {
					...data.formDataReport,
				}))
		})
		.catch((err) => {
			dispatch({ type: COMPLAINT_FORM_ERROR, payload: err });
		});
};

const siniestroActions = {
	getAllSiniestros,
	updateSiniestro,
	sendDataLiquidate,
	changeEstado,
	getInidividualSiniestro,
	createSiniestroNumber,
	getSiniestroEstados,
	updateSiniestroEstado,
	updateReserva,
	updateDenuncia,
	bajaSiniestro,
	liquidacionSiniestro,
	getSiniestroReporte,
	updateSiniestroDetails,
	getReserva,
	getAllSiniestrosFilter,
	complaintForm
};

export default siniestroActions;
