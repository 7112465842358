import React, { forwardRef, useState, useEffect } from "react";
import { FormContainerStyled, useStyles } from "./styles";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import ComposedFormGroup from "../ComposedFormGroup";
import clsx from "clsx";
import { FormHelperText } from "@material-ui/core";
import FormLabel from "../FormLabel";
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import moment from "moment";
import RadioForm from "../../components/RadioGroup";
import allActions from "../../actions";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalForm(props) {
	const { open, modalFormData, formDataReport } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const idSiniestro = new URLSearchParams(window.location.search).get("id");
	const [formData, setFormData] = useState({
		idSiniestro: idSiniestro,
		numeroPoliza: '',
		nombreAsegurado: '',
		ciudad: '',
		estado: '',
		origenEmbarque: '',
		destinoEmbarque: '',
		lugarSiniestro: '',
		tipoSiniestro: '',
		especificidad: '',
		fechaSiniestro: '',
		transportista: '',
		remolque1: '',
		remolque2: '',
		numeroEconomico1: '',
		numeroEconomico2: '',
		razonSocialTransporte: '',
		productoPerdido: '',
		daño: '',
		moneda: 'USD',
		importeReclamado: '',
		usuarioReporta: '',
		fechaReclamo: '',
	});
	const RADIO_OPTIONS_SINIESTRO = [
		{ label: "volcadura", value: "volcadura" },
		{ label: "colision", value: "colision" },
		{ label: "robo", value: "robo" },
		{ label: "otro", value: "otro" },
	];
	const RADIO_OPTIONS_TRANSP = [
		{ label: "propio", value: "propio" },
		{ label: "tercero", value: "tercero" },
	];

	useEffect(() => {
		if (modalFormData) {
			setFormData({
				...formData,
				daño: modalFormData.descripcionHecho,
				importeReclamado: modalFormData.reserva,
				lugarSiniestro: modalFormData.lugarSiniestro,
				especificidad: modalFormData.tipoSiniestro,
				fechaSiniestro: modalFormData.siniestroFecha,
				razonSocialTransporte: modalFormData.transportista,
				productoPerdido: modalFormData.mercaderiaTransportada,
				fechaReclamo: modalFormData.siniestroDenuncia,
			});
		}
	}, [modalFormData]);

	const [helperText, setHelperText] = useState("");

	const HandleOnChange = (id, value) => {
		setFormData({ ...formData, [id]: value })
	}
	const HandleOnChangeInputs = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value })
	}
	const HandleOnChangeCheckbox = (prop, val) => {
		setFormData({ ...formData, [prop]: val });
	}

	const onSubmit = (e) => {
		e.preventDefault();

		if (formData.numeroPoliza && formData.ciudad && formData.lugarSiniestro && formData.productoPerdido && formData.daño &&
			formData.destinoEmbarque && formData.especificidad && formData.moneda && formData.estado && formData.nombreAsegurado &&
			formData.fechaSiniestro && formData.razonSocialTransporte && formData.origenEmbarque && formData.tipoSiniestro &&
			formData.transportista && formData.importeReclamado && formData.fechaReclamo && formData.usuarioReporta) {
			setHelperText("");
			dispatch(
				allActions.siniestroActions.complaintForm(idSiniestro, {
					formData,
					formDataReport
				})
			)
		} else {
			setHelperText("Complete los campos obligatorios para proseguir");
		}
	};

	return (
		<FormContainerStyled onSubmit={onSubmit}>
			<Dialog
				style={{ fontWeight: "700" }}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth={true}
				maxWidth={"lg"}
				scroll={"paper"}
			>
				<DialogTitle className={classes.titleModal}>
					{"Formulario de envío a la compañía"}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item xs={12} md={4}>
							<ComposedFormGroup
								placeholder={"Poliza Número"}
								typeInput="number"
								id={"numeroPoliza"}
								value={formData.numeroPoliza}
								onChange={HandleOnChange}
							/>
							<ComposedFormGroup
								placeholder={"Nombre del asegurado"}
								id={"nombreAsegurado"}
								value={formData.nombreAsegurado}
								onChange={HandleOnChange}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ComposedFormGroup
								placeholder={"Ciudad"}
								id={"ciudad"}
								value={formData.ciudad}
								onChange={HandleOnChange}
							/>
							<ComposedFormGroup
								placeholder={"Estado"}
								id={"estado"}
								value={formData.estado}
								onChange={HandleOnChange}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ComposedFormGroup
								placeholder={"Origen del embarque"}
								id={"origenEmbarque"}
								value={formData.origenEmbarque}
								onChange={HandleOnChange}
							/>
							<ComposedFormGroup
								placeholder={"Destino del embarque"}
								id={"destinoEmbarque"}
								value={formData.destinoEmbarque}
								onChange={HandleOnChange}
							/>
						</Grid>
						<Grid item md={8} className={classes.inputsInfoContainer}>
							<span>Lugar del siniestro: Durante el traslado desde:</span>
							<input
								placeholder={formData.lugarSiniestro}
								type="text"
								size="30"
								name="lugarSiniestro"
								value={formData.lugarSiniestro}
								onChange={HandleOnChangeInputs}
							/>
						</Grid>
						<Divider className={classes.divider} />
						<Grid xs={12} item>
							<span>Tipo de Siniestro</span>
							<Grid container spacing={4}>
								<Grid item xs={6} className={classes.checkboxData}>
									<RadioForm
										customStyles={classes.radioGroup}
										value={formData.tipoSiniestro}
										data={RADIO_OPTIONS_SINIESTRO}
										label=""
										id={"tipoSiniestro"}
										onChange={HandleOnChangeCheckbox}
									/>
								</Grid>
								<Grid item xs={6} className={classes.inputsInfoContainer}>
									<span>Específique siniestro:</span>
									<input
										type="text"
										placeholder={formData.especificidad}
										name="especificidad"
										size="30"
										value={formData.especificidad}
										onChange={HandleOnChangeInputs}
									/>
									<FormLabel text={"Fecha de ocurrencia:"} />
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DateTimePicker
											ampm={false}
											inputFormat='dd/MM/yyyy HH:mm'
											value={moment(formData.fechaSiniestro).format()}
											onChange={(e) => {
												setFormData({
													...formData,
													fechaSiniestro: moment(e).format("YYYY-MM-DD HH:mm:ss")
												});
											}}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</Grid>
							</Grid>
						</Grid>
						<Divider className={classes.divider} />
						<Grid xs={12} item>
							<span>Transportista</span>
							<Grid container spacing={2}>
								<Grid item xs={4} className={classes.checkboxData}>
									<RadioForm
										value={formData.transportista}
										data={RADIO_OPTIONS_TRANSP}
										label=""
										id={"transportista"}
										onChange={HandleOnChangeCheckbox}
										customStyles={classes.radioGroup}
									/>
								</Grid>
								<Grid
									item
									xs={8}
									className={clsx(classes.inputSpecificationContainer, classes.divTransportista)}
								>
									<Grid item xs={12} className={classes.inputSpecificationContainer}>
										<ComposedFormGroup
											placeholder={"Número económico"}
											typeInput="number"
											id={"numeroEconomico1"}
											value={formData.numeroEconomico1}
											onChange={HandleOnChange}
										/>
										<ComposedFormGroup
											placeholder={"Número económico"}
											typeInput="number"
											id={"numeroEconomico2"}
											value={formData.numeroEconomico2}
											onChange={HandleOnChange}
										/>
									</Grid>
									<Grid className={classes.inputsInfoContainer}>
										<span>Razón social:</span>
										<input
											type="text"
											placeholder={formData.razonSocialTransporte}
											name="razonSocialTransporte"
											value={formData.razonSocialTransporte}
											onChange={HandleOnChangeInputs}
										/>
									</Grid>
									<Grid className={classes.inputsSpecification}>
										<input
											type="text"
											placeholder={"Remolque 1:"}
											name="remolque1"
											value={formData.remolque1}
											onChange={HandleOnChangeInputs}
										/>
										<input
											type="text"
											placeholder={"Remolque 2:"}
											name="remolque2"
											value={formData.remolque2}
											onChange={HandleOnChangeInputs}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Divider className={classes.divider} />
						<Grid xs={12} item>
							<ComposedFormGroup
								placeholder={'Indicar el tipo de producto que se perdió en el siniestro:'}
								id={"productoPerdido"}
								value={formData.productoPerdido}
								onChange={HandleOnChange}
							/>
							<ComposedFormGroup
								placeholder={"Daño en:"}
								inputAs={"textarea"}
								id={"daño"}
								value={formData.daño}
								onChange={HandleOnChange}
							/>
							<Divider className={classes.divider} />
							<Grid xs={12} item className={classes.importeReclamadoContainer}>
								<span>Importe reclamado:</span>
								<input
									placeholder={'moneda'}
									size="5"
									name="moneda"
									value={formData.moneda}
									onChange={HandleOnChangeInputs}
								/>
								<input
									placeholder={`$${formData.importeReclamado == undefined || null ? 0 : formData.importeReclamado}`}
									type="number"
									size="15"
									name="importeReclamado"
									value={formData.importeReclamado}
									onChange={HandleOnChangeInputs}
								/>
							</Grid>
						</Grid>
						<Divider className={classes.divider} />
						<Grid xs={12} item className={classes.inputsInfoContainer}>
							<span>Persona que realiza el reporte:</span>
							<input
								placeholder=""
								type="text"
								size="40"
								name="usuarioReporta"
								value={formData.usuarioReporta}
								onChange={HandleOnChangeInputs}
							/>
							<FormLabel text={"Fecha:"} />
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									ampm={false}
									inputFormat='dd/MM/yyyy HH:mm'
									value={moment(formData.fechaReclamo).format()}
									onChange={(e) => {
										setFormData({
											...formData,
											fechaReclamo: moment(e).format("YYYY-MM-DD HH:mm:ss")
										});
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className={classes.dialogActionsContainer}>
					<Grid>
						{helperText && (
							<FormHelperText
								style={{ fontSize: "13px", marginTop: "10px" }}
								error
							>
								{helperText}
							</FormHelperText>
						)}
					</Grid>
					<Grid>
						<Button
							onClick={onSubmit}
							color="primary"
							id={"denunciar"}
						>
							Denunciar
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>
		</FormContainerStyled>
	);
}
