import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormContainerStyled, useStyles } from "./styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ComposedFormGroup from "../../components/ComposedFormGroup";
import { Grid } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import allActions from "../../actions";
import RichTextEditor from "../../components/RichTextEditor"

const ClaimDocumentation = (props) => {
    const { open, setOpen } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const idSiniestro = new URLSearchParams(window.location.search).get("id");
    const [helperText, setHelperText] = useState("");
    const templatesReducer = useSelector((state) => state.templatesReducer);
    const templateSiniestro = templatesReducer.templateSiniestro.result;
    const [formData, setFormData] = useState({
        siniestroId: idSiniestro,
        recipient: '',
        cc: '',
        subject: '',
        message: '',
        files: []
    });

    useEffect(() => {
        if (formData) {
            setFormData({
                ...formData,
                subject: templateSiniestro?.asunto,
                message: templateSiniestro?.cuerpo,
            })
        }
    }, [templateSiniestro]);

    const handleClose = () => {
        setOpen(!open);
    };
    const HandleOnChange = (id, value) => {
        setFormData({ ...formData, [id]: value })
    }
    const onSubmit = (e) => {
        e.preventDefault();

        if (formData.recipient && formData.cc && formData.subject && formData.message) {
            setHelperText("");
            dispatch(
                allActions.emailsActions.sendEmailClaimDoc(idSiniestro, {
                    ...formData,
                }))
        } else {
            setHelperText("Complete todos los campos para proseguir");
        }
    };

    return (
        <FormContainerStyled onSubmit={onSubmit}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth={true}
            >
                <DialogContent>
                    <Grid>
                        <ComposedFormGroup
                            placeholder={"Destinatario"}
                            id={"recipient"}
                            value={formData.recipient}
                            onChange={HandleOnChange}
                        />
                        <ComposedFormGroup
                            placeholder={"Con Copia (CC)"}
                            id={"cc"}
                            value={formData.cc}
                            onChange={HandleOnChange}
                        />
                        <ComposedFormGroup
                            placeholder={"Asunto"}
                            id={"subject"}
                            value={formData.subject || templateSiniestro?.asunto}
                            onChange={HandleOnChange}
                        />
                        <RichTextEditor
                            onChange={HandleOnChange}
                            id="message"
                            value={formData.message || templateSiniestro?.cuerpo}
                            customStyles={classes.messageContainer}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActionsContainer}>
                    <Grid>
                        {helperText && (
                            <FormHelperText
                                style={{ fontSize: "13px" }}
                                error
                            >
                                {helperText}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid>
                        <Button
                            onClick={handleClose}
                            color="primary">
                            Cancelar
                        </Button>
                        <Button
                            onClick={onSubmit}
                            color="primary"
                            id={"reclamar"}
                        >
                            Reclamar
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </FormContainerStyled>
    )
}

export default ClaimDocumentation;