import {
  CREATE_SINIESTRO_NUMBER_START,
  CREATE_SINIESTRO_NUMBER_SUCCESS,
  CREATE_SINIESTRO_NUMBER_ERROR,
} from "../actions/types";

let initialState = {
  createSiniestroNumber: [],
  isLoading: false,
  error: null,
};

const siniestroNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SINIESTRO_NUMBER_START:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_SINIESTRO_NUMBER_SUCCESS:
      return {
        ...state,
        createSiniestroNumber: action.payload,
        isLoading: false,
      };
    case CREATE_SINIESTRO_NUMBER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default siniestroNumberReducer;
