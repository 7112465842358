import React, { useState } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useStyles from "./styles";
import clsx from "clsx";

function ButtonAddAndInput(props) {
	const classes = useStyles();
	const {
		onSubmit,
		value,
		isLoading,
		setValue,
		customStylesInput,
		customInput = false,
	} = props;
	const [isInputEnable, setIsInputEnable] = useState(false);

	const handleCancel = () => {
		setValue && setValue("");
		setIsInputEnable(false);
	};

	return (
		<>
			{isInputEnable ? (
				<div
					className={clsx(classes.inputContainer, customStylesInput)}
				>
					<form style={{ width: "70%" }} onSubmit={onSubmit}>
						{customInput ? (
							customInput
						) : (
							<TextField
								type="text"
								margin="dense"
								variant={"outlined"}
								label={"Nombre"}
								className={classes.inputEditable}
								autoFocus
								required
								value={value}
								onChange={(e) => setValue(e.target.value)}
								disabled={isLoading}
							/>
						)}
					</form>
					{isLoading ? (
						<CircularProgress size={20} />
					) : (
						<>
							<CancelIcon
								onClick={handleCancel}
								className={classes.cancelIcon}
							/>
							<CheckCircleIcon
								className={clsx(
									classes.cancelIcon,
									value
										? classes.checkIcon
										: classes.checkIconDisabled
								)}
								onClick={onSubmit}
							/>
						</>
					)}
				</div>
			) : (
				<Button
					variant="outlined"
					className={classes.buttonAdd}
					onClick={() => setIsInputEnable(!isInputEnable)}
				>
					<AddCircleOutlineIcon className={classes.addIcon} />
					Agregar
				</Button>
			)}
		</>
	);
}

export default ButtonAddAndInput;
