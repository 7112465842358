import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() => ({
	radioGroup: {
		flexDirection: "row",
		"& > label": {
			padding: "0 20px",
		},
	},
	sideBar: {
		marginTop: "4%"
	},
	errorContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		padding: "5% 5% 10% 0%",
		color: "red"
	}
}));
