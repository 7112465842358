import React, { useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useStyles from "./styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

function Row(props) {
	const { row, onAddDocumentation } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [docToDelete, setDocToDelete] = useState({});
	const siniestroTipoArchivoTipoReducer = useSelector(
		(state) => state.siniestroTipoArchivoTipoReducer
	);

	const handleAddDocumentation = () => {
		onAddDocumentation(row);
	};

	const handleDeleteDocumentation = (rowData) => () => {
		onCloseDialog();
		setDocToDelete(rowData);
	};

	const onCloseDialog = () => setOpenDialog(!openDialog);

	const onSubmit = () => {
		dispatch(
			allActions.siniestroTipoArchivoTipoActions.deleteSiniestroTipoArchivoTipo(
				docToDelete.siniestroTipo_id,
				docToDelete.archivoTipo_id
			)
		);
		dispatch(
			allActions.siniestroTipoArchivoTipoActions.getSiniestroTipoArchivoTipo()
		);
	};

	return (
		<>
			<TableRow className={classes.rootRow}>
				<TableCell className={classes.tableCellRow}>
					<IconButton size="small" onClick={() => setOpen(!open)}>
						{open ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</IconButton>
				</TableCell>
				<TableCell
					className={classes.tableHeaderRow}
					component="th"
					scope="row"
				>
					{row.siniestroTitulo}{" "}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography
								variant="h6"
								gutterBottom
								component="div"
								className={classes.expandTitleRow}
							>
								Documentación requerida
								<Button
									variant="outlined"
									className={classes.buttonAdd}
									onClick={handleAddDocumentation}
								>
									<AddCircleOutlineIcon
										className={classes.addIcon}
									/>
									Agregar
								</Button>
							</Typography>
							<Table
								size="small"
								className={classes.expandTableRow}
							>
								<TableHead>
									<TableRow>
										<TableCell
											className={classes.tableCellHeadRow}
										>
											Nombre
										</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{row.docRequerida?.map((docRow) => (
										<TableRow key={docRow.archivoTipo_id}>
											<TableCell
												component="th"
												scope="row"
												className={
													classes.tableCellRowItem
												}
											>
												{docRow.titulo}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
												className={
													classes.tableCellRowItem
												}
												align={"right"}
											>
												<IconButton
													onClick={handleDeleteDocumentation(
														docRow
													)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			<Dialog open={openDialog} onClose={onCloseDialog}>
				<DialogTitle className={classes.modalTitle}>
					¿Estás seguro de realizar los cambios?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						La documentación dejará de ser requerida en el tipo de
						siniestro
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCloseDialog} color="primary">
						Cancelar
					</Button>
					<Button onClick={onSubmit} color="primary" autoFocus>
						{siniestroTipoArchivoTipoReducer.isLoadingDelete ? (
							<CircularProgress size={20} />
						) : (
							"Aceptar"
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Row;
