import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Dropzone from "../../../../components/Dropzone";
import SideBar from "../../../../components/SideBar";
import { useStyles } from "./styles";
import { Typography, CircularProgress, Button } from "@mui/material";
import ComposedFormGroup from "../../../../components/ComposedFormGroup";
import { onUploadFilesCompañia } from "../FourthForm/functions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, isEqual } from "lodash";
import { onComunicationClick, onNewCommunicationClick } from "../FirstForm/functions"
import { LOAD_FILE_SUCCESS } from "../../../../actions/types";
import allActions from "../../../../actions";
import CustomDialogFiles from "../../components/CustomDialogFiles";
import FilesList from "../Files";
import DialogThread from "../../../../components/DialogThreads";

function FourthForm(props) {
	const classes = useStyles();
	const { onChange, isEverythingDisabled } = props;
	const [files, setFiles] = useState([]);
	const [openThreads, setOpenThreads] = useState(false)
	const dispatch = useDispatch();
	const history = useHistory();
	const [filesMaxSize, setFilesMaxSize] = useState(false);
	const siniestroId = new URLSearchParams(window.location.search).get("id");
	const archivoReducer = useSelector((state) => state.archivoReducer);
	const [openDialog, setOpenDialog] = useState(false);
	const [filesWithError, setFilesWithError] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const createSiniestroReducer = useSelector(
		(state) => state.createSiniestroReducer
	);
	const comunicationsReducer = useSelector((state) => state.comunicationsReducer);
	const OD = createSiniestroReducer.individualSiniestro?.result?.operacion;

	const handleUploadFiles = (e) => {
		onUploadFilesCompañia(files, setFilesMaxSize, siniestroId, dispatch);
		setTimeout(() => {
			setFiles([])
		}, 3000);
	};
	const HandleThreads = () => {
		setOpenThreads(false)
	}
	useEffect(() => {
		if (
			archivoReducer.error &&
			isEmpty(
				filesWithError.find(
					(e) => e === archivoReducer?.error?.response?.data?.result
				)
			)
		) {
			setFilesWithError((prevState) => [
				...prevState,
				archivoReducer.error?.response?.data?.result,
			]);
		}

		if (
			!isEmpty(archivoReducer.uploadedFile) &&
			isEmpty(
				uploadedFiles.find(
					(e) => e.nombre === archivoReducer.uploadedFile?.nombre
				)
			)
		) {
			setUploadedFiles((prevState) => [
				...prevState,
				archivoReducer.uploadedFile,
			]);
		}
	}, [archivoReducer, dispatch, uploadedFiles, filesWithError]);

	useEffect(() => {
		if (
			isEqual(
				uploadedFiles.length + filesWithError.length,
				files.length
			) &&
			files.length > 0
		) {
			if (!isEmpty(filesWithError)) {
				setOpenDialog(true);
				setFiles([]);
			} else {
				setUploadedFiles([]);
				setFiles([]);
			}

			dispatch({ type: LOAD_FILE_SUCCESS, payload: [] });
			dispatch(
				allActions.archivoActions.getAllFilesBySiniestro(siniestroId)
			);
		}
		if (!isEmpty(filesMaxSize)) {
			setOpenDialog(true);
		}
	}, [
		filesMaxSize,
		uploadedFiles,
		filesWithError,
		files,
		siniestroId,
		dispatch,
	]);

	useEffect(() => {
		if (!isEmpty(filesMaxSize)) {
			setOpenDialog(true);
		}
	}, [filesMaxSize]);

	return (
		<Grid container spacing={6}>
			<Grid item xs={12} md={6} className={classes.gridItem}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12} className={classes.itme}>
						<Typography variant="h5">{OD?.compNombre}</Typography>
					</Grid>
					<Grid item xs={12} md={12} className={classes.itme}>
						<ComposedFormGroup
							placeholder="Fecha de pago"
							typeInput="date"
							id="date"
							onChange={onChange}
							isDisabled={isEverythingDisabled}
						/>
					</Grid>
					<Grid item xs={12} style={{ padding: "20px 5px 45px 10px" }}>
						<FilesList
							files={archivoReducer.allFiles?.filter((fi) =>
								isEqual(fi.entidad, "compania")
							)}
						/>
						<Dropzone files={files} setFiles={setFiles} />
						<Button
							variant="contained"
							onClick={handleUploadFiles}
							color="primary"
						>
							{archivoReducer.isLoading ? (
								<CircularProgress size={20} color={"inherit"} />
							) : (
								"Subir archivos"
							)}
						</Button>
					</Grid>
					{archivoReducer.error &&
						<Grid className={classes.errorContainer}>
							{archivoReducer.error ? archivoReducer.error : ''}
						</Grid>}
				</Grid>
			</Grid>

			<Grid item xs={12} md={6} className={classes.sideBar}>
				<SideBar title="Comunicación con Compañía"
					data={comunicationsReducer.comunicationsBySiniestro?.result?.filter((comunicacion) => comunicacion.entidad_comunicacion === "compania")}
					isLoading={comunicationsReducer.isLoading}
					handleFabButtonClick={onNewCommunicationClick(history, siniestroId, "compania")}
					handleListItemClick={onComunicationClick(history, siniestroId)}
					unreadMessages={comunicationsReducer.messagesUnread}
					setOpenThreads={setOpenThreads}
					openThreads={openThreads}
					tipo="Label_4391670337534562555" //ID LABEL COMPANIA
				/>
			</Grid>
			<CustomDialogFiles
				filesNames={
					!isEmpty(filesMaxSize) ? filesMaxSize : filesWithError
				}
				open={openDialog}
				setOpen={setOpenDialog}
				setFilesMaxSize={setFilesMaxSize}
				isSize={!isEmpty(filesMaxSize)}
				setFilesWithError={setFilesWithError}
				setUploadedFiles={setUploadedFiles}
			/>
			<DialogThread
				isOpen={openThreads}
				onClose={HandleThreads}
			/>
		</Grid >
	);
}

FourthForm.propTypes = {
	onChange: PropTypes.func,
	onClick: PropTypes.func,
};

export default FourthForm;
