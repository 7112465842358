import { isNull } from "lodash";
import {
	CREATE_SINIESTRO_SUCCESS,
	GET_AJUSTADORES_SUCCESS,
	GET_ALL_SINIESTROS_SUCCESS,
	GET_CLIENTS_SUCCESS,
	GET_COMUNICATIONS_SUCCESS,
	GET_COMUNICATIONS_TYPES_SUCCESS,
	GET_INDIVIDUAL_SINIESTRO_SUCCESS,
	GET_OPERATION_SUCCESS,
	GET_SINGLE_COMUNICATION_SUCCESS,
	GET_SINIESTROS_SUCCESS,
	UPDATE_SINIESTRO_SUCCESS,
} from "../actions/types";
import XLSX from "xlsx";
import FileSaver from "file-saver";

const getURL = () => {
	switch (window.location.hostname) {
		case "siwcargo.local":
			return "http://localhost:7000";
		case "localhost":
			return "http://localhost:7000";
		case "siniestrostest.siwcargo.com":
			return "https://siniestros.api.prod.siwcargo.com";
		default:
			return "https://siniestros.api.prod.siwcargo.com";
	}
};

export const BASE_URL = getURL();

const siwcargoURL = () => {
	switch (window.location.hostname) {
		case "siwcargo.local":
		case "localhost":
			return "http://siwcargo.local";
		case "siniestrostest.siwcargo.com":
			return "https://sistema.siwcargo.com";
		default:
			return "https://sistema.siwcargo.com";
	}
};
const siwcargoURLOrigin = () => {
	switch (window.location.hostname) {
		case "siwcargo.local":
		case "localhost":
			return "http://siwcargo.local:3000";
		case "siniestrostest.siwcargo.com":
			return "https://sistema.siwcargo.com";
		default:
			return "https://sistema.siwcargo.com";
	}
};

export const SIWCARGO_URL = siwcargoURL();
export const SIWCARGO_URL_ORIGIN = siwcargoURLOrigin();

export const SUCCESS = "succcess";
export const ERROR = "error";

export const toLocaleDateString = (date) => {
	if (!isNull(date)) {
		const datt = new Date(date);
		const month = datt.getUTCMonth() + 1; //months from 1-12
		const day = datt.getUTCDate();
		const year = datt.getUTCFullYear();

		return day + "/" + month + "/" + year;
	}
};

export const getDaysDifference = (date1, date2) => {
	if (date1 && date2) {
		const parsedDate1 = new Date(date1);
		const parsedDate2 = new Date(date2);
		const diff_in_time = parsedDate2.getTime() - parsedDate1.getTime();
		return Math.round(Math.abs(diff_in_time / (1000 * 3600 * 24)));
	} else {
		return "";
	}
};

export const clearDispatchs = (dispatch) => {
	dispatch({ type: GET_OPERATION_SUCCESS, success: [] });
	dispatch({ type: GET_SINIESTROS_SUCCESS, success: [] });
	dispatch({ type: GET_CLIENTS_SUCCESS, success: [] });
	dispatch({ type: CREATE_SINIESTRO_SUCCESS, success: [] });
	dispatch({ type: UPDATE_SINIESTRO_SUCCESS, success: [] });
	dispatch({ type: GET_INDIVIDUAL_SINIESTRO_SUCCESS, success: [] });
	dispatch({ type: GET_ALL_SINIESTROS_SUCCESS, success: [] });
	dispatch({ type: GET_COMUNICATIONS_SUCCESS, success: [] });
	dispatch({ type: GET_COMUNICATIONS_TYPES_SUCCESS, success: [] });
	dispatch({ type: GET_SINGLE_COMUNICATION_SUCCESS, success: [] });
	dispatch({ type: GET_AJUSTADORES_SUCCESS, succes: [] })
};

export const setItemSessionStorage = (key, item) => {
	sessionStorage.setItem(key, JSON.stringify(item));
};

export const getItemSessionStorage = (key) => {
	return JSON.parse(sessionStorage.getItem(key));
};

export const getBase64 = (file) => {
	return new Promise((resolve) => {
		let baseURL = "";
		// Make new FileReader
		let reader = new FileReader();

		// Convert the file to base64 text
		reader.readAsDataURL(file);

		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object
			baseURL = reader.result;
			resolve(baseURL);
		};
	});
};

export const createBreadcrumbData = (href, label) => ({
	href,
	label,
});

// TODO: Estado del siniestro "dado de baja", check this in production
export const ESTADOS_ID = {
	enProceso: 2,
	pedidoDeDoc: 3,
	derivacionAseguradora: 4,
	analisisDocAjustadorCia: 5,
	standby: 7,
	liquidado: 9,
	pagado: 10,
	baja: 11,
	rechazado: 12,
};
//Para Id de acciones
export const ACCION_DENUNCIA_COMPANIA = 1
export const ACCION_PEDIDO_DOCUMENTACION = 3

//para subir archivos
export const CANTIDAD_ARCHIVOS = 2;

export const exportToExcel = (jsonToDownload, fileName) => {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const ws = XLSX.utils.json_to_sheet(jsonToDownload);
	const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + fileExtension);
};

export const sliceText = (text, numberOfChars) => {
	const suspensivePoints = text?.length > numberOfChars ? "..." : "";

	return text?.slice(0, numberOfChars) + suspensivePoints;
};

export function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}
