import React from "react";
import { Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import useStyles from "../../styles";
import { polizaSiniestro } from "../../functions";
export default function TableDashboard(props) {
  const { siniestros } = props
  const classes = useStyles();
  let poliza;
  if (siniestros) {
    poliza = polizaSiniestro(siniestros);
    poliza.sort(function (a, b) { return b.reserva - a.reserva });
  };

  return (
    <TableContainer className={classes.indTable} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead className={classes.colorTitle}>
          <TableRow>
            <TableCell className={classes.tableTittle}>Poliza</TableCell>
            <TableCell className={classes.tableTittle}>Cantidad</TableCell>
            <TableCell className={classes.tableTittle}>Reserva</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {poliza ? poliza.map((row) => (
            <TableRow key={row}>
              <TableCell className={classes.tableBody} component="th" scope="row">
                {row?.name}
              </TableCell>
              <TableCell className={classes.tableNumber}>{row?.cantidad}</TableCell>
              <TableCell className={classes.tableNumber}>${(Math.round(row?.reserva * 100) / 100).toLocaleString()}</TableCell>
            </TableRow>
          ))
            :
            <TableCell className={classes.table}>Falta info</TableCell>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}