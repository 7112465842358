import styled from "styled-components";

export const ContainerStyled = styled.div`
	@media only screen and (max-width: 960px) {
		margin-top: 90px;
	}
`;

export const CardStyled = styled.div`
	width: 100%;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	@media only screen and (max-width: 600px) {
		width: 90%;
	}
`;

export const FormStyled = styled.form`
	display: flex;
	flex-direction: column;
	width: 95%;

	@media only screen and (max-width: 480px) {
		width: 90%;
	}
`;

export const InputStyled = styled.input`
	margin-bottom: 10px;
`;

export const ButtonContainerStyled = styled.div`
	width: 100%;
	padding-top: 15px;
	text-align: right;
`;
