import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
	ovalContainer: {
		height: "75vh",
		display: "flex",
		justifyContent: "center"
	},
	buttonAdd: {
		fontWeight: "500",
		backgroundColor: "red"
	},
	customToolbar: {
		display: "flex",
		justifyContent: "space-between",
		backgroundColor: "#f8f8f8 !important",
	},
	addIcon: {
		marginRight: "5px",
	},
	actionsLink: {
		width: "100%",
		height: "100%",
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
	},
}));

export default useStyles;
