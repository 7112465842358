
import { getDaysDifference } from "../../../common/constants"

//comáñia con mas siniestros, recibe el array de siniestros
export function clientWithMoreSiniestros(arr) {
    let tomadores = {}
    let mayor = 0
    let comp = ""
    for (let i = 0; i < arr.length; i++) {
        if (tomadores[arr[i].tomador]) {
            tomadores[arr[i].tomador] = tomadores[arr[i].tomador] + 1
        } else {
            tomadores[arr[i].tomador] = 1
        }
    }
    for (const property in tomadores) {
        if (tomadores[property] > mayor) {
            mayor = tomadores[property]
            comp = property
        }
    }
    return (`${comp}   : ${mayor} siniestros`)
}

export function compSelect(arr) {
    let compañias = {}
    for (let i = 0; i < arr.length; i++) {
        if (compañias[arr[i].compañia]) {
            compañias[arr[i].compañia] = compañias[arr[i].compañia] + 1
        } else {
            compañias[arr[i].compañia] = 1
        }
    }
    return compañias
}

//Recibe el array de siniestros y calcula el que mayor tiempo lleva sin ser liquidado
export function moreDelay(arr) {
    let more = null
    for (let i = 0; i < arr.length; i++) {
        let diffDay = getDaysDifference(new Date(), arr[i].fechaDenuncia)
        let diffDay2 = getDaysDifference(arr[i].fechaDenuncia, arr[i].fechaPago)
        if (diffDay > more && arr[i].estadoTitulo !== "LIQUIDACION") {
            more = diffDay
        }
        if (diffDay2 > more) {
            more = diffDay2
        }
    }
    return (`${Math.ceil(more)} días`)
}

//recibe el array de siniestros y devuelve el ID del siniestro que mayor tiempo lleva sin ser liquidado
export function moreDelayId(arr) {
    let more = null
    let idSiniestro = 0
    for (let i = 0; i < arr.length; i++) {
        let diffDay = getDaysDifference(new Date(), arr[i].fechaDenuncia)
        let diffDay2 = getDaysDifference(arr[i].fechaDenuncia, arr[i].fechaPago)
        if (diffDay > more && arr[i].estadoTitulo !== "LIQUIDACION") {
            more = diffDay
            idSiniestro = arr[i].idSiniestro
        }
        if (diffDay2 > more) {
            more = diffDay2
            idSiniestro = arr[i].idSiniestro
        }
    }
    return idSiniestro
}


//Recibe el array de siniestros y calcula el que fue liquidado en menor tiempo
export function lessDelay(arr) {
    let less = 999
    for (let i = 0; i < arr.length; i++) {
        let diffDay = getDaysDifference(arr[i].fechaDenuncia, arr[i].fechaPago)
        if (diffDay < less && diffDay >= 1) {
            less = diffDay
        }
        //en el caso de que no haya ningún siniestro liquidado o se haya liquidado el mismo día que se denunció
        else {
            less = null
        }
    }
    return (`${Math.ceil(less)} días`)
}

//Recibe el array de siniestros y devuelve el id del que fue liquidado en menor tiempo
export function lessDelayId(arr) {
    let less = 999
    let idSiniestro = 0
    for (let i = 0; i < arr.length; i++) {
        let diffDay = getDaysDifference(arr[i].fechaDenuncia, arr[i].fechaPago)
        if (diffDay < less && diffDay >= 1) {
            less = diffDay
            idSiniestro = arr[i].idSiniestro
        }
    }
    return idSiniestro
}

//recibe el array de siniestros y devuelve el monto total de la reserva
export function totalReserva(arr) {
    let reserva = 0
    arr.map((s) => {
        if (s.montoReclamado) {
            return reserva = reserva + s.montoReclamado
        }
        return reserva
    })
    return reserva.toLocaleString()
}

//recibe el array de siniestros y genera un array con objetos de esta estructura: {name:tipoSiniestro,cantidad:numero,reserva:totalReserva}
export function tipoSiniestro(arr) {
    let contTipo = []
    for (var i = 0; i < arr.length; i++) {
        if (contTipo.findIndex((s) => s.name === arr[i].tipoSiniestro) === -1) {
            contTipo.push({
                name: arr[i].tipoSiniestro,
                cantidad: 1,
                reserva: arr[i].montoReclamado
            })
        } else {
            contTipo[contTipo.findIndex((s) => s.name === arr[i].tipoSiniestro)].cantidad += 1
            contTipo[contTipo.findIndex((s) => s.name === arr[i].tipoSiniestro)].reserva += arr[i].montoReclamado
        }
    }
    return contTipo
}

//recibe el array de siniestros y genera un array con objetos de esta estructura: {name:EstadoSiniestro,cantidad:numero,reserva:totalReserva}
export function estadoSiniestro(arr) {
    let contEstado = []
    for (var i = 0; i < arr.length; i++) {
        if (contEstado.findIndex((s) => s.name === arr[i].estadoTitulo) === -1) {
            contEstado.push({
                name: arr[i].estadoTitulo,
                cantidad: 1,
                reserva: arr[i].montoReclamado
            })
        } else {
            contEstado[contEstado.findIndex((s) => s.name === arr[i].estadoTitulo)].cantidad += 1
            contEstado[contEstado.findIndex((s) => s.name === arr[i].estadoTitulo)].reserva += arr[i].montoReclamado
        }
    }
    return contEstado
}

export function clienteSiniestro(arr) {
    let cont = []
    for (var i = 0; i < arr.length; i++) {
        if (cont.findIndex((s) => s.name === arr[i].tomador) === -1) {
            cont.push({
                name: arr[i].tomador,
                cantidad: 1,
                reserva: arr[i].montoReclamado
            })
        } else {
            cont[cont.findIndex((s) => s.name === arr[i].tomador)].cantidad += 1
            cont[cont.findIndex((s) => s.name === arr[i].tomador)].reserva += arr[i].montoReclamado
        }
    }
    return cont
}

export function polizaSiniestro(arr) {
    let cont = []
    for (var i = 0; i < arr.length; i++) {
        if (cont.findIndex((s) => s.name === arr[i].numeroPoliza) === -1) {
            cont.push({
                name: arr[i].numeroPoliza,
                cantidad: 1,
                reserva: arr[i].montoReclamado
            })
        } else {
            cont[cont.findIndex((s) => s.name === arr[i].numeroPoliza)].cantidad += 1
            cont[cont.findIndex((s) => s.name === arr[i].numeroPoliza)].reserva += arr[i].montoReclamado
        }
    }
    return cont
}

export function datosPorPoliza(arr) {
    var intento = {
        comp: {
            name: "CUBB",
            polizas: [{
                numero: 1231,
                siniestros: []

            },
            {
                numero: 123,
                siniestros: []
            }]
        }
    }
    var data = {}
    arr.map((s) => {
        if (!data[s.compañia]) {
            data[s.compañia] = { [s.numeroPoliza]: [s] }
        }
        if (!data[s.compañia][s.numeroPoliza]) {
            data[s.compañia] = {
                ...data[s.compañia],
                [s.numeroPoliza]: [s]
            }
        }
        if (data[s.compañia][s.numeroPoliza]) {
            if (!data[s.compañia][s.numeroPoliza].includes(s)) {
                data[s.compañia][s.numeroPoliza].push(s)
            }
        }
        return
    })
    return (data)
};

export function datosPorPoliza2(arr) {
    let cont = []
    for (var i = 0; i < arr.length; i++) {
        if (cont.findIndex((s) => s.name === arr[i].compañia) === -1) {
            cont.push({
                name: arr[i].compañia,
                polizas: [{ numero: arr[i].numeroPoliza, siniestros: [arr[i]] }],

            })
        } else if (cont[cont.findIndex((s) => s.name === arr[i].compañia)].polizas.findIndex((p) => p.numero === arr[i].numeroPoliza) === -1) {
            cont[cont.findIndex((s) => s.name === arr[i].compañia)].polizas.push({
                numero: arr[i].numeroPoliza,
                siniestros: [arr[i]]
            })
        } else {
            cont[cont.findIndex((s) => s.name === arr[i].compañia)].polizas[cont[cont.findIndex((s) => s.name === arr[i].compañia)].polizas.findIndex((p) => p.numero === arr[i].numeroPoliza)].siniestros.push(arr[i])
        }
    };
    return cont
};