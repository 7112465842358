import React, {useEffect, useState} from "react";
import { createBreadcrumbData } from "../../common/constants";
import {Grid} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";
import useStyles from "./styles";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper, Button} from  '@material-ui/core';
import Columnas from './components/Columnas'
import DialogEstudio from "./components/DialogEstudio";
import DialogAjustador from "./components/DialogAjustador";

function Ajustadores (props) {
    const {setBreadcrumbs} = props
    const classes = useStyles();
    const dispatch = useDispatch(); 
    const estudiosReducer = useSelector((state) => state.estudiosReducer)
    const ajustadoresReducer = useSelector((state) => state.ajustadoresReducer)
    const [load, setLoad] = useState(false)
    const [openA, setOpenA] = useState(false)
    const [openE, setOpenE] = useState(false)

    useEffect (() => {
      setTimeout(() => {
        dispatch(allActions.estudiosActions.getAllEstudios())
        dispatch(allActions.ajustadoresActions.getAllAjustadores())
      }, 1000);
    }, [dispatch,load])

    useEffect(() => { 
		setBreadcrumbs([
			createBreadcrumbData(`/ajustadores`, `Ajustadores`),
		]);
	}, [setBreadcrumbs]);
    
    const ajustadores = ajustadoresReducer.allAjustadores?.map(
        (ajustador) => ({
            ...ajustador,
            activo: ajustador.activo === 1 ? "activo" : "inactivo",
            id: Math.ceil(Math.random() * 1000),
        })
    )
    const estudios = estudiosReducer.allEstudios?.map(
        (estudio) => ({
            ...estudio,
            activo: estudio.activo === 1 ? "activo" : "inactivo",
            id: Math.ceil(Math.random() * 1000),
            ajustadores: []
        })
    )
    if(estudios){
      estudios.forEach((estudio) =>{
        if(ajustadores){
         return ajustadores.forEach((ajustador) =>{
            if(ajustador.estudio_id === estudio.id_estudio){
              return estudio.ajustadores.push(ajustador)
            }
          })
        }
        
      })
    } 

    const handleClickOpenA = () => {
      setOpenA(true);
    };
    const handleClickOpenE = () => {
      setOpenE(true);
    };
    return (
      <Grid container className={classes.container}>
        <Grid>
          <Button variant="outlined"  className={classes.button} align="rigth" onClick={handleClickOpenE} >Nuevo estudio</Button>
          <Button variant="outlined"  className={classes.button} align="rigth" onClick={handleClickOpenA} >Nuevo ajustador</Button>
        </Grid>
        <TableContainer className={classes.TableContainer} component={Paper}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableHead} />
                <TableCell className={classes.tableHead} >Nombre estudio</TableCell>
                <TableCell className={classes.tableCellRow} >Email</TableCell>
                <TableCell className={classes.tableCellRow} >Teléfono</TableCell>
                <TableCell className={classes.tableCellRow} >Dirección</TableCell>
                <TableCell className={classes.tableCellRow} align="right">Editar</TableCell>
                <TableCell className={classes.tableCellRow} align="right">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {estudios ? estudios.map((estudio) => (
                <Columnas load={load} setLoad={setLoad} key={estudio.id_estudio} estudio={estudio} ajustadores={estudio.ajustadores}/>
              )) : <h1> No hay estudios</h1>}
            </TableBody>
          </Table>
        </TableContainer>
        <DialogEstudio load={load} setLoad={setLoad} open={openE} setOpen={setOpenE}></DialogEstudio>
        <DialogAjustador load={load} setLoad={setLoad} open={openA} setOpen={setOpenA} estudios={estudios}></DialogAjustador>
       </Grid>
    )
}

export default Ajustadores;