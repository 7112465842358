import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { createBreadcrumbData } from "../../common/constants";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import SkeletonLoader from "../../components/SkeletonLoader";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import allActions from "../../actions";
import { Dialog, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, DialogActions } from "@material-ui/core";
import { ACTIVETE_IS_VIEW_TEMPLATE, ACTIVETE_IS_EDIT_TEMPLATE } from "../../actions/types";

const Templates = (props) => {
    const { setBreadcrumbs } = props;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const templatesReducer = useSelector((state) => state.templatesReducer);
    const arrayAllTemplates = templatesReducer?.allTemplates?.result;
    const [stateDialog, setStateDialog] = useState({
        isOpen: false,
        templateIdSelected: null
    });

    useEffect(() => {
        setBreadcrumbs([
            createBreadcrumbData(`/parametrizacion`, `Parametrización`),
            createBreadcrumbData(`/parametrizacion/plantillas`, `Plantillas`)
        ]);
    }, [setBreadcrumbs]);

    useEffect(() => {
        dispatch(allActions.templatesActions.getAllTemplates())
        dispatch({ type: ACTIVETE_IS_VIEW_TEMPLATE, payload: false })
        dispatch({ type: ACTIVETE_IS_EDIT_TEMPLATE, payload: false })
    }, []);

    const getId = (idTemplate, stateTemplates) => {
        if (stateTemplates === 'isView') {
            dispatch({ type: ACTIVETE_IS_VIEW_TEMPLATE, payload: true })
            setTimeout(() => {
                dispatch(allActions.templatesActions.getTemplate(idTemplate))
            }, 300);
        }
        if (stateTemplates === 'isEdit') {
            dispatch({ type: ACTIVETE_IS_EDIT_TEMPLATE, payload: true })
            setTimeout(() => {
                dispatch(allActions.templatesActions.getTemplate(idTemplate))
            }, 300);
        }
    }

    const handleDeleteTemplate = (plantilla_id) => {
        dispatch(allActions.templatesActions.deleteTemplate(plantilla_id))
    }

    const onCloseDialog = () => setStateDialog({ ...stateDialog, isOpen: !stateDialog.isOpen });

    return (
        <>
            <TableContainer
                component={Paper}>
                <Table>
                    <TableHead className={classes.tableHeaderTemplates}>
                        <TableRow>
                            <TableCell className={classes.tableCellTemplates}>
                                Título
                            </TableCell>
                            <TableCell className={classes.tableCellTemplates}>
                                Funcionalidad
                            </TableCell>
                            <TableCell className={classes.tableCellTemplates}>
                                Acciones
                            </TableCell>
                            <TableCell>
                                <Button
                                    onClick={() =>
                                        history.push(
                                            "/parametrizacion/nueva-plantilla"
                                        )
                                    }
                                >
                                    {" "}
                                    nueva plantilla
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templatesReducer.isLoading ? (
                            <TableRow>
                                <TableCell className={classes.tableCellTemplates}>
                                    <SkeletonLoader
                                        numberOfSkeletons={6}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCellTemplates}>
                                    <SkeletonLoader
                                        numberOfSkeletons={6}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCellTemplates}>
                                    <SkeletonLoader
                                        numberOfSkeletons={6}
                                    />
                                </TableCell>
                            </TableRow>
                        ) : (
                            arrayAllTemplates?.map(templates => (
                                <TableRow key={templates.plantilla_id}>
                                    <TableCell
                                        className={classes.fontSize} > {templates.titulo}
                                    </TableCell>
                                    <TableCell
                                        className={classes.fontSize} > {templates.accion}
                                    </TableCell>
                                    <TableCell className={classes.iconsTemplate}>
                                        <Link
                                            to={`/parametrizacion/visualizar-plantilla?id=${templates.plantilla_id}`}
                                            className={classes.actionsSearchLink}
                                        >
                                            <SearchIcon style={{ fontSize: "20px" }}
                                                onClick={() => getId(templates.plantilla_id, 'isView')}
                                            />
                                        </Link>
                                        <Link
                                            to={`/parametrizacion/editar-plantilla?id=${templates.plantilla_id}`}
                                        >
                                            <EditIcon
                                                onClick={() => getId(templates.plantilla_id, 'isEdit')}
                                            />
                                        </Link>
                                        <IconButton
                                            onClick={() => (setStateDialog({ isOpen: true, templateIdSelected: templates.plantilla_id }))}
                                        >
                                            <DeleteIcon style={{ fontSize: "18px" }} />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={stateDialog.isOpen}
                onClose={onCloseDialog}
            >
                <DialogTitle
                    className={classes.fontSize}
                >
                    ¿Está seguro que desea eliminar la plantilla?
                </DialogTitle>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={onCloseDialog}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => handleDeleteTemplate(stateDialog.templateIdSelected)}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Templates;