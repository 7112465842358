import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    buttonDoc: {
        marginBottom: "20px",
        marginRight: "80px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    tableHeader: {
        background: "#f8f8f8",
        width: "100%",
    },
    tableHead: {
        fontSize: "15px",
        fontWeight: "700",
    },
    rootRow: {
        "& > *": {
            borderBottom: "unset",
            fontSize: "14px",
        },
        "& > tr": {
            fontSize: "14px !important",
        },
    },
    tableCellRow: {
        paddingRight: "0",
    },
    tableHeaderRow: {
        fontSize: "14px !important",
    },
    expandTitleRow: {
        fontSize: "15px",
        fontWeight: "bold",
    },
    expandTableRow: {
        marginTop: "10px",
    },
    tableCellHeadRow: {
        fontSize: "14px",
        fontWeight: "600",
    },
    tableCellRowItem: {
        fontSize: "13px",
    },
    skeletonLoader: {
        width: "700px",
        marginLeft: "30px",
    },
    customStylesInput: {
        padding: "20px 40px !important",
        width: "500px",
    },
    tablePagination: {
        "& > td": {
            width: "1400px",
            background: "#f8f8f8",
        },
    },
    buttonAdd: {
        fontSize: "13px !important",
        color: "#2930FF",
        margin: "15px 8px",
        padding: "0px 10px",
        borderColor: "#2930FF",
        borderRadius: "100px",
        textTransform: "none",
        marginLeft: "20px",
    },
    addIcon: {
        marginRight: "6px",
    },

}));

export default useStyles;
