import React, {useState, useEffect} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import allActions from "../../../actions";
export default function DialogEditAjustador (props) {
    const {openEditA, setOpenEditA, ajustador, load, setLoad} = props
    const [ajustadorActivo, setAjustadorActivo] = useState({})
    const dispatch = useDispatch();
    useEffect(() => {
      setAjustadorActivo({
        id: ajustador.ajustador_id,
        nombre: ajustador.nombre,
        apellido: ajustador.apellido,
        email: ajustador.email,
        telefono: ajustador.telefono
      })
    },[ajustador])
    const handleClose = () => {
        setOpenEditA(false)
    }
    const handleSave = (ajustadorActivo) => {
        const body = { 
            id: ajustadorActivo.id,
            nombre: ajustadorActivo.nombre,
            apellido: ajustadorActivo.apellido,
            email: ajustadorActivo.email,
            telefono: ajustadorActivo.telefono
        }
        
        dispatch(allActions.ajustadoresActions.putAjustador(body))
        setLoad(!load)
        setOpenEditA(false)
    }
    const handleChange = (e) => {
        setAjustadorActivo({
            ...ajustadorActivo,
            [e.target.name] : e.target.value
        })
    }
    const handleState = (ajustador) => {
      const id = ajustador.ajustador_id
      if(ajustador.activo === "activo"){
        dispatch(allActions.ajustadoresActions.lowAjustador({id}))
        setLoad(!load)
        setOpenEditA(false)
      }else{
        dispatch(allActions.ajustadoresActions.upAjustador({id}))
        setLoad(!load)
        setOpenEditA(false)
      }
    }
    return (
        <Dialog open={openEditA} onClose={handleClose}>
        <DialogTitle>Editar ajustador {ajustador.nombre}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Completa los siguientes campos para agregar un nuevo estudio.
          </DialogContentText>
          <TextField
            defaultValue={ajustador.nombre}
            onChange={handleChange}
            margin="dense"
            name="nombre"
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
          />
           <TextField
            defaultValue={ajustador.apellido}
            onChange={handleChange}
            margin="dense"
            name="apellido"
            label="Apellido"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            defaultValue={ajustador.email}
            onChange={handleChange}
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            defaultValue={ajustador.telefono}
            onChange={handleChange}
            margin="dense"
            name="telefono"
            label="Teléfono"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => {handleState(ajustador)}}>{ajustador.activo === "activo" ? "Dar de baja" : "Dar de alta"}</Button>
          <Button onClick={() => {handleSave(ajustadorActivo)}}>Guardar</Button>
        </DialogActions>
      </Dialog>
    )
}
