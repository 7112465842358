import makeStyles from "@material-ui/core/styles/makeStyles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
	gridItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-evenly",
	},
	container: {
		display: "flex",
		justifyContent: "space-between",
		padding: "10px",
		alignItems: "center",
	},
	title: {
		marginBottom: "20px"
	},
	link: {
		fontSize: "15px",
		cursor: "pointer",
	},
	buttonGrid: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		margin: "50px 0 20px",
	},
	buttonAdd: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		margin: "50px 0 20px",
	},
	paperFiles: {
		display: "flex",
		justifyContent: "center",
		padding: "40px",
		marginTop: "10px",
		paddingBottom: "0",
		flexDirection: "column",
	},
	filesGrid: {
		padding: "20px",
		paddingTop: "40px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	expandIconButton: {
		position: "relative",
		right: "calc(-50% + 20px)",
		bottom: "-18px",
	},
	siniestroType: {
		marginBottom: "15px",
		fontSize: "15px",
		display: "flex",
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	firstFormCommunicationGrid: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		[theme.breakpoints.down("960")]: {
			height: "auto",
		},
	},
	firstFormCommunicationPaper: {
		marginTop: "20px",
	},
	idSubtitle: {
		color: "gray",
		fontSize: "16.5px",
	},
	siniestroDataContainer: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	},
	divider: {
		width: "100%",
		margin: "20px 0",
		height: "2px",
	},
	typographyData: {
		fontSize: "17px !important",
		marginBottom: "13px",
	},
	amountsContainer: {
		marginTop: "20px",
		display: "flex",
	},
	inputEditable: {
		width: "70%",
		outline: "none",
		borderRight: "0",
		borderLeft: "0",
		borderTop: "0",
	},
	paperComments: {
		display: "flex",
		justifyContent: "center",
		padding: "40px",
		marginTop: "30px",
		flexDirection: "column",
	},
	requiredDocumentationTitle: {
		display: "flex",
		alignItems: "center",
	},
	listItemDocumentation: {
		display: "flex",
		alignItems: "center",
		marginBottom: "15px",
	},
	listCompleted: {
		display: "flex",
		alignItems: "center",
		marginBottom: "15px",
		textDecoration: "line-through"
	},
	checkIcon: {
		marginRight: "8px",
		color: "green",
	},
	documentationList: {
		listStyle: "none",
		padding: "20px",
	},
	dropzoneGrid: {
		"& > div": {
			width: 900,
		},
	},
	docSubidaTitle: {
		width: "100%",
		marginBottom: "10px",
	},
	selectCategorize: {
		maxWidth: "400px",
	},
	titleDialog: {
		"& h2": {
			fontSize: "16px !important",
		},
	},
	textDialog: {
		fontSize: "15px !important",
	},
	buttonDialog: {
		fontSize: "14px !important",
		"& .MuiButton-contained": {
			margin: "20px !important",
		},
		margin: "20px 20px",
	},
	buttonFinish: {
		marginTop: "40px",
		backgroundColor: "#a8323d",
	},
	radioButton: {
		display: "flex",
		flexDirection: "row",
	},
	liquidacionText: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#428642",
	},
	bajaText: {
		color: "#ca5353",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	procesoDePagoText: {
		color: "#ff9800",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	iconsEstadosContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginRight: "12px",
	},
	iconsEstados: {
		fontSize: "20px",
	},
	docReqCont: {
		display: "flex",
		flexDirection: "column",
	},
	docReqTypo: {
		fontWeight: "bold",
		padding: "10px 0",
	},
	docExtraTitle: {
		display: "flex",
		alignItems: "center",
		padding: "15px 0",
	},
	buttonAddInputDoc: {
		marginLeft: "15px",
	},
	itme: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	headerContainer: {
		display: "flex",
		justifyContent: "space-between"
	},
	docContainer: {
		display: "flex",
		width: "100%"
	},
	errorContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		padding: "5% 5% 5% 0%",
		color: "red"
	}
}));

export const FormStyled = styled.div`
	@media only screen and (max-width: 960px) {
		padding: 20px;
	}
`;
