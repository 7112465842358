import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useStyles } from "../../styles";
import { CircularProgress } from "@material-ui/core";
import { isEmpty, isEqual } from "lodash";
import FormControl from "../../../../components/FormControl";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";
import { ESTADOS_ID, getItemSessionStorage } from "../../../../common/constants";
import { useEffect } from "react";
import { BAJA_SINIESTRO_SUCCESS, GET_INDIVIDUAL_SINIESTRO_SUCCESS, LIQUIDACION_SINIESTRO_SUCCESS } from "../../../../actions/types";

const AlertDialog = (props) => {
	const { isOpen, handleOpen } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const siniestroId = new URLSearchParams(window.location.search).get("id");
	const bajaRollback = isEqual(isOpen, "bajaRollback");
	const [typeOfEnding, setTypeOfEnding] = useState("");
	const [endingText, setEndingText] = useState(
		bajaRollback ? "¿Por qué quiere dar el siniestro de alta de nuevo?" : ""
	);
	const [data, setData] = useState({
		comentario: "",
		montoLiquidado: "",
		liquidador: "siw",
		fechaDePago: "",
	});
	const siniestroEstadoReducer = useSelector((state) => state.siniestroEstadoReducer);
	const createSiniestroReducer = useSelector((state) => state.createSiniestroReducer);
	const createSiniestroData = createSiniestroReducer.individualSiniestro?.result;
	const isBaja = isEqual(typeOfEnding, "baja");
	const isRechazo = isEqual(typeOfEnding, "rechazo");

	const handleOption = (option) => () => {
		setTypeOfEnding(option);
		setEndingText(isEqual(option, "baja")
			? "Exprese por qué quiere dar de baja el siniestro"
			: isEqual(option, "rechazo")
			&& "Exprese por qué quiere rechazar el siniestro"
		);
	};

	const handleCancel = () => {
		setTypeOfEnding("");
		handleOpen();
		setData({
			comentario: "",
			montoLiquidado: "",
			liquidador: "siw",
		});
	};

	const handleChange = (prop, value) => {
		setData({ ...data, [prop]: value });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (isBaja && !bajaRollback) {
			dispatch(allActions.siniestroActions.bajaSiniestro(siniestroId, {
				userId: getItemSessionStorage("idUsuario"),
				comentario: data.comentario,
			}));
		} else if (bajaRollback) {
			dispatch(allActions.siniestroActions.updateSiniestroEstado({
				userId: getItemSessionStorage("idUsuario"),
				estadoId: ESTADOS_ID.enProceso,
				comentario: data.comentario,
				siniestroId,
				tipoComentario: "alta",
			}));
		} else if (isRechazo) {
			dispatch(allActions.siniestroActions.updateSiniestroEstado({
				userId: getItemSessionStorage("idUsuario"),
				estadoId: ESTADOS_ID.rechazado,
				comentario: data.comentario,
				siniestroId,
				tipoComentario: "rechazo",
			}));
		} else {
			dispatch(allActions.siniestroActions.liquidacionSiniestro(siniestroId, {
				montoLiquidado: data.montoLiquidado,
				liquidador: isEqual(data.liquidador, "siw")
					? 0
					: createSiniestroData?.siniestro?.idCompañia,
				fechaDePago: data.fechaDePago,
			}));
		}
	};

	const handleBack = () => {
		setTypeOfEnding("");
		setData({
			comentario: "",
			montoLiquidado: "",
			liquidador: "siw",
		});
	};

	useEffect(() => {
		if (
			(!isEmpty(createSiniestroReducer.bajaSiniestro) &&
				isEqual(createSiniestroReducer.bajaSiniestro.status, "success")) ||
			(!isEmpty(createSiniestroReducer.liquidacionSiniestro) &&
				isEqual(createSiniestroReducer.liquidacionSiniestro.status, "success"))
		) {
			dispatch({ type: GET_INDIVIDUAL_SINIESTRO_SUCCESS, payload: [] });
			dispatch({ type: BAJA_SINIESTRO_SUCCESS, payload: [] });
			dispatch({ type: LIQUIDACION_SINIESTRO_SUCCESS, payload: [] });
			dispatch(allActions.siniestroActions.getInidividualSiniestro(siniestroId));
			setTypeOfEnding("");
			handleOpen();
			setData({
				comentario: "",
				montoLiquidado: "",
				liquidador: "siw",
			});
		}
	}, [createSiniestroReducer, dispatch, handleOpen, siniestroId]);

	useEffect(() => {
		if (bajaRollback) {
			setEndingText("¿Por qué quiere dar el siniestro de alta de nuevo?");
		}
	}, [bajaRollback]);

	return (
		<>
			<Dialog open={isOpen} onClose={handleOpen}>
				<form onSubmit={onSubmit}>
					<DialogContent>
						<DialogContentText className={classes.textDialog}>
							{!typeOfEnding && !bajaRollback
								? "¿Cómo quieres finalizar el siniestro?"
								: endingText}
						</DialogContentText>
						<div className={classes.buttonsFinishContainer}>
							{!typeOfEnding && !bajaRollback ? (
								<>
									<Button
										variant="contained"
										className={classes.buttonDialog}
										onClick={handleOption("liquidacion")}
									>
										Liquidar
									</Button>
									<Button
										variant="contained"
										className={classes.buttonDialog}
										onClick={handleOption("baja")}
									>
										Dar de baja
									</Button>
									<Button
										variant="contained"
										className={classes.buttonDialog}
										onClick={handleOption("rechazo")}
									>
										Rechazar
									</Button>
								</>
							) : isBaja || bajaRollback || isRechazo ? (
								<FormControl
									as={"textarea"}
									placeholder={
										bajaRollback
											? "Motivo de alta"
											: isBaja
												? "Motivo de baja"
												: "Motivo de rechazo"
									}
									customStyles={classes.liquidacionInput}
									onChange={handleChange}
									id="comentario"
									isRequired
									value={data.comentario}
								/>
							) : (
								<>
									<h4>¿Desea enviar a liquidación?</h4>
								</>
							)}
						</div>
					</DialogContent>
					<DialogActions>
						{typeOfEnding && (
							<Button
								onClick={handleBack}
								disabled={createSiniestroReducer.isLoading}
							>
								Volver
							</Button>
						)}

						<Button
							onClick={handleCancel}
							color="primary"
							disabled={createSiniestroReducer.isLoading}
						>
							Cancelar
						</Button>
						{(typeOfEnding || bajaRollback) && (
							<Button
								color="primary"
								autoFocus
								disabled={createSiniestroReducer.isLoading}
								type={"submit"}
							>
								{createSiniestroReducer.isLoading || siniestroEstadoReducer.isLoading
									? <CircularProgress size={20} />
									: "Aceptar"}
							</Button>
						)}
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

AlertDialog.propTypes = {
	isOpen: PropTypes.bool,
	title: PropTypes.string,
	text: PropTypes.string,
	handleOpen: PropTypes.func,
	onClickAccept: PropTypes.func,
};

export default AlertDialog;
