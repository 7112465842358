import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Snackbar } from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";
import { Alert } from "@material-ui/lab";
import { CREATE_SINIESTRO_NUMBER_SUCCESS, GET_INDIVIDUAL_SINIESTRO_SUCCESS } from "../../actions/types";
import { createBreadcrumbData } from "../../common/constants";
import MaterialTable from "material-table";
import Search from '@material-ui/icons/Search';
import { Oval } from 'react-loader-spinner'
import * as XLSX from 'xlsx';
import { FcDownload } from "react-icons/fc";
function Siniestros(props) {
	const { setBreadcrumbs } = props;
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const siniestroReducer = useSelector((state) => state.siniestroReducer);
	const [alert, setAlert] = useState({
		open: false,
		type: "error",
		message: "",
	});

	useEffect(() => {
		setBreadcrumbs([createBreadcrumbData(`/siniestros`, `Siniestros`)]);
	}, [setBreadcrumbs]);

	useEffect(() => {
		dispatch(allActions.siniestroActions.getAllSiniestros());
		dispatch({ type: CREATE_SINIESTRO_NUMBER_SUCCESS, payload: [] });
		dispatch({ type: GET_INDIVIDUAL_SINIESTRO_SUCCESS, payload: [] });
		if (history.location.state?.changesSaved)
			setAlert({
				open: true,
				type: "success",
				message: "Cambios guardados exitosamente",
			});
	}, [dispatch, history]);
	const handleAlertOpen = () => setAlert({ ...alert, open: !alert.open });

	const siniestros = siniestroReducer.allSiniestros?.result?.siniestros?.map(
		(siniestro) => ({
			...siniestro,
			id: Math.ceil(Math.random() * 1000),
			fechaSiniestro: siniestro.fechaSiniestro === null ? "" : new Date(siniestro.fechaSiniestro).toLocaleDateString(),
			fechaDenuncia: siniestro.fechaDenuncia === null ? "" : new Date(siniestro.fechaDenuncia).toLocaleDateString(),
		})
	);
	const orderDescSiniestros = siniestros?.reverse();

	const columns = [
		{ field: "idSiniestro", title: "ID" },
		{ field: "siniestroNro", title: "N°siniestro", sorting: false, headerStyle: { fontSize: "15px" } },
		{ field: "tipoSiniestro", title: "Tipo", cellStyle: { minWidth: 180, maxWidth: 180 } },
		{ field: "tomador", title: "Tomador", cellStyle: { minWidth: 250, maxWidth: 250 } },
		{ field: "nombreReclamante", title: "Reclamante", cellStyle: { minWidth: 300, maxWidth: 300 } },
		{ field: "fechaSiniestro", title: "Fecha siniestro" },
		{ field: "fechaDenuncia", title: "Fecha denuncia" },
		{ field: "estadoTitulo", title: "Estado" },
		{ field: "sumaAsegurada", title: "SA ($)", type: "number" },
		{ field: "montoReclamado", title: "Reserva ($)", type: "number" },
	];

	const searchIndividualSiniestro = (idSiniestro) => {
		window.location.assign(`/siniestro?id=${idSiniestro}`)
	};

	function exportToExcel(data, fileName) {
		const filteredData = data.map(row => {
			const filteredRow = {};
			Object.keys(row).forEach(key => {
				if (key !== 'tableData') {
					filteredRow[key] = row[key];
				}
			});
			return filteredRow;
		});
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(filteredData);
		XLSX.utils.book_append_sheet(workbook, worksheet, "Siniestros");
		XLSX.writeFile(workbook, fileName);
	}

	return (
		<Grid>
			{!orderDescSiniestros?.length ?
				<Grid className={classes.ovalContainer}>
					<Oval
						height={80}
						width={80}
						color="#1467A1"
						wrapperStyle={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%"
						}}
						secondaryColor="#1467A1"
					/>
				</Grid> :
				<Grid>
					<MaterialTable
						style={{ width: "100%" }}
						columns={columns}
						data={orderDescSiniestros}
						title=""
						icons={{
							Search: () => <Search style={{ fontSize: "18px" }} />,
							Filter: 'none'
						}}
						actions={[
							{
								icon: "search",
								iconProps: { style: { fontSize: "20px", color: "blue" } },
								tooltip: "visualizar",
								onClick: (_, rowData) => { searchIndividualSiniestro(rowData.idSiniestro) },
							},
							{
								icon: ({ color }) => <FcDownload style={{ color }} />,
								tooltip: 'Descargar Excel',
								isFreeAction: true,
								onClick: () => exportToExcel(orderDescSiniestros, 'Siniestros.xlsx')
							}
						]}
						options={{
							filtering: true,
							headerStyle: { whiteSpace: 'nowrap' },
							paginationType: "stepped",
							columnsButton: true,
						}}
					/>
				</Grid>}
			<Snackbar
				open={alert.open}
				autoHideDuration={6000}
				onClose={handleAlertOpen}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert onClose={handleAlertOpen} severity={alert.type}>
					{alert.message}
				</Alert>
			</Snackbar>
		</Grid>
	);
}

Siniestros.propTypes = {};

export default Siniestros;


