import makeStyles from "@material-ui/core/styles/makeStyles";
import styled from "styled-components";

export const FormContainerStyled = styled.form`
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media only screen and (max-width: 480px) {
		width: 90%;
	}
`;

export const useStyles = makeStyles(() => ({
    titleContainer: {
        fontWeight: "700"
    },
    docContainer: {
        padding: "2% 0% 2% 0%",
    },
    docItemNotSelected: {
        maxWidth: "100%",
        fontWeight: "100",
        display: "flex",
        marginTop: "8px",
    },
    crossIcon: {
        margin: "0.5% 2% 0% 0%",
        fontSize: "16px",
        color: "red",
        fontWeight: "700"
    },
    dialogActionsContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 10px 0px",
        "& > p": {
            fontWeight: "100",
            marginTop: "1%"
        }
    },
    messageContainer: {
        marginTop: "30px",
    }
}));