import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, Typography } from "@material-ui/core";
import { gte, isEmpty } from "lodash";
import notInfoFace from "../../../../assets/images/noInfoFace.svg";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";
import { useEffect } from "react";
import { CATEGORIZE_ARCHIVO_SUCCESS, DELETE_ARCHIVO_SUCCESS } from "../../../../actions/types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 1300,
		backgroundColor: theme.palette.background.paper,
		border: "3px solid #ccc",
		borderRadius: "4px",
		padding: "10px",
		maxHeight: 300,
		overflowY: "scroll",
		marginTop: "50px"
	},
	listItem: {
		"& .MuiTypography-root": {
			fontSize: "15px !important",
		},
	},
	secondaryActionContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "400px",
	},
	deleteIconButton: {
		marginLeft: "15px",
	},
	deleteIcon: {
		fontSize: "20px",
	},
	noInfoContainer: {
		width: "100%",
		padding: "90px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& img": {
			marginBottom: "20px",
			width: "90px",
			height: "90px",
		},
	},
	noInfoText: {
		fontSize: "16.5px !important",
	},
}));

const FilesList = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { files, isEverythingDisabled, handleToggle, checked } = props;
	const archivoTipoReducer = useSelector((state) => state.archivoTipoReducer);
	const archivoReducer = useSelector((state) => state.archivoReducer);
	const siniestroId = new URLSearchParams(window.location.search).get("id");
	const [archivoUpdating, setArchivoUpdating] = useState(false);
	const nothingHere = (
		<div className={classes.noInfoContainer}>
			<img src={notInfoFace} alt="No info" />
			<Typography variant="body1" className={classes.noInfoText}>
				Nada por aqui
			</Typography>
		</div>
	);

	useEffect(() => {
		if (!isEmpty(archivoReducer.archivoCategorized)) {
			dispatch(allActions.archivoActions.getAllFilesBySiniestro(siniestroId));
			dispatch({ type: CATEGORIZE_ARCHIVO_SUCCESS, payload: [] });
			setArchivoUpdating(false);
		}

		if (!isEmpty(archivoReducer.archivoDeleted)) {
			dispatch(allActions.archivoActions.getAllFilesBySiniestro(siniestroId));
			dispatch({ type: DELETE_ARCHIVO_SUCCESS, payload: [] });
			setArchivoUpdating(false);
		}
	}, [archivoReducer, dispatch, siniestroId]);

	return (
		<>
			<List className={classes.root}>
				{!isEmpty(files)
					? files.map((value) => {
						const labelId = `checkbox-list-label-${value}`;
						const archivoTitulo = archivoTipoReducer.archivos?.find(
							(ar) => ar?.archivoTipo_id === value?.archivoTipo_id)?.titulo;
						return (
							<ListItem
								key={value.archivo_id}
								className={classes.listItem}
								dense
							>
								<ListItemButton role={undefined} onClick={handleToggle(value)} dense>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={checked.indexOf(value.archivo_id) !== -1}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText primary={archivoTitulo ? archivoTitulo : value.nombre} />
								</ListItemButton>
								<ListItemSecondaryAction className={classes.secondaryActionContainer}>
									{!isEverythingDisabled ? (
										<></>
									) : (
										<ListItemText
											secondary={archivoTitulo ? gte(archivoTitulo.length, 50) ?
												archivoTitulo?.slice(0, 50) + "..." : archivoTitulo : "Sin categoría"}
										/>
									)}
									<a
										href={`https://storage.googleapis.com/siw-siniestros-test/${value.ruta}/${value.nombre}`}
										target="_blank"
										rel="noreferrer"
									>
										<IconButton>
											<VisibilityIcon className={classes.deleteIcon} />
										</IconButton>
									</a>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})
					: nothingHere}
			</List>
		</>
	);
};

export default FilesList;
