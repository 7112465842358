import React, {useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import allActions from "../../../actions";
export default function DialogEstudio (props) {
    const {open, setOpen,load,setLoad} = props
    const [data,setData] = useState({})
    const dispatch = useDispatch();
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleChange = (e) => {
      setData ({
        ...data,
        [e.target.name] : e.target.value
      })
    }
    const handleCreate = () => {
     dispatch(allActions.estudiosActions.newEstudio(data))
     setLoad(!load)
      setOpen(false)
      
    }
    return (
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nuevo estudio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Completa los siguientes campos para agregar un nuevo estudio.
          </DialogContentText>
          <TextField
            onChange = {handleChange}
            autoFocus
            margin="dense"
            name="titulo"
            label="Nombre del estudio"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange = {handleChange}
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange = {handleChange}
            margin="dense"
            name="telefono"
            label="Teléfono"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange = {handleChange}
            margin="dense"
            name="direccion"
            label="Dirección"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button disabled={!data.titulo || !data.email || !data.telefono || !data.direccion} onClick={handleCreate}>Crear</Button>
        </DialogActions>
      </Dialog>
    )
}
