import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	listParametrizacionContainer: {
		listStyle: "none",
		display: "flex",
		padding: 0,
		width: "100%",
	},
	linkMenu: {
		fontSize: "15px",
		padding: "0",
		color: "#333",
		fontFamily: "Open Sans, sans-serif",
	},
	navItem: {
		padding: "10px 20px",
		cursor: "pointer",
		"&:hover": {
			background: "#eee",
		},
		display: "flex",
		margin: "0",
	},
}));

export default useStyles;
