import {
	SINIESTRO_RESERVA_START,
	SINIESTRO_RESERVA_SUCCESS,
	SINIESTRO_RESERVA_ERROR,
} from "../actions/types";

let initialState = {
	reservaUpdated: [],
	isLoading: false,
	error: null,
};

const siniestroReducer = (state = initialState, action) => {
	switch (action.type) {
		case SINIESTRO_RESERVA_START:
			return {
				...state,
				isLoading: true,
			};
		case SINIESTRO_RESERVA_SUCCESS:
			return {
				...state,
				reservaUpdated: action.payload,
				isLoading: false,
			};
		case SINIESTRO_RESERVA_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default siniestroReducer;
