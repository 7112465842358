import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	liquidateContainer: {
		maxWidth: "100%",
		marginTop: "5px"
	},
	idSubtitle: {
		color: "gray",
		fontSize: "16.5px",
	},
	buttonLiquidar: {
		marginLeft: "36%",
	},
	initialInfo: {
		display: "flex",
		justifyContent: "space-between",
	},
	inputEditable: {
		width: "70%",
		outline: "none",
		borderRight: "0",
		borderLeft: "0",
		borderTop: "0",
	},
	firstFormCommunicationPaper: {
		marginTop: "20px",
	},
	divider: {
		width: "100%",
		margin: "20px 0",
		height: "2px",
	},
	typographyData: {
		fontSize: "17px !important",
		marginBottom: "13px",
	},
	link: {
		fontSize: "15px",
		cursor: "pointer",
	},
	amountsContainer: {
		marginTop: "20px",
		display: "flex",
	},
	buttonFinish: {
		marginTop: "40px",
		backgroundColor: "#a8323d !important",
		width: "100%",
	},
	containsMany: {
		display: "flex",
		alignItems: "flex-end",
		paddingBottom: "6%"
	},
	standbyLink: {
		display: "flex",
		justifyContent: "flex-end",
		width: "50%",
		fontSize: "18px"
	},
	reactivateButton: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "40%",
		width: "50%",
	},
	submitButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
	liquidacionText: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#428642",
	},
	bajaText: {
		color: "#ca5353",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	procesoDePagoText: {
		color: "#ff9800",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	iconsEstadosContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginRight: "12px",
	},
	iconsEstados: {
		fontSize: "20px",
	},
	fechas: {
		marginBottom: "15px",
		width: "100%"
	},
	containerFechas: {
		display: "flex",
		justifyContent: "space-around",
		alignItems: "center"
	},
	tipoSiniestroContainer: {
		marginTop: "15px",
	},
	typography: {
		fontSize: "18px !important",
	}
}));
