import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { CircularProgress } from "@material-ui/core";

const AlertDialog = (props) => {
	const { title, text, isOpen, handleOpen, onClickAccept, isLoading } = props;
	const classes = useStyles();

	return (
		<Dialog open={isOpen} onClose={handleOpen} data-testid="dialog">
			<DialogTitle id="alert-dialog-title" className={classes.title}>
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleOpen}
					className={classes.button}
					color="primary"
					disabled={isLoading}
				>
					Cancelar
				</Button>
				<Button
					onClick={onClickAccept}
					className={classes.button}
					color="primary"
					autoFocus
					disabled={isLoading}
				>
					{isLoading ? <CircularProgress size={20} /> : "Aceptar"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

AlertDialog.propTypes = {
	isOpen: PropTypes.bool,
	title: PropTypes.string,
	text: PropTypes.string,
	handleOpen: PropTypes.func,
	onClickAccept: PropTypes.func,
};

export default AlertDialog;
