import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    filterContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    filter: {
        width: "720px",
        height: "200px",
    },
    infoContainer: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginTop: "35px",
        marginBottom: "50px",
    },
    info: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "320px",
        height: "150px",
        margin: "15px",
        padding: "15px",
        minWidth: "150px",
        '&:hover': {
            backgroundColor: "#DBDBDB"
        }
    },
    gridDate: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        margin: "25px 20px 25px",
    },
    gridSelect: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    },
    numbers: {
        fontSize: "3.5rem",
        margin: "15px",
        color: "#006CA6"
    },
    textImportant: {
        margin: "15px",
        fontSize: "2rem",
        color: "#006CA6"
    },
    demoras: {
        fontSize: "3rem",
        color: "#006CA6",
        cursor: "pointer",
    },
    date: {
        width: "350px",
        height: "15px",
    },
    filterButton: {
        width: "120px",
        height: "40px",
    },
    tableTittle: {
        fontSize: "15px",
        fontWeight: "bold",
        color: "white"
    },
    tableBody: {
        fontSize: "12px"
    },
    tableNumber: {
        fontWeight: "bold",
        fontSize: "12px"
    },
    gridTables: {
        display: "flex",
        justifyContent: "space-around"
    },
    gridTitle: {
        display: "flex",
        flexDirection: "column !important",
        alignItems: "center",
        marginBottom: "30px"
    },
    indTable: {
        maxHeight: "250px",
        minHeight: "250px",
        maxWidth: "500px",
        minWidth: "500px",
        margin: "30px",
    },
    tableCompanyContainer: {
        maxHeight: "560px",
        minHeight: "560px",
        margin: "30px",
    },
    title: {
        fontSize: "3rem",
        margin: "30px",
        color: "#006CA6"
    },
    subtitle: {
        fontSize: "2.5rem",
        margin: "20px",
        color: "#006CA6"
    },
    colorTitle: {
        backgroundColor: "#006CA6",
    },
    compañia: {
        fontSize: "2rem",
        margin: "10px",
        color: "#006CA6",
    },
    poliza: {
        fontSize: "15px",
        margin: "10px",
        color: "#006CA6",
        height: "40%",
        padding: "1.5% 0% 1% 1.5%",
        fontWeight: 700
    },
    dataPolizaContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0% 0% 2.5%"
    },
    dataPoliza: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "180px",
        height: "80px",
        margin: "0px 15px 5px",
        backgroundColor: "#006CA6",
        borderRadius: "5px",
        color: "white",
        fontWeight: 700,
        fontSize: "21px"
    },
    cursor: {
        cursor: "pointer",
        cursor: "hand"
    },
    graphics: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    }
});


export default useStyles;
