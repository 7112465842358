import {
  UPDATE_DENUNCIA_INFO_START,
  UPDATE_DENUNCIA_INFO_SUCCESS,
  UPDATE_DENUNCIA_INFO_ERROR,
} from "../actions/types";

let initialState = {
  denunciaUpdated: [],
  isLoading: false,
  error: null,
};

const denunciaReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DENUNCIA_INFO_START:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_DENUNCIA_INFO_SUCCESS:
      return {
        ...state,
        jwtdenunciaUpdated: action.payload,
        isLoading: false,
      };
    case UPDATE_DENUNCIA_INFO_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default denunciaReducer;
