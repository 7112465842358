import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { createBreadcrumbData } from "../../common/constants";

const Parametrizacion = (props) => {
	const { setBreadcrumbs } = props;
	const classes = useStyles();

	useEffect(() => {
		setBreadcrumbs([
			createBreadcrumbData(`/parametrizacion`, `Parametrización`),
		]);
	}, [setBreadcrumbs]);

	const menuParametizacion = (
		<ul className={classes.listParametrizacionContainer}>
			<Link className={classes.linkMenu} to="/parametrizacion/documentacion">
				<li className={classes.navItem}>Documentación</li>
			</Link>
			<Link className={classes.linkMenu} to="/parametrizacion/plantillas">
				<li className={classes.navItem}>Plantillas</li>
			</Link>
		</ul>
	);

	return (
		<Grid>
			<div>{menuParametizacion}</div>
		</Grid>
	);
};

export default Parametrizacion;
