import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { createBreadcrumbData } from "../../common/constants";
import allActions from "../../actions";
import EmailsDisplay from "./components/emailsDisplay";
import SendEmail from "./components/SendEmail";

function Mails(props) {
	const { setBreadcrumbs } = props;
	const dispatch = useDispatch();
	const mailsReducer = useSelector((state) => state.mailsReducer);
 	const threadId = new URLSearchParams(window.location.search).get(
		"threadId"
	); 
	const emailId = new URLSearchParams(window.location.search).get("emailId"); 
	
	const siniestroId = useSelector((state) => state.createSiniestroReducer?.individualSiniestro?.result?.siniestro?.idSiniestro)

	const messages = mailsReducer.thread?.messages;

	useEffect(() => {
		setBreadcrumbs([
			createBreadcrumbData(
				`/siniestro?id=${siniestroId}`,
			   `Siniestro #${siniestroId}`
		   ),
			createBreadcrumbData(`/mails`, `Mails`),
			
		]);
			dispatch(allActions.emailsActions.getThread(threadId));
	}, [setBreadcrumbs, dispatch, threadId]);

	return (
		<Grid>
			{threadId ? ( 
				<EmailsDisplay messages={messages} emailId={emailId} siniestroId={siniestroId}/>
		) : (
				<SendEmail siniestroId={siniestroId} />
			)} 
		</Grid>
	);
}

export default Mails;
