import React from "react";
import PropTypes from "prop-types";
import FormTextBootstrap from "react-bootstrap/FormText";

function FormText(props) {
  const { text } = props;
  return (
    <FormTextBootstrap muted data-testid="form-text">
      {text}
    </FormTextBootstrap>
  );
}

FormText.propTypes = {
  text: PropTypes.string,
};

export default FormText;
