import React from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import Dialog from "@material-ui/core/Dialog";
import { Grid, Button } from "@material-ui/core";
import allActions from "../../actions";

const DialogStandby = (props) => {
    const { openStandby, onClose } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const siniestroId = new URLSearchParams(window.location.search).get("id");

    const onStandby = () => {
        dispatch(
            allActions.siniestroActions.changeEstado(
                siniestroId, { idEstado: 7 })
        )
    }

    return (
        <Dialog
            open={openStandby}
            fullWidth={true}
            maxWidth={'xs'}>
            <Grid className={classes.textContainer}>
                <span>¿Está seguro que desea dejar el siniestro en standby?</span>
            </Grid>
            <Grid className={classes.buttons}>
                <Button
                    onClick={onStandby}
                    color="primary"
                >
                    Aceptar
                </Button>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    Cerrar
                </Button>
            </Grid>
        </Dialog>
    )
}

export default DialogStandby;