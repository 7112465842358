import {
    SEND_TEMPLATE_START,
    SEND_TEMPLATE_SUCCESS,
    SEND_TEMPLATE_ERROR,
    GET_TEMPLATE_START,
    GET_TEMPLATE_SUCCESS,
    GET_TEMPLATE_ERROR,
    DELETE_TEMPLATE_START,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_ERROR,
    UP_DATE_TEMPLATE_START,
    UP_DATE_TEMPLATE_SUCCESS,
    UP_DATE_TEMPLATE_ERROR,
    GET_TEMPLATES_START,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_ERROR,
    GET_ACTION_START,
    GET_ACTION_SUCCESS,
    GET_ACTION_ERROR,
    GET_VARIABLES_START,
    GET_VARIABLES_SUCCESS,
    GET_VARIABLES_ERROR,
    GET_TEMPLATE_DECODED_START,
    GET_TEMPLATE_DECODED_SUCCESS,
    GET_TEMPLATE_DECODED_ERROR,
} from "./types";
import getLoggedClient from "./getLoggedClient";

const sendTemplate = (data) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: SEND_TEMPLATE_START });
    client
        .post('/plantilla', data)
        .then((response) => {
            dispatch({
                type: SEND_TEMPLATE_SUCCESS,
                payload: response.data,
            });
            window.location.reload(true)
        })
        .catch((err) => {
            dispatch({ type: SEND_TEMPLATE_ERROR, payload: err });
        });
};

const getTemplate = (idTemplate) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: GET_TEMPLATE_START });
    client
        .get(`/plantilla/${idTemplate}`)
        .then((response) => {
            dispatch({
                type: GET_TEMPLATE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch({ type: GET_TEMPLATE_ERROR, payload: err });
        });
};

const deleteTemplate = (plantilla_id) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: DELETE_TEMPLATE_START });
    client
        .delete(`/plantilla/${plantilla_id}`)
        .then((response) => {
            dispatch({
                type: DELETE_TEMPLATE_SUCCESS,
                payload: response.data,
            });
            window.location.reload(true)
        })
        .catch((err) => {
            dispatch({ type: DELETE_TEMPLATE_ERROR, payload: err });
        });
};

const upDateTemplate = (idTemplate, data) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: UP_DATE_TEMPLATE_START });
    client
        .put(`/plantilla/${idTemplate}`, data)
        .then((response) => {
            dispatch({
                type: UP_DATE_TEMPLATE_SUCCESS,
                payload: response.data,
            });
            window.location.reload(true)
        })
        .catch((err) => {
            dispatch({ type: UP_DATE_TEMPLATE_ERROR, payload: err });
        });
};

const getAllTemplates = (data) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: GET_TEMPLATES_START });
    client
        .get('/plantillas', data)
        .then((response) => {
            dispatch({
                type: GET_TEMPLATES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch({ type: GET_TEMPLATES_ERROR, payload: err });
        });
};

const getActions = (data) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: GET_ACTION_START });
    client
        .get('/plantilla/acciones', data)
        .then((response) => {
            dispatch({
                type: GET_ACTION_SUCCESS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch({ type: GET_ACTION_ERROR, payload: err });
        });
};

const getVariables = (data) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: GET_VARIABLES_START });
    client
        .get('/plantilla/variables', data)
        .then((response) => {
            dispatch({
                type: GET_VARIABLES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch({ type: GET_VARIABLES_ERROR, payload: err });
        });
};

const getTemplateDecoded = (idAccion, siniestroId) => async (dispatch) => {
    const client = getLoggedClient();
    dispatch({ type: GET_TEMPLATE_DECODED_START });
    client
        .get(`/plantillaDecode/${idAccion}/${siniestroId}`)
        .then((response) => {
            dispatch({
                type: GET_TEMPLATE_DECODED_SUCCESS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch({ type: GET_TEMPLATE_DECODED_ERROR, payload: err });
        });
};

const templatesActions = {
    sendTemplate,
    getTemplate,
    deleteTemplate,
    upDateTemplate,
    getAllTemplates,
    getActions,
    getVariables,
    getTemplateDecoded
};

export default templatesActions;