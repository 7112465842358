import React, { useEffect, useState } from "react";
import {
	CircularProgress,
	Collapse,
	Grid,
	IconButton,
	Link,
	Paper,
	Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import { getItemSessionStorage } from "../../common/constants";
import Button from "../Button";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "../Dialog";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";
import { gte, isEmpty, isEqual } from "lodash";
import notInfoFace from "../../assets/images/noInfoFace.svg";
import DialogComment from "./components/Dialog";
import clsx from "clsx";

const Comments = (props) => {
	const { idSiniestro } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const commentsReducer = useSelector((state) => state.commentsReducer);
	const [isEnable, setIsEnable] = useState(false);
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const [newComment, setNewComment] = useState("");
	const [callDispatch, setCallDispatch] = useState(true);
	const [allComments, setAllComments] = useState([]);
	const [dialogComment, setDialogComment] = useState({
		title: "",
		subtitle: "",
		open: false,
		text: "",
	});

	const handleOpenDialog = () => setIsOpenDialog(!isOpenDialog);

	const handleDeleteComment = () => {
		setIsEnable(false);
		setIsOpenDialog(false);
		setNewComment("");
	};

	const handleNewComment = () => {
		dispatch(
			allActions.commentsActions.addNewComment(idSiniestro, {
				comentario: newComment,
				useri_id: getItemSessionStorage("idUsuario"),
			})
		);
	};

	useEffect(() => {
		if (callDispatch) {
			dispatch(
				allActions.commentsActions.getCommentsBySiniestro(idSiniestro)
			);
			setCallDispatch(false);
		}

		if (
			commentsReducer.createdComment?.status === "success" &&
			!commentsReducer.isLoading
		) {
			setAllComments(commentsReducer.createdComment.result);
			handleDeleteComment();
		}
	}, [dispatch, idSiniestro, callDispatch, commentsReducer]);

	useEffect(() => {
		if (
			commentsReducer.allComments?.status === "success" &&
			isEmpty(commentsReducer.createdComment)
		) {
			setAllComments(commentsReducer.allComments.result);
		}
	}, [commentsReducer]);

	const nothingHere = (
		<div className={classes.noInfoContainer}>
			<img src={notInfoFace} alt="No info" />
			<Typography variant="body1" className={classes.noInfoText}>
				Nada por aqui
			</Typography>
		</div>
	);

	const handleSeeMore = (item) => () => {
		setDialogComment({
			text: item.comentario,
			subtitle: new Date(item.fecha).toLocaleDateString(),
			open: true,
			title: item.nombreUsuario,
		});
	};

	const seeMore = (item) => (
		<>
			...{" "}
			<Link className={classes.seeMoreLink} onClick={handleSeeMore(item)}>
				Ver más
			</Link>
		</>
	);

	return (
		<Grid container className={classes.container} spacing={3}>
			<Grid item xs={12} className={classes.buttonNewComment}>
				<Button
					text={"Nuevo comentario"}
					onClick={() => setIsEnable(true)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Collapse in={isEnable}>
					<Paper elevation={3} className={classes.paper}>
						<Typography variant="h5" className={classes.headerText}>
							<strong className={classes.userText}>
								{getItemSessionStorage("nombreUsuario")}
							</strong>{" "}
							-
							<span className={classes.dateText}>
								{new Date().toLocaleDateString()}{" "}
							</span>
						</Typography>
						<Typography>
							<textarea
								autoFocus
								className={classes.inputNewComment}
								value={newComment}
								onChange={(e) => setNewComment(e.target.value)}
								rows={5}
								disabled={commentsReducer.isLoading}
								onKeyPress={(e) =>
									newComment &&
									isEqual(e.key, "Enter") &&
									e.ctrlKey &&
									handleNewComment()
								}
							/>
						</Typography>

						<div className={classes.iconsContainer}>
							<IconButton
								onClick={handleOpenDialog}
								disabled={commentsReducer.isLoading}
							>
								<CancelIcon
									style={{
										color:
											!commentsReducer.isLoading && "red",
										fontSize: "16px",
									}}
								/>
							</IconButton>

							{commentsReducer.isLoading ? (
								<CircularProgress size={15} />
							) : (
								<IconButton
									disabled={!newComment}
									onClick={handleNewComment}
								>
									<SendIcon
										style={{
											color: newComment && "#1976d2",
											fontSize: "16px",
										}}
									/>
								</IconButton>
							)}
						</div>
					</Paper>
				</Collapse>
			</Grid>

			{!isEmpty(allComments)
				? allComments.map((item) => {
						const isBaja = isEqual(item.tipoComentario, "baja");
						const isAlta = isEqual(item.tipoComentario, "alta");
						const isRechazo = isEqual(
							item.tipoComentario,
							"rechazo"
						);
						return (
							<Grid
								item
								xs={12}
								key={item.siniestroComentario_id}
							>
								<Collapse in={true}>
									<Paper
										elevation={3}
										className={clsx(
											classes.paper,
											isBaja ||
												(isRechazo &&
													classes.isBajaComment),
											isAlta && classes.isAltaComment
										)}
									>
										<Typography
											variant="h5"
											className={classes.headerText}
										>
											<strong
												className={classes.userText}
											>
												{item.nombreUsuario}
											</strong>{" "}
											-
											<span className={classes.dateText}>
												{new Date(
													item.fecha
												).toLocaleDateString()}
											</span>
											{(isBaja ||
												isAlta ||
												isRechazo) && (
												<span
													className={
														classes.isBajaText
													}
												>
													MOTIVO DE{" "}
													{isAlta
														? "ALTA"
														: isRechazo
														? "RECHAZO"
														: "BAJA"}
												</span>
											)}
										</Typography>
										<Typography
											style={{ lineHeight: "1.6" }}
										>
											{item.comentario.slice(0, 500)}
											{gte(item.comentario.length, 500) &&
												seeMore(item)}{" "}
										</Typography>{" "}
									</Paper>
								</Collapse>
							</Grid>
						);
				  })
				: !isEnable && nothingHere}

			<AlertDialog
				text="El progreso realizado no se guardará"
				title="¿Estás seguro de eliminar el comentario?"
				isOpen={isOpenDialog}
				handleOpen={handleOpenDialog}
				onClickAccept={handleDeleteComment}
			/>
			<DialogComment
				comment={dialogComment.text}
				open={dialogComment.open}
				titulo={dialogComment.title}
				handleOpen={() => setDialogComment(!dialogComment)}
				subtitle={dialogComment.subtitle}
			/>
		</Grid>
	);
};

export default Comments;
