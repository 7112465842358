import {
	GET_OPERATION_START,
	GET_OPERATION_SUCCESS,
	GET_OPERATION_ERROR,
	GET_SINIESTROS_START,
	GET_SINIESTROS_SUCCESS,
	GET_SINIESTROS_ERROR,
	CREATE_SINIESTRO_SUCCESS,
	CREATE_SINIESTRO_START,
	CREATE_SINIESTRO_ERROR,
	ADD_SINIESTRO_TIPO_SUCCESS,
} from "./types";
import getLoggedClient from "./getLoggedClient";

const getOperationByNumber =
	(clientId, operationNumber) => async (dispatch, getState) => {
		const client = getLoggedClient();
		dispatch({ type: GET_OPERATION_START });

		client
			.get(`/operaciones/${clientId}?search=${operationNumber}`)
			.then((response) => {
				dispatch({
					type: GET_OPERATION_SUCCESS,
					payload: response.data.result,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_OPERATION_ERROR, payload: error });
			});
	};

const getSiniestros = () => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: GET_SINIESTROS_START });

	client
		.get("/tipoSiniestro")
		.then((response) => {
			dispatch({
				type: GET_SINIESTROS_SUCCESS,
				payload: response.data.result,
			});
		})
		.catch((error) => {
			dispatch({ type: GET_SINIESTROS_ERROR, payload: error });
		});
};

const registerSiniestro = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: CREATE_SINIESTRO_START });
	client
		.post("/siniestro", data)
		.then((response) => {
			dispatch({
				type: CREATE_SINIESTRO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			dispatch({ type: CREATE_SINIESTRO_ERROR, payload: error });
		});
};

const newSiniestroTipo = (title) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: GET_SINIESTROS_START });
	client
		.post("/tipoSiniestro", { titulo: title })
		.then((response) => {
			dispatch({
				type: ADD_SINIESTRO_TIPO_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			dispatch({ type: GET_SINIESTROS_ERROR, payload: error });
		});
};

const altaSiniestroActions = {
	getOperationByNumber,
	getSiniestros,
	registerSiniestro,
	newSiniestroTipo,
};

export default altaSiniestroActions;
