import React from "react";
import RichTextEditor from "../../../../components/RichTextEditor";
import ComposedFormGroup from "../../../../components/ComposedFormGroup";
import Button from "../../../../components/Button";
import { useState } from "react";
import useStyles from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";
import { useEffect } from "react";
import FormLabel from "../../../../components/FormLabel";
import AutohideAlert from "../../../../components/AutohideAlert";
import { isEmpty, isEqual } from "lodash";
import { SEND_EMAIL_SUCCESS } from "../../../../actions/types";

function SendEmail({ siniestroId }) {
	const classes = useStyles();
	const [data, setData] = useState({
		recipient: "",
		subject: "",
		message: "",
	});

	const [alertAutohide, setAlertAutohide] = useState({
		open: false,
		type: "error",
		message: "",
		title: "",
	});

	const dispatch = useDispatch();
	const mailsReducer = useSelector((state) => state.mailsReducer);
	const handleChange = (prop, value) => {
		setData({ ...data, [prop]: value });
	};

	const handleSend = (e) => {
		e.preventDefault();
		dispatch(allActions.emailsActions.sendEmail({ ...data, siniestroId }));
	};

	const handleAlertClose = () => {
		setAlertAutohide({ ...alertAutohide, open: false });
	};

	useEffect(() => {
		if (
			!isEmpty(mailsReducer.sentEmail) &&
			isEqual(mailsReducer.sentEmail.status, 200) &&
			!mailsReducer.isLoading
		) {
			setAlertAutohide({
				open: true,
				type: "success",
				message: "Email enviado con éxito",
				title: "¡Éxito!",
			});
			setData({
				message: "",
				recipient: "",
				subject: "",
			});
			dispatch({ type: SEND_EMAIL_SUCCESS, payload: [] });
		} else if (
			!mailsReducer.isLoading &&
			(mailsReducer.error ||
				isEqual(mailsReducer?.sentEmail?.status, 400))
		) {
			setAlertAutohide({
				open: true,
				type: "error",
				message: "Hubo un error en el envío del email",
				title: "¡Error!",
			});
			dispatch({ type: SEND_EMAIL_SUCCESS, payload: [] });
		}
	}, [mailsReducer, dispatch]);

	return (
		<form onSubmit={handleSend} style={{ width: "100%" }}>
			<ComposedFormGroup
				placeholder={"Destinatario"}
				id={"recipient"}
				onChange={handleChange}
				isRequired
				value={data.recipient}
				typeInput={"email"}
			/>
			<ComposedFormGroup
				placeholder={"Asunto"}
				id={"subject"}
				onChange={handleChange}
				isRequired
				value={data.subject}
			/>
			<FormLabel text={"Mensaje"} />
			<RichTextEditor
				id={"message"}
				onChange={handleChange}
				value={data.message}
			/>
			<div className={classes.buttonSendContainer}>
				<Button
					text={"Enviar"}
					type={"submit"}
					isDisabled={!data.message}
					isLoading={mailsReducer.isLoading}
				/>
			</div>

			<AutohideAlert
				message={alertAutohide.message}
				title={alertAutohide.title}
				open={alertAutohide.open}
				type={alertAutohide.type}
				onClose={handleAlertClose}
			/>
		</form>
	);
}

export default SendEmail;
