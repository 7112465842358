import makeStyles from "@material-ui/core/styles/makeStyles";
import styled from "styled-components";

export const FormContainerStyled = styled.form`
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media only screen and (max-width: 480px) {
		width: 90%;
	}
`;

export const useStyles = makeStyles(() => ({
    titleModal: {
        "& .MuiTypography-h6": {
            fontSize: "16px !important",
        },
        fontSize: "16px !important",
    },
    inputsInfoContainer: {
        width: "48%",
        fontWeight: "700",
        "&>input": {
            border: "none",
            borderBottom: "1px solid gray",
            margin: "3% 10% 1.5% 1%",
            outline: "none",
        }
    },
    importeReclamadoContainer: {
        "&>input": {
            border: "none",
            borderBottom: "1px solid gray",
            margin: "0% 0% 0% 0.5%",
            outline: "none",
        }
    },
    divider: {
        width: "96%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        justifySelf: "center",
        margin: "40px 0px 30px 0px",
    },
    checkboxData: {
        display: "inline",
        display: "flex",
        alignItems: "center",
        fontWeight: "400",
        "&>input": {
            margin: "0% 10% 0.5% 1%",
        }
    },
    radioGroup: {
        flexDirection: "row",
        margin: "5% 0% 0% 1.5%"
    },
    inputSpecificationContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    divTransportista: {
        flexDirection: "column",
        display: "flex",
    },
    inputsSpecification: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "4%",
        "& > input": {
            width: "24%",
            marginRight: "1%",
            border: "none",
            borderBottom: "1px solid gray",
            "&:focus": {
                outline: "none",
            },
        },
    },
    dialogActionsContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 10px 0px",
        "& > p": {
            fontWeight: "100",
            marginTop: "1%"
        }
    }
}))