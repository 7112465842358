import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormContainerStyled, useStyles } from "./styles";
import FormControl from "react-bootstrap/FormControl";
import { Grid, Container } from "@material-ui/core";
import { Button } from "@mui/material"
import ComposedFormGroup from "../../components/ComposedFormGroup";
import DialogActions from "@material-ui/core/DialogActions";
import { FormHelperText } from "@material-ui/core";
import allActions from "../../actions";
import DynamicContents from "../../components/DynamicContents";
import { createBreadcrumbData } from "../../common/constants";
import SkeletonLoader from "../../components/SkeletonLoader";
import { IS_LOADED_SUCCESS } from "../../actions/types";

function TemplateActions(props) {
    const { setBreadcrumbs } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const templatesReducer = useSelector((state) => state.templatesReducer);
    const idTemplate = new URLSearchParams(window.location.search).get("id");
    const arrayActions = templatesReducer?.allActions?.result;
    const individualTemplate = templatesReducer?.individualTemplate?.result;
    const isSelectedView = templatesReducer?.isSelectedView;
    const isSelectedEdit = templatesReducer?.isSelectedEdit;
    const [dataTemplate, setDataTemplate] = useState({
        titulo: '',
        asunto: '',
        cuerpo: '',
        accion: ''
    });
    const HandleOnChangeSelect = (select) => {
        setDataTemplate({ ...dataTemplate, accion: select.target.value })
    }
    const HandleOnChangeData = (id, value) => {
        setDataTemplate({ ...dataTemplate, [id]: value })
    }
    const [helperText, setHelperText] = useState("");

    useEffect(() => {
        let href = `/parametrizacion/nueva-plantilla`;
        let label = `Nueva plantilla`;

        if (isSelectedView) {
            href = `/parametrizacion/visualizar-plantilla`;
            label = `Visualizar plantilla`;
        }
        if (isSelectedEdit) {
            href = `/parametrizacion/editar-plantilla`;
            label = `Editar plantilla`;
        }
        setBreadcrumbs([
            createBreadcrumbData(`/parametrizacion`, `Parametrización`),
            createBreadcrumbData(`/parametrizacion/plantillas`, `Plantillas`),
            createBreadcrumbData(href, label),
        ]);
    }, [setBreadcrumbs]);

    const onSubmit = (e) => {
        e.preventDefault();

        if (dataTemplate.titulo && dataTemplate.asunto && dataTemplate.cuerpo && dataTemplate.accion) {
            setHelperText("");
            if (isSelectedEdit) {
                dispatch(allActions.templatesActions.upDateTemplate(idTemplate, { ...dataTemplate }))
            } else {
                dispatch(allActions.templatesActions.sendTemplate({ ...dataTemplate }))
            }
        } else {
            setHelperText("Complete todos los campos para proseguir");
        }
    };

    useEffect(() => {
        if (isSelectedEdit) {
            setDataTemplate({
                titulo: individualTemplate?.titulo,
                asunto: individualTemplate?.asunto,
                cuerpo: individualTemplate?.cuerpo,
                accion: individualTemplate?.accion?.accionPlantilla_id
            })
        }
    }, [individualTemplate]);

    useEffect(() => {
        dispatch(allActions.templatesActions.getActions())
        dispatch(allActions.templatesActions.getVariables())
        dispatch(allActions.templatesActions.getTemplate(idTemplate))
        dispatch({ type: IS_LOADED_SUCCESS, payload: true })
    }, []);

    return (
        <FormContainerStyled onSubmit={onSubmit}>
            <Container>
                <Grid container className={classes.templateItemsContainer}>
                    <span className={classes.template}>Plantilla</span>
                    <Grid item className={classes.titleContainer}>
                        {templatesReducer.isLoading ? (
                            <SkeletonLoader numberOfSkeletons={2} />
                        ) : (
                            <ComposedFormGroup
                                isDisabled={isSelectedView ? true : false}
                                placeholder="Titulo de plantilla"
                                inputAs="input"
                                id={"titulo"}
                                onChange={HandleOnChangeData}
                                value={isSelectedView ? individualTemplate?.titulo : dataTemplate.titulo}
                            />
                        )}
                    </Grid >
                    <Grid item className={classes.subjectContainer}>
                        {templatesReducer.isLoading ? (
                            <SkeletonLoader numberOfSkeletons={2} />
                        ) : (
                            <ComposedFormGroup
                                isDisabled={isSelectedView ? true : false}
                                placeholder="Asunto"
                                inputAs="input"
                                id={"asunto"}
                                onChange={HandleOnChangeData}
                                value={isSelectedView ? individualTemplate?.asunto : dataTemplate.asunto}
                            />
                        )}
                    </Grid >
                    <Grid item className={classes.bodyContainer}>
                        {templatesReducer.isLoading ? (
                            <SkeletonLoader numberOfSkeletons={2} />
                        ) : (
                            <ComposedFormGroup
                                isDisabled={isSelectedView ? true : false}
                                placeholder="Cuerpo"
                                inputAs="textarea"
                                rows={6}
                                id={"cuerpo"}
                                onChange={HandleOnChangeData}
                                value={isSelectedView ? individualTemplate?.cuerpo : dataTemplate.cuerpo}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.selectContainer}>
                        Acciones
                        {templatesReducer.isLoading ? (
                            <SkeletonLoader numberOfSkeletons={2} />
                        ) : (
                            <FormControl
                                as="select"
                                className={classes.select}
                                value={isSelectedView ? individualTemplate?.accion?.accionPlantilla_id : dataTemplate.accion}
                                onChange={HandleOnChangeSelect}
                            >
                                <option value=''>Seleccionar</option>
                                {arrayActions?.map(accion => (
                                    <option key={accion.accion} value={accion.accionPlantilla_id}>{accion.accion}</option>
                                ))}
                            </FormControl>
                        )}
                        {helperText && (
                            <FormHelperText
                                style={{ fontSize: "13px", marginTop: "10px" }}
                                error
                            >
                                {helperText}
                            </FormHelperText>
                        )}
                    </Grid>
                    <DialogActions>
                        {!isSelectedView &&
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={onSubmit}
                            >
                                {isSelectedEdit ? 'Actualizar' : 'Guardar'}
                            </Button>}
                    </DialogActions>
                </Grid>
                <DynamicContents />
            </Container>
        </FormContainerStyled >
    )
};

export default TemplateActions;