import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from '@mui/material';
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	basicStyles: {
		padding: "20px",
	},
}));

function SkeletonLoader({ numberOfSkeletons, customStyles }) {
	const classes = useStyles();
	return (
		<Grid
			container
			spacing={3}
			className={clsx(classes.basicStyles, customStyles)}
		>
			{[...Array(numberOfSkeletons).keys()].map((id) => (
				<Grid key={id} item style={{ margin: "30px 0" }} xs={12} md={6}>
					<Skeleton />
				</Grid>
			))}
		</Grid>
	);
}

export default SkeletonLoader;
