import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
	container: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	table: {
		height: "70vh",
	},
	buttonAdd: {
		fontSize: "13px !important",
		color: "#2930FF",
		margin: "15px 8px",
		padding: "0px 10px",
		borderColor: "#2930FF",
		borderRadius: "100px",
		textTransform: "none",
	},
	tableHead: {
		fontSize: "15px",
		paddingRight: "0",
		fontWeight: "700",
	},
	addIcon: {
		marginRight: "6px",
	},
	tableCellRowItem: {
		fontSize: "14px",
	},
	tableCellRowDense: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	inputEditable: {
		width: "100%",
		outline: "none",
		"& .MuiFormLabel-root": {
			fontSize: "11.5px !important",
		},
	},
	inputContainer: {
		display: "flex",
		alignItems: "center",
		padding: "10px 0",
	},
	cancelIcon: {
		marginLeft: "20px",
		fontSize: "16px",
		color: "red",
		cursor: "pointer",
	},
	checkIcon: {
		color: "green",
	},
	checkIconDisabled: {
		color: "rgba(0, 0, 0, 0.26)",
		cursor: "inherit",
	},
	switchContainer: {
		display: "flex",
		justifyContent: "flex-start",
		width: "100%",
	},
	tableHeader: {
		background: "#f8f8f8",
	},
});

export default useStyles;
