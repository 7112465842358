import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    titleContainer: {
        marginTop: "0.5%",
        fontWeight: "700"
    },
    docContainer: {
        padding: "2% 0% 2% 0%",
        display: "flex",
    },
    docItemContainer: {
        width: "50%"
    },
    crossIcon: {
        margin: "0.5% 2% 0% 0%",
        fontSize: "16px",
        color: "red",
        fontWeight: "700"
    },
    visibilityIcon: {
        fontSize: "20px",
        marginRight: "8px",
    },
    docReqItem: {
        display: "flex",
    },
    docItemCheckbox: {
        fontWeight: "100",
        marginTop: "10px",
        "&>input": {
            marginRight: "5px"
        }
    },
    docItemNotSelected: {
        maxWidth: "100%",
        fontWeight: "100",
        display: "flex",
        marginTop: "10px",
    },
    dialogActionsContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 10px 0px"
    }
}));