import makeStyles from "@material-ui/core/styles/makeStyles";
import { fontWeight } from "@mui/system";

const useStyles = makeStyles((theme) => ({
	text: {
		padding: theme.spacing(2, 2, 0),
	},
	paper: {
		paddingBottom: 50,
		maxHeight: "450px",
		overflowY: "scroll",
		overflowX: "hidden",
	},
	list: {
		marginBottom: theme.spacing(2),
		paddingBottom: "30px",
		maxHeight: "100%",
	},
	subheader: {
		backgroundColor: theme.palette.background.paper,
		fontSize: "14px",
	},
	appBar: {
		top: "auto",
		bottom: 0,
		position: "static !important",
		background: "#337ab7",
	},
	grow: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontWeight: "bold",
	},
	fabButton: {
		position: "absolute",
		zIndex: 1,
		top: -30,
		left: 0,
		right: 0,
		margin: "0 auto",
		background: "#a8323d",
	},
	titleContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "20px 14px",
	},
	closeButton: {
		marginRight: "10px",
		padding: 0,
	},
	buttonMessage: {
		backgroundColor: "#a8323d",
	},
	closeIcon: {
		fontSize: "20px",
	},
	noInfoContainer: {
		width: "100%",
		padding: "90px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& img": {
			marginBottom: "20px",
			width: "90px",
			height: "90px",
		},
	},
	noInfoText: {
		fontSize: "16.5px !important",
	},
}));

export default useStyles;
