import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ComposedFormGroup from "../../components/ComposedFormGroup";
import { Grid, Typography } from "@material-ui/core";
import { map } from "lodash";
import ModalForm from "../ModalForm";
import { AiOutlineClose } from "react-icons/ai";
import { IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FormHelperText } from "@material-ui/core";

const ModalReport = (props) => {
    const { open, setOpen, docReq, modalFormData, setModalFormData } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const templatesReducer = useSelector((state) => state.templatesReducer);
    const templateSiniestro = templatesReducer.templateSiniestro.result;
    const archivoReducer = useSelector((state) => state.archivoReducer);
    const siniestroId = new URLSearchParams(window.location.search).get("id");
    const allFiles = archivoReducer.allFiles;
    const [helperText, setHelperText] = useState("");
    const [modalForm, setModalForm] = useState(false);
    const [docSelected, setDocSelected] = useState([]);
    const [formDataReport, setFormDataReport] = useState({
        siniestroId: siniestroId,
        recipient: '',
        cc: '',
        subject: '',
        message: '',
        files: [],
    });

    useEffect(() => {
        setDocSelected(docReq?.filter((doc) => doc.isSelected == false ? false : (doc.esta === 1 || doc.isSelected == true)))
    }, [docReq]);

    useEffect(() => {
        const idAllFiles = [];
        map(docSelected, (doc) => {
            const idFilesSelected = allFiles.find(file => file.archivoTipo_id === doc.archivoTipo_id)
            idAllFiles.push(idFilesSelected)
        });
        const archivoIdFilter = idAllFiles.map((item) => item.archivo_id)
        if (formDataReport) {
            setFormDataReport({
                ...formDataReport,
                subject: templateSiniestro?.asunto,
                message: templateSiniestro?.cuerpo,
                files: archivoIdFilter,
            });
        };
    }, [templateSiniestro]);

    const handleClose = () => {
        setOpen(!open);
    };
    const HandleOnChange = (id, value) => {
        setFormDataReport({ ...formDataReport, [id]: value })
    };
    const HandleOnChangeCheckbox = (event) => {
        const newDocReq = docSelected.map(doc => {
            if (doc.archivoTipo_id == event.target.value) {
                doc.isSelected = doc.hasOwnProperty('isSelected') ? !doc.isSelected : false;
            }
            return doc;
        });
        setDocSelected(newDocReq)
    };
    const docNotSelected = docReq?.filter((doc) => (doc.esta === 0))
    const isRequired = (item) => {
        if (item.hasOwnProperty('isSelected')) {
            return item.isSelected;
        };
        return item.esta === 1 ? true : false;
    };

    const openModalForm = () => {
        if (formDataReport.recipient && formDataReport.cc && formDataReport.subject && formDataReport.message) {
            setHelperText("");
            setModalForm(true);
        } else {
            setHelperText("Complete todos los campos para proseguir");
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogContent>
                <Grid>
                    <ComposedFormGroup
                        placeholder={"Destinatario"}
                        id={"recipient"}
                        value={formDataReport.recipient}
                        onChange={HandleOnChange}
                    />
                    <ComposedFormGroup
                        placeholder={"Con Copia (CC)"}
                        id={"cc"}
                        value={formDataReport.cc}
                        onChange={HandleOnChange}
                    />
                    <ComposedFormGroup
                        placeholder={"Asunto"}
                        id={"subject"}
                        value={formDataReport.subject || templateSiniestro?.asunto}
                        onChange={HandleOnChange}
                    />
                    <ComposedFormGroup
                        placeholder={"Cuerpo"}
                        id={"message"}
                        inputAs="textarea"
                        rows={6}
                        value={formDataReport.message || templateSiniestro?.cuerpo}
                        onChange={HandleOnChange}
                    />
                </Grid>
                <Typography
                    className={classes.titleContainer}
                    variant="h5">
                    Documentación - Tipo de siniestro
                </Typography>
                <Grid className={classes.docContainer}>
                    <Grid className={classes.docItemContainer}>
                        {map(docSelected, (doc, index) => {
                            const fileFinded = allFiles.find(file => file.archivoTipo_id === doc.archivoTipo_id);
                            return (
                                <Grid className={classes.docReqItem}>
                                    <a
                                        href={fileFinded?.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton>
                                            <VisibilityIcon className={classes.visibilityIcon} />
                                        </IconButton>
                                    </a>
                                    <label key={index} className={classes.docItemCheckbox}>
                                        <input
                                            key={index + doc.archivoTipo_id}
                                            type="checkbox"
                                            value={doc.archivoTipo_id}
                                            checked={isRequired(doc)}
                                            onChange={HandleOnChangeCheckbox}
                                        />
                                        {doc.titulo}
                                    </label>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid className={classes.docItemContainer}>
                        {map(docNotSelected, (item) => (
                            <Grid
                                item
                                key={item.archivoTipo_id}
                                xs={12}
                                md={6}
                                className={classes.docItemNotSelected}
                            >
                                <AiOutlineClose className={classes.crossIcon} />{" "}
                                {item.titulo}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActionsContainer}>
                <Grid>
                    {helperText && (
                        <FormHelperText
                            style={{ fontSize: "13px" }}
                            error
                        >
                            {helperText}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid>
                    <Button
                        onClick={handleClose}
                        color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => openModalForm()}
                        color="primary"
                    >
                        Siguiente
                    </Button>
                </Grid>
            </DialogActions>
            <ModalForm
                formDataReport={formDataReport}
                setFormDataReport={setFormDataReport}
                open={modalForm}
                setOpen={setModalForm}
                modalFormData={modalFormData}
                setModalFormData={setModalFormData}
            />
        </Dialog>
    )
}

export default ModalReport;