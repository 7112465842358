import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    chat: {
        paddingLeft: "20px",
        paddingBottom: "20px"
    },
    subtitle: {
        marginTop: "15px",
    },
    input: {
        marginBottom: "15px"
    },
    buttons: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-evenly",
        marginBottom: "15px"
    },
    btnSubirArch: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        margin: "20px 0 20px",
    },
    btnGuardar: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        margin: "50px 0 20px",
    },
    buttonRequired: {
        backgroundColor: "red"
    },
    errorContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        padding: "5% 5% 10% 0%",
        color: "red"
    }
}));
