import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import ComposedFormGroup from "../../../../components/ComposedFormGroup";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";
import { CircularProgress, Button } from "@mui/material";

function SecondForm(props) {
	const { isEverythingDisabled, setModalFormData, modalFormData } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const createSiniestroReducer = useSelector(
		(state) => state.createSiniestroReducer
	);
	const denunciaReducer = useSelector((state) => state.denunciaReducer);
	const denunciaData = denunciaReducer.denunciaUpdated?.result;
	const siniestroId = new URLSearchParams(window.location.search).get("id");

	const siniestroData =
		createSiniestroReducer.individualSiniestro?.result?.siniestro || "";

	const [data, setData] = useState({
		transportista: "",
		contratador: "",
		lugarSiniestro: "",
		descripcionHecho: "",
		datosInspeccion: "",
	});

	useEffect(() => {
		setModalFormData({
			...modalFormData,
			transportista: data.transportista,
			lugarSiniestro: data.lugarSiniestro,
			descripcionHecho: data.descripcionHecho,
		});
	}, [data.transportista, data.lugarSiniestro, data.descripcionHecho]);

	useEffect(() => {
		setData({
			transportista:
				denunciaData?.lugarSiniestro || siniestroData.transportista,
			contratador: denunciaData?.contratador || siniestroData.contratador,
			lugarSiniestro: denunciaData?.lugarSiniestro || siniestroData.lugar,
			descripcionHecho:
				denunciaData?.descripcionHecho || siniestroData.descripcion,
			datosInspeccion:
				denunciaData?.datosInspeccion || siniestroData.datosInspeccion,
		});
	}, [siniestroData, denunciaData]);

	const onChange = (prop, value) => {
		setData({ ...data, [prop]: value });
	};
	//actualiza información
	const onClick = () => {
		dispatch(allActions.siniestroActions.updateDenuncia(siniestroId, data));
	};

	return (
		<Grid container className={classes.container} spacing={5}>
			<Grid item xs={12} md={4} className={classes.gridItem}>
				<ComposedFormGroup
					placeholder="Transportista"
					onChange={onChange}
					id="transportista"
					value={data.transportista}
					isDisabled={isEverythingDisabled}
				/>
				<ComposedFormGroup
					placeholder="Quién lo contrata"
					id="contratador"
					onChange={onChange}
					value={data.contratador}
					isDisabled={isEverythingDisabled}
				/>
				<ComposedFormGroup
					placeholder="Lugar del siniestro"
					onChange={onChange}
					id="lugarSiniestro"
					value={data.lugarSiniestro}
					isDisabled={isEverythingDisabled}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<ComposedFormGroup
					placeholder="Descripción del hecho"
					inputAs="textarea"
					rows={8}
					id="descripcionHecho"
					onChange={onChange}
					value={data.descripcionHecho}
					isDisabled={isEverythingDisabled}
				/>
			</Grid>
			<Grid item xs={12} md={4} className={classes.gridItem}>
				<ComposedFormGroup
					placeholder="Datos de inspección"
					inputAs="textarea"
					rows={8}
					id="datosInspeccion"
					onChange={onChange}
					value={data.datosInspeccion}
					isDisabled={isEverythingDisabled}
				/>
			</Grid>
			{!isEverythingDisabled && (
				<div className={classes.buttonGrid}>
					<Button
						color="primary"
						variant="contained"
						onClick={onClick}
					>
						{denunciaReducer.isLoading ? (
							<CircularProgress size={20} color={"inherit"} />
						) : (
							"Guardar"
						)}
					</Button>

				</div>
			)}
		</Grid>
	);
}

SecondForm.propTypes = {
	onChange: PropTypes.func,
	onClick: PropTypes.func,
};

export default SecondForm;
