import {
  GET_JWT_START,
  GET_JWT_SUCCESS,
  GET_JWT_ERROR,
} from "../actions/types";

let initialState = {
  jwt: {},
  isLoading: false,
  error: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JWT_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_JWT_SUCCESS:
      return {
        ...state,
        jwt: action.payload,
        isLoading: false,
      };
    case GET_JWT_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default sessionReducer;
