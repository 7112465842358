import { isEqual } from "lodash";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import allActions from "../../../../actions";
import {
	CREATE_SINIESTRO_NUMBER_SUCCESS,
	GET_INDIVIDUAL_SINIESTRO_SUCCESS,
	UPDATE_SINIESTRO_ESTADO_SUCCESS,
} from "../../../../actions/types";

const useSiniestroNumberFeedback = (
	siniestroNumberReducer,
	setEditNro,
	siniestroEstadoReducer,
	setOpenDialogLiquidar,
	siniestroId
) => {
	const SNR = siniestroNumberReducer;
	const dispatch = useDispatch();
	const [alertAutohide, setAlertAutohide] = useState({
		open: false,
		type: "error",
		message: "",
		title: "",
	});

	useEffect(() => {
		if (
			SNR.createSiniestroNumber.result &&
			SNR.createSiniestroNumber.status === "success" &&
			!SNR.error
		) {
			setAlertAutohide({
				type: "success",
				message: "Número de siniestro creado con éxito",
				open: true,
				title: "¡YEY!",
			});
			setEditNro(false);
			dispatch({ type: CREATE_SINIESTRO_NUMBER_SUCCESS, payload: [] });
			setTimeout(() => setAlertAutohide({ open: false }), 5000);
		}

		if (SNR.error || SNR.createSiniestroNumber?.status === "error") {
			setAlertAutohide({
				type: "error",
				message: "Hubo un error en la creación del numero de siniestro",
				open: true,
				title: "!Error!",
			});
		}
	}, [SNR, dispatch, setEditNro]);

	useEffect(() => {
		const s = siniestroEstadoReducer || {};
		if (!s.isLoading) {
			if (
				!isEmpty(s.estadoUpdated) &&
				!s.error &&
				isEqual(s.estadoUpdated.status, "success")
			) {
				setOpenDialogLiquidar(false);
				dispatch({
					type: GET_INDIVIDUAL_SINIESTRO_SUCCESS,
					payload: [],
				});
				dispatch(
					allActions.siniestroActions.getInidividualSiniestro(
						siniestroId
					)
				);
				dispatch({
					type: UPDATE_SINIESTRO_ESTADO_SUCCESS,
					payload: [],
				});

				setAlertAutohide({
					title: "Exito",
					message: "Se liquido el siniestro con exito",
					open: true,
					type: "success",
				});
				setTimeout(() => setAlertAutohide({ open: false }), 5000);
			} else if (s.error || isEqual(s.estadoUpdated.status, "error")) {
				setAlertAutohide({
					title: "Error",
					message: "Hubo un error tratando de liquidar el siniestro",
					open: true,
					type: "error",
				});
			}
		}
	}, [dispatch, siniestroEstadoReducer, siniestroId, setOpenDialogLiquidar]);

	return [alertAutohide];
};

export default useSiniestroNumberFeedback;
