import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Mail from "@material-ui/icons/Mail";
import { List, ListItem, ListItemAvatar, Typography,Button } from "@material-ui/core";
import { sliceText } from "../../common/constants";
const DialogThread = (props) => {
	const history = useHistory();
	const { isOpen,onClose, isLoading, siniestroId} = props;
	const classes = useStyles();
	const threads = useSelector((state) => state.comunicationsReducer?.messagesUnread?.threads) 
	const onComunicationClick = (threadId) => {
		let redirectTo = `/mails?threadId=${threadId}&emailId=undefined`
		history.push(redirectTo, { siniestroId });
	};
	return (
		<Dialog open={isOpen} onClose={onClose}  data-testid="dialog">
			<DialogTitle id="alert-dialog-title" className={classes.title}>
				Mensajes nuevos
			</DialogTitle>
			<List>
						{ threads && threads.map((thread, index) => (
							<React.Fragment
								key={index}
								
							>
								<ListItem
									button
									onClick={() => onComunicationClick(thread.id)}
								>
									<ListItemAvatar>
										<Mail/>
									</ListItemAvatar>
									<Typography variant="h5">
										{sliceText(thread.snippet, 45)}
									</Typography>
								</ListItem>
							</React.Fragment>
						))}
					</List>
			<DialogActions>
				<Button
				 onClick={onClose}
				 color="primary"
				>
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default DialogThread;
