import React from "react";
import PropTypes from "prop-types";
import FormControl from "../FormControl";
import FormGroup from "../FormGroup";
import FormLabel from "../FormLabel";
import InputGroup from "../InputGroup";
import FormText from "../FormText";

function ComposedFormGroup(props) {
	const {
		placeholder,
		typeInput = "text",
		sideElement,
		inputAs,
		rows,
		id,
		onChange,
		helperText,
		isRequired,
		value,
		onBlur,
		inputStatus,
		isDisabled,
		maxLength,
		label,
	} = props;

	return (
		<div data-testid="composed-form-group" style={{ width: "100%" }}>
			<FormGroup>
				<FormLabel text={label ? label : placeholder} />
				{sideElement ? (
					<InputGroup
						sideElement={sideElement}
						placeholder={placeholder}
						type={typeInput}
						id={id}
						onChange={onChange}
						value={value}
						isRequired={isRequired}
						isDisabled={isDisabled}
						status={inputStatus}
					/>
				) : (
					<FormControl
						type={typeInput}
						placeholder={placeholder}
						as={inputAs}
						rows={rows}
						id={id}
						onChange={onChange}
						isRequired={isRequired}
						value={value}
						onBlur={onBlur}
						status={inputStatus}
						isDisabled={isDisabled}
						maxLength={maxLength}
					/>
				)}
				<FormText text={helperText} />
			</FormGroup>
		</div>
	);
}

ComposedFormGroup.propTypes = {
	placeholder: PropTypes.string,
	typeInput: PropTypes.string,
	inputAs: PropTypes.string,
	id: PropTypes.string,
	rows: PropTypes.number,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	sideElement: PropTypes.string,
	helperText: PropTypes.string,
	isRequired: PropTypes.bool,
	inputStatus: PropTypes.string,
	isDisabled: PropTypes.bool,
};

export default ComposedFormGroup;
