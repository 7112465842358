import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	navBarContainer: {
		height: "140px",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			padding: "0 20px",
		},
		width: "80%",
	},
	navBar: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "space-between",
		},
		justifyContent: "flex-start",
	},
	list: {
		listStyle: "none",
		display: "flex",
		padding: 0,
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "space-around",
			marginTop: "40px",
			padding: 0,
			display: "block",
		},
	},
	navBarItem: {
		padding: "10px 20px",
		cursor: "pointer",
		"&:hover": {
			background: "#eee",
		},
		display: "flex",
		margin: "0",
	},
	link: {
		fontSize: "15px !important",
		padding: "0",
		color: "#333",
		fontFamily: "Open Sans, sans-serif",
	},
	menuIcon: {
		fontSize: "24px",
	},
	imageSidebar: {
		display: "flex",
		justifyContent: "center",
		marginTop: "30px",
	},
	closeContainer: {
		width: "100%",
		textAlign: "right",
	},
	mobileDisplayTrue: {
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
	},
	mobileDisplayFalse: {
		display: "block",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	logo: {
		width: "220px",
		height: "120px",
		objectFit: "contain",
	},
	breadcrumb: {
		padding: "8px 15px",
		marginBottom: "40px",
		listStyle: "none",
		backgroundColor: "#f5f5f5",
		borderRadius: "4px",
		width: "80%",
		marginTop: "10px",
		[theme.breakpoints.down("sm")]: {
			width: "95%",
		},
	},
}));

export default useStyles;
