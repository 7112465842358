import {
  GET_COMMENTS_START,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_ERROR,
  CREATE_NEW_COMMENT_SUCCESS,
} from "./types";
import getLoggedClient from "./getLoggedClient";

const getCommentsBySiniestro = (idSiniestro) => async (dispatch, getState) => {
  dispatch({ type: GET_COMMENTS_START });
  const client = getLoggedClient();

  client
    .get(`/siniestro/${idSiniestro}/comentario`)
    .then((response) => {
      dispatch({ type: GET_COMMENTS_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: GET_COMMENTS_ERROR, payload: err });
    });
};

const addNewComment = (idSiniestro, data) => async (dispatch, getState) => {
  dispatch({ type: GET_COMMENTS_START });
  const client = getLoggedClient();

  client
    .post(`/siniestro/${idSiniestro}/comentario`, data)
    .then((response) => {
      dispatch({ type: CREATE_NEW_COMMENT_SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: GET_COMMENTS_ERROR, payload: err });
    });
};

const commentsActions = {
  getCommentsBySiniestro,
  addNewComment,
};

export default commentsActions;
