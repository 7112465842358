import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";

const AutocompleteInput = (props) => {
  const {
    id,
    placeholder,
    isLoading,
    onChange,
    onInputChange,
    options = [],
    isRequired,
    helperText,
    isDisabled,
    getOptionLabel,
  } = props;
  const [open, setOpen] = useState(false);
  const loading = open && isLoading;
  const classes = useStyles();

  const handleInputChange = (ev, newVa, typeOperation) => {
    onInputChange && onInputChange(ev, newVa, typeOperation);
  };

  const handleChange = (id) => (ev, newValue, typeOperation) => {
    onChange && onChange(id, newValue, typeOperation);
  };

  const handleOpen = () => setOpen(!open);

  const renderInput = (params) => (
    <TextField
      {...params}
      fullWidth
      className={classes.inputStyles}
      placeholder={placeholder}
      variant="outlined"
      margin="dense"
      required={isRequired}
      helperText={helperText}
      InputProps={{
        ...params.InputProps,
        className: classes.inputStyles,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      id={id}
      className={classes.autocompleteContainer}
      onClose={handleOpen}
      onOpen={handleOpen}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={handleChange(id)}
      onInputChange={handleInputChange}
      renderInput={renderInput}
      loading={loading}
      disabled={isDisabled}
      loadingText="Cargando..."
      data-testid="autocomplete"
      classes={{
        option: classes.option,
        loading: classes.option,
        noOptions: classes.option,
      }}
    />
  );
};

AutocompleteInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
};

export default AutocompleteInput;
