import {
	GET_DOCUMENTATION_START,
	GET_DOCUMENTATION_SUCCESS,
	GET_DOCUMENTATION_ERROR,
	ADD_ARCHIVO_TIPO_SUCCESS,
} from "./types";
import getLoggedClient from "./getLoggedClient";

const getAllArchivoTipo = () => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: GET_DOCUMENTATION_START });

	client
		.get(`/archivoTipo`)
		.then((response) => {
			dispatch({
				type: GET_DOCUMENTATION_SUCCESS,
				payload: response.data.result,
			});
		})
		.catch((error) => {
			dispatch({
				type: GET_DOCUMENTATION_ERROR,
				payload: error,
			});
		});
};

const newArchivoTipo = (data) => async (dispatch, getState) => {
	const client = getLoggedClient();
	dispatch({ type: GET_DOCUMENTATION_START });

	client
		.post(`/archivoTipo`, data)
		.then((response) => {
			dispatch({
				type: ADD_ARCHIVO_TIPO_SUCCESS,
				payload: response.data.result,
			});
		})
		.catch((error) => {
			dispatch({
				type: GET_DOCUMENTATION_ERROR,
				payload: error,
			});
		});
};

const archivoTipoActions = {
	getAllArchivoTipo,
	newArchivoTipo,
};
export default archivoTipoActions;
