import {
	LOAD_FILE_START,
	LOAD_FILE_SUCCESS,
	LOAD_FILE_ERROR,
	GET_ALL_FILES_SUCCESS,
	CATEGORIZE_ARCHIVO_SUCCESS,
	DELETE_ARCHIVO_SUCCESS,
	DELETE_ARCHIVO_START,
	DELETE_ARCHIVO_ERROR,
} from "../actions/types";

let initialState = {
	uploadedFile: [],
	allFiles: [],
	archivoCategorized: [],
	archivoDeleted: [],
	isLoading: false,
	error: null,
};

const archivoReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOAD_FILE_START:
			return {
				...state,
				isLoading: true,
			};
		case LOAD_FILE_SUCCESS:
			return {
				...state,
				uploadedFile: action.payload,
				isLoading: false,
				error: null,
			};
		case LOAD_FILE_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case GET_ALL_FILES_SUCCESS:
			return {
				...state,
				allFiles: action.payload,
				isLoading: false,
			};
		case CATEGORIZE_ARCHIVO_SUCCESS:
			return {
				...state,
				archivoCategorized: action.payload,
				isLoading: false,
			};
		case DELETE_ARCHIVO_SUCCESS:
			return {
				...state,
				archivoDeleted: action.payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default archivoReducer;
