export const GET_OPERATION_START = "GET_OPERATION_START";
export const GET_OPERATION_SUCCESS = "GET_OPERATION_SUCCESS";
export const GET_OPERATION_ERROR = "GET_OPERATION_ERROR";

export const GET_SINIESTROS_START = "GET_SINIESTROS_START";
export const SEND_LIQUIDATE_SUCCESS = "SEND_LIQUIDATE_SUCCESS";
export const CHANGE_ESTADO_SUCCESS = "CHANGE_ESTADO_SUCCESS";
export const GET_SINIESTROS_SUCCESS = "GET_SINIESTROS_SUCCESS";
export const GET_SINIESTROS_ERROR = "GET_SINIESTROS_ERROR";
export const ADD_SINIESTRO_TIPO_SUCCESS = "ADD_SINIESTRO_TIPO_SUCCESS";

export const GET_JWT_START = "GET_JWT_START";
export const GET_JWT_SUCCESS = "GET_JWT_SUCCESS";
export const GET_JWT_ERROR = "GET_JWT_ERROR";

export const GET_CLIENTS_START = "GET_CLIENTS_START";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_ERROR = "GET_CLIENTS_ERROR";

export const CREATE_SINIESTRO_START = "CREATE_SINIESTRO_START";
export const CREATE_SINIESTRO_SUCCESS = "CREATE_SINIESTRO_SUCCESS";
export const CREATE_SINIESTRO_ERROR = "CREATE_SINIESTRO_ERROR";
export const UPDATE_SINIESTRO_SUCCESS = "UPDATE_SINIESTRO_SUCCESS";
export const GET_INDIVIDUAL_SINIESTRO_SUCCESS = "GET_INDIVIDUAL_SINIESTRO_SUCCESS";
export const BAJA_SINIESTRO_SUCCESS = "BAJA_SINIESTRO_SUCCESS";
export const LIQUIDACION_SINIESTRO_SUCCESS = "LIQUIDACION_SINIESTRO_SUCCESS";
export const UPDATE_SINIESTRO_DETAILS_SUCCESS = "UPDATE_SINIESTRO_DETAILS_SUCCESS";

export const GET_ALL_SINIESTROS_START = "GET_ALL_SINIESTROS_START";
export const GET_ALL_SINIESTROS_SUCCESS = "GET_ALL_SINIESTROS_SUCCESS";
export const GET_ALL_SINIESTROS_ERROR = "GET_ALL_SINIESTROS_ERROR";
export const GET_ALL_SINIESTROSFILTER_START = "GET_ALL_SINIESTROSFILTER_START";
export const GET_ALL_SINIESTROSFILTER_SUCCESS = "GET_ALL_SINIESTROSFILTER_SUCCESS";
export const GET_ALL_SINIESTROSFILTER_ERROR = "GET_ALL_SINIESTROSFILTER_ERROR";
export const COMPLAINT_FORM_START = "COMPLAINT_FORM_START";
export const COMPLAINT_FORM_SUCCESS = "COMPLAINT_FORM_SUCCESS";
export const COMPLAINT_FORM_ERROR = "COMPLAINT_FORM_ERROR";

export const GET_COMUNICATIONS_START = "GET_COMUNICATIONS_START";
export const GET_COMUNICATIONS_SUCCESS = "GET_COMUNICATIONS_SUCCESS";
export const GET_COMUNICATIONS_ERROR = "GET_COMUNICATIONS_ERROR";
export const GET_COMUNICATIONS_TYPES_SUCCESS = "GET_COMUNICATIONS_TYPES_SUCCESS";
export const NEW_COMUNICATION_SUCCESS = "NEW_COMUNICATION_SUCCESS";
export const GET_SINGLE_COMUNICATION_SUCCESS = "GET_SINGLE_COMUNICATION_SUCCESS";

export const CREATE_SINIESTRO_NUMBER_START = "CREATE_SINIESTRO_NUMBER_START";
export const CREATE_SINIESTRO_NUMBER_SUCCESS = "CREATE_SINIESTRO_NUMBER_SUCCESS";
export const CREATE_SINIESTRO_NUMBER_ERROR = "CREATE_SINIESTRO_NUMBER_ERROR";
export const LOAD_SINIESTRO_ESTADO_START = "LOAD_SINIESTRO_ESTADO_START";
export const LOAD_SINIESTRO_ESTADO_SUCCESS = "LOAD_SINIESTRO_ESTADO_SUCCESS";
export const LOAD_SINIESTRO_ESTADO_ERROR = "LOAD_SINIESTRO_ESTADO_ERROR";
export const UPDATE_SINIESTRO_ESTADO_SUCCESS = "UPDATE_SINIESTRO_ESTADO_SUCCESS";

export const GET_COMMENTS_START = "GET_COMMENTS_START";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_ERROR = "GET_COMMENTS_ERROR";
export const CREATE_NEW_COMMENT_SUCCESS = "CREATE_NEW_COMMENT_SUCCESS";

export const SINIESTRO_RESERVA_START = "SINIESTRO_RESERVA_START";
export const SINIESTRO_RESERVA_SUCCESS = "SINIESTRO_RESERVA_SUCCESS";
export const SINIESTRO_RESERVA_ERROR = "SINIESTRO_RESERVA_ERROR";

export const UPDATE_DENUNCIA_INFO_START = "UPDATE_DENUNCIA_INFO_START";
export const UPDATE_DENUNCIA_INFO_SUCCESS = "UPDATE_DENUNCIA_INFO_SUCCESS";
export const UPDATE_DENUNCIA_INFO_ERROR = "UPDATE_DENUNCIA_INFO_ERROR";

export const GET_DOCUMENTATION_START = "GET_DOCUMENTATION_START";
export const GET_DOCUMENTATION_SUCCESS = "GET_DOCUMENTATION_SUCCESS";
export const GET_DOCUMENTATION_ERROR = "GET_DOCUMENTATION_ERROR";
export const ADD_ARCHIVO_TIPO_SUCCESS = "ADD_ARCHIVO_TIPO_SUCCESS";

export const LOAD_FILE_START = "LOAD_FILE_START";
export const LOAD_FILE_SUCCESS = "LOAD_FILE_SUCCESS";
export const LOAD_FILE_ERROR = "LOAD_FILE_ERROR";
export const GET_ALL_FILES_SUCCESS = "GET_ALL_FILES_SUCCESS";
export const CATEGORIZE_ARCHIVO_SUCCESS = "CATEGORIZE_ARCHIVO_SUCCESS";
export const DELETE_ARCHIVO_SUCCESS = "DELETE_ARCHIVO_SUCCESS";
export const DELETE_ARCHIVO_START = "DELETE_ARCHIVO_START";
export const DELETE_ARCHIVO_ERROR = "DELETE_ARCHIVO_ERROR";
export const SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS =
	"SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS";
export const SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR =
	"SINIESTRO_TIPO_ARCHIVO_TIPO_ERROR";
export const SINIESTRO_TIPO_ARCHIVO_TIPO_START =
	"SINIESTRO_TIPO_ARCHIVO_TIPO_START";
export const ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS =
	"ADD_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS";
export const DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS =
	"DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_SUCCESS";
export const DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_START =
	"DELETE_SINIESTRO_TIPO_ARCHIVO_TIPO_START";
export const GET_SINIESTRO_ARCHIVO_TIPO_SUCCESS =
	"GET_SINIESTRO_ARCHIVO_TIPO_SUCCESS";
export const ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS =
	"ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS";
export const DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS =
	"DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS";

export const SEND_EMAIL_START = "SEND_EMAIL_START";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";
export const GET_THREAD_SUCCESS = "GET_THREAD_SUCCESS";
export const SEND_EMAIL_COMPANY_START = "SEND_EMAIL_COMPANY_START";
export const SEND_EMAIL_COMPANY_SUCCESS = "SEND_EMAIL_COMPANY_SUCCESS";
export const SEND_EMAIL_COMPANY_ERROR = "SEND_EMAIL_COMPANY_ERROR";
export const SEND_EMAIL_CLAIM_DOC_START = "SEND_EMAIL_CLAIM_DOC_START";
export const SEND_EMAIL_CLAIM_DOC_SUCCESS = "SEND_EMAIL_CLAIM_DOC_SUCCESS";
export const SEND_EMAIL_CLAIM_DOC_ERROR = "SEND_EMAIL_CLAIM_DOC_ERROR";

export const SINIESTROS_REPORTE_START = "SINIESTROS_REPORTE_START";
export const SINIESTROS_REPORTE_SUCCESS = "SINIESTROS_REPORTE_SUCCESS";
export const SINIESTROS_REPORTE_ERROR = "SINIESTROS_REPORTE_ERROR";

export const GET_AJUSTADORES_START = "GET_AJUSTADORES_START";
export const GET_AJUSTADORES_SUCCESS = "GET_AJUSTADORES_SUCCESS";
export const GET_AJUSTADORES_ERROR = "GET_AJUSTADORES_ERROR";

export const GET_ESTUDIOS_START = "GET_ESTUDIOS_START";
export const GET_ESTUDIOS_SUCCES = "GET_ESTUDIOS_SUCCES";
export const GET_ESTUDIOS_ERROR = "GET_ESTUDIOS_ERROR";

export const NEW_ESTUDIO_START = "NEW_ESTUDIO_START";
export const NEW_ESTUDIO_SUCCESS = "NEW_ESTUDIO_SUCCESS";
export const NEW_ESTUDIO_ERROR = "NEW_ESTUDIO_ERROR";

export const NEW_AJUSTADOR_START = "NEW_AJUSTADOR_START";
export const NEW_AJUSTADOR_SUCCESS = "NEW_AJUSTADOR_SUCCESS";
export const NEW_AJUSTADOR_ERROR = "NEW_AJUSTADOR_ERROR";

export const PUT_ESTUDIO_START = "PUT_ESTUDIO_START";
export const PUT_ESTUDIO_SUCCESS = "PUT_ESTUDIO_SUCCESS";
export const PUT_ESTUDIO_ERROR = "PUT_ESTUDIO_ERROR";

export const PUT_AJUSTADOR_START = "PUT_AJUSTADOR_START";
export const PUT_AJUSTADOR_SUCCESS = "PUT_AJUSTADOR_SUCCESS";
export const PUT_AJUSTADOR_ERROR = "PUT_AJUSTADOR_ERROR";

export const DELETE_AJUSTADOR_START = "DELETE_AJUSTADOR_START";
export const DELETE_AJUSTADOR_SUCCESS = "DELETE_AJUSTADOR_SUCCESS";
export const DELETE_AJUSTADOR_ERROR = "DELETE_AJUSTADOR_ERROR";

export const DELETE_ESTUDIO_START = "DELETE_ESTUDIO_START";
export const DELETE_ESTUDIO_SUCCESS = "DELETE_ESTUDIO_SUCCESS";
export const DELETE_ESTUDIO_ERROR = "DELETE_ESTUDIO_ERROR";

export const LOW_ESTUDIO_START = "LOW_ESTUDIO_START";
export const LOW_ESTUDIO_SUCCESS = "LOW_ESTUDIO_SUCCESS";
export const LOW_ESTUDIO_ERROR = "LOW_ESTUDIO_ERROR";

export const UP_ESTUDIO_START = "UP_ESTUDIO_START";
export const UP_ESTUDIO_SUCCESS = "UP_ESTUDIO_SUCCESS";
export const UP_ESTUDIO_ERROR = "UP_ESTUDIO_ERROR";

export const LOW_AJUSTADOR_START = "LOW_AJUSTADOR_START";
export const LOW_AJUSTADOR_SUCCESS = "LOW_AJUSTADOR_SUCCESS";
export const LOW_AJUSTADOR_ERROR = "LOW_AJUSTADOR_ERROR";

export const UP_AJUSTADOR_START = "UP_AJUSTADOR_START";
export const UP_AJUSTADOR_SUCCESS = "UP_AJUSTADOR_SUCCESS";
export const UP_AJUSTADOR_ERROR = "UP_AJUSTADOR_ERROR";

export const GET_AJUSTADORESIDESTUDIO_START = "GET_AJUSTADORESIDESTUDIO_START";
export const GET_AJUSTADORESIDESTUDIO_SUCCESS = "GET_AJUSTADORESIDESTUDIO_SUCCESS";
export const GET_AJUSTADORESIDESTUDIO_ERROR = "GET_AJUSTADORESIDESTUDIO_ERROR";

export const GET_AJUSTADOR_START = "GET_AJUSTADOR_START";
export const GET_AJUSTADOR_SUCCESS = "GET_AJUSTADOR_SUCCESS";
export const GET_AJUSTADOR_ERROR = "GET_AJUSTADOR_ERROR";

export const SET_AJUSTADOR_START = "SET_AJUSTADOR_START";
export const SET_AJUSTADOR_SUCCESS = "SET_AJUSTADOR_SUCCESS";
export const SET_AJUSTADOR_ERROR = "SET_AJUSTADOR_ERROR";

export const GET_TOTALRESERVA_START = "GET_TOTALRESERVA_START";
export const GET_TOTALRESERVA_SUCCES = "GET_TOTALRESERVA_SUCCES";
export const GET_TOTALRESERVA_ERROR = "GET_TOTALRESERVA_ERROR";
export const GET_UNREAD_MESSAGES_START = "GET_UNREAD_MESSAGES_START";
export const GET_UNREAD_MESSAGES_SUCCES = "GET_UNREAD_MESSAGES_SUCCES";
export const GET_UNREAD_MESSAGES_ERROR = "GET_UNREAD_MESSAGES_ERROR";

export const GET_NEW_THREADS_START = "GET_NEW_THREADS_START";
export const GET_NEW_THREADS_SUCCES = "GET_NEW_THREADS_SUCCES";
export const GET_NEW_THREADS_ERROR = "GET_NEW_THREADS_ERROR";

export const SEND_REPLY_START = "SEND_REPLY_START";
export const SEND_REPLY_SUCCESS = "SEND_REPLY_SUCCESS";
export const SEND_REPLY_ERROR = "SEND_REPLY_ERROR";

export const SEND_TEMPLATE_START = "SEND_TEMPLATE_START";
export const SEND_TEMPLATE_SUCCESS = "SEND_TEMPLATE_SUCCESS";
export const SEND_TEMPLATE_ERROR = "SEND_TEMPLATE_ERROR";
export const GET_TEMPLATE_START = "GET_TEMPLATE_START";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_ERROR = "GET_TEMPLATE_ERROR";
export const DELETE_TEMPLATE_START = "DELETE_TEMPLATE_START";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const IS_LOADED_SUCCESS = "IS_LOADED_SUCCESS";
export const DELETE_TEMPLATE_ERROR = "DELETE_TEMPLATE_ERROR";
export const UP_DATE_TEMPLATE_START = "UP_DATE_TEMPLATE_START";
export const UP_DATE_TEMPLATE_SUCCESS = "UP_DATE_TEMPLATE_SUCCESS";
export const UP_DATE_TEMPLATE_ERROR = "UP_DATE_TEMPLATE_ERROR";
export const GET_TEMPLATES_START = "GET_TEMPLATE_START";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_ERROR = "GET_TEMPLATES_ERROR";
export const GET_ACTION_START = "GET_ACTION_START";
export const GET_ACTION_SUCCESS = "GET_ACTION_SUCCESS";
export const GET_ACTION_ERROR = "GET_ACTION_ERROR";
export const ACTIVETE_IS_VIEW_TEMPLATE = "ACTIVETE_IS_VIEW_TEMPLATE";
export const ACTIVETE_IS_EDIT_TEMPLATE = "ACTIVETE_IS_EDIT_TEMPLATE";
export const GET_VARIABLES_START = "GET_VARIABLES_START";
export const GET_VARIABLES_SUCCESS = "GET_VARIABLES_SUCCESS";
export const GET_VARIABLES_ERROR = "GET_VARIABLES_ERROR";
export const GET_TEMPLATE_DECODED_START = "GET_TEMPLATE_SINIESTRO_START";
export const GET_TEMPLATE_DECODED_SUCCESS = "GET_TEMPLATE_SINIESTRO_SUCCESS";
export const GET_TEMPLATE_DECODED_ERROR = "GET_TEMPLATE_SINIESTRO_ERROR";


