import styled from "styled-components";

export const FormControlExtensionStatus = styled.div`
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 10%;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  justify-content: center;
  align-items: center;

  ${(props) => {
    switch (props.status) {
      case "success":
        return `
          border-color: green;
          color: green;
          display: flex !important;
        `;
      case "error":
        return `
          border-color: red;
          color: red;
          display: flex !important;
        `;
      case "loading":
        return `
            display: flex !important;
          `;
      default:
        return `
          display: none !important;
        `;
    }
  }}
`;

const borderIfStatus = `
  border-right: 0;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  width: 90%;`;

export const FormControlInput = styled.input`
  ${(props) => {
    switch (props.status) {
      case "success":
        return `
          border-color: green;
          ${borderIfStatus}
        `;
      case "error":
        return `
          border-color: red;
          ${borderIfStatus}
        `;
      case "loading":
        return borderIfStatus;
      default:
        return `
          width: 100%;
        `;
    }
  }}
`;

export const FormControlContainer = styled.div`
  display: flex;
`;
