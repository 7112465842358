import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import FormLabel from "../FormLabel";
import FormGroup from "../FormGroup";

function RadioForm(props) {
	const { onChange, value, customStyles, data, label, id, isDisabled } =
		props;

	const handleChange = (id) => (ev) => {
		onChange && onChange(id, ev.target.value);
	};

	return (
		<FormGroup>
			<FormLabel text={label} />
			<RadioGroup
				id={id}
				value={value}
				onChange={handleChange(id)}
				className={customStyles}
			>
				{data.map(({ label, value }) => (
					<FormControlLabel
						value={value}
						key={value}
						control={<Radio />}
						label={label}
						disabled={isDisabled}
					/>
				))}
			</RadioGroup>
		</FormGroup>
	);
}

RadioForm.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	id: PropTypes.string,
};

export default RadioForm;
