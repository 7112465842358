import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { FormStyled, useStyles } from "./styles";
import FirstForm from "./forms/FirstForm";
import FilesList from "./forms/Files";
import { Collapse, Grid, IconButton, Typography } from "@material-ui/core";
import { Button, CircularProgress } from "@mui/material"
import SecondForm from "./forms/SecondForm";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";
import SkeletonLoader from "../../components/SkeletonLoader";
import Comments from "../../components/Comments";
import Dropzone from "../../components/Dropzone";
import { createBreadcrumbData, ESTADOS_ID } from "../../common/constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { isEmpty, isEqual, map } from "lodash";
import StarsIcon from "@material-ui/icons/Stars";
import clsx from "clsx";
import FormLabel from "../../components/FormLabel";
import CustomDialogFiles from "./components/CustomDialogFiles";
import { ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS, DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS, LOAD_FILE_SUCCESS } from "../../actions/types";
import ButtonAddAndInput from "../../components/ButtonAddAndInput";
import Select from "../../components/Select";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import Delete from "@material-ui/icons/Delete";
import FourthForm from "./forms/FourthForm";
import AjustadorForm from "./forms/ThirdForm";
import { onUploadFilesGeneral } from './forms/FourthForm/functions';
import ModalReport from "../../components/ModalReport";
import ClaimDocumentation from "../../components/ClaimDocumentation";
import { ACCION_PEDIDO_DOCUMENTACION, ACCION_DENUNCIA_COMPANIA } from "../../common/constants";

function IndividualSiniestro(props) {
	const { setBreadcrumbs } = props;
	const siniestroId = new URLSearchParams(window.location.search).get("id");
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const createSiniestroReducer = useSelector((state) => state.createSiniestroReducer);
	const archivoReducer = useSelector((state) => state.archivoReducer);
	const archivoTipoReducer = useSelector((state) => state.archivoTipoReducer);
	const siniestroTipoArchivoTipoReducer = useSelector((state) => state.siniestroTipoArchivoTipoReducer);
	const [data, setData] = useState({});
	const [files, setFiles] = useState([]);
	const [filesMaxSize, setFilesMaxSize] = useState([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [filesWithError, setFilesWithError] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [expandMore, setExpandMore] = useState(true);
	const handleExpandMoreFiles = () => setExpandMore(!expandMore);
	const [docReqExtra, setDocReqExtra] = useState(null);
	const [docReq, setDocReq] = useState([]);
	const [modalFormData, setModalFormData] = useState({})
	const [modalReport, setModalReport] = useState(false);
	const [modalClaimDoc, setModalClaimDoc] = useState(false);
	const siniestroData = createSiniestroReducer.individualSiniestro?.result;
	const onChange = (prop, value) => {
		setData({ ...data, [prop]: value });
	};
	useEffect(() => {
		setBreadcrumbs([
			createBreadcrumbData(`/siniestros`, `Siniestros`),
			createBreadcrumbData(
				`/siniestro?id=${siniestroId}`,
				`Siniestro #${siniestroId}`
			),
		]);
	}, [siniestroId, setBreadcrumbs]);

	useEffect(() => {
		if (siniestroData?.siniestro?.labelGmail_id) {
			dispatch(allActions.comunicationsActions.getUnreadMessages(siniestroData?.siniestro?.labelGmail_id))
		}
	}, [siniestroData])

	useEffect(() => {
		if (siniestroData?.siniestro?.docRequerida) {
			setDocReq(siniestroData.siniestro.docRequerida)
		}
	}, [siniestroData])

	useEffect(() => {
		dispatch(allActions.siniestroActions.getInidividualSiniestro(siniestroId));
		dispatch(allActions.comunicationsActions.getComunicationsBySiniestro(siniestroId));
		dispatch(allActions.archivoActions.getAllFilesBySiniestro(siniestroId));
		dispatch(allActions.archivoTipoActions.getAllArchivoTipo());
		dispatch(allActions.siniestroActions.getSiniestroEstados());
		dispatch(allActions.altaSiniestroActions.getSiniestros());
		dispatch(allActions.siniestroTipoArchivoTipoActions.getDocExtraBySiniestro(siniestroId));
	}, [dispatch, siniestroId]);

	if (!siniestroId) history.push("/siniestros");

	const docReqIds = siniestroData?.siniestro?.docRequerida?.map((doc) => doc.archivoTipo_id);
	const docExtrIds = siniestroTipoArchivoTipoReducer?.docExtraSiniestro?.map((doc) => doc.archivoTipo_id);
	const customInputDocReqExtra = (
		<Select
			options={archivoTipoReducer.archivos?.filter((arch) =>
				!docReqIds?.includes(arch.archivoTipo_id) &&
				!docExtrIds?.includes(arch.archivoTipo_id)
			)}
			keyToShow={"titulo"}
			onChange={(prop, val) => {
				setDocReqExtra(val);
			}}
		/>
	);

	const renderStyleCrossOut = (isSelected, isSelectedInMemory) => {
		if (isSelected !== undefined) {
			return isSelected ? classes.listCompleted : classes.listItemDocumentation;
		};
		return isSelectedInMemory === 1 ? classes.listCompleted : classes.listItemDocumentation;
	}
	const requiredDocumentationList = (
		<Grid container className={classes.documentationList}>
			{!isEmpty(siniestroData?.siniestro?.docRequerida) && (
				<div className={classes.docReqCont}>
					<Typography variant="h5" className={classes.docReqTypo}>
						Tipo de siniestro
					</Typography>
					<Grid container>
						{map(docReq, (doc) => (
							<Grid
								item
								key={doc.archivoTipo_id}
								xs={12}
								md={6}
								className={renderStyleCrossOut(doc.isSelected, doc.esta)}
							>
								<StarsIcon className={classes.checkIcon} />{" "}
								{doc.titulo}
							</Grid>
						))}
					</Grid>
				</div>
			)}
			<div className={classes.docReqCont}>
				<div className={classes.docExtraTitle}>
					<Typography variant="h5" className={classes.docReqTypo}>
						Documentacion extra
					</Typography>
					<ButtonAddAndInput
						customStylesInput={classes.buttonAddInputDoc}
						customInput={customInputDocReqExtra}
						onSubmit={() => {
							dispatch(allActions.siniestroTipoArchivoTipoActions.addDocExtraBySiniestro(
								siniestroId, { archivoTipo_id: docReqExtra.archivoTipo_id }));
						}}
						value={docReqExtra}
						isLoading={siniestroTipoArchivoTipoReducer.isLoading}
					/>
				</div>
				{!isEmpty(siniestroTipoArchivoTipoReducer?.docExtraSiniestro) && (
					<Grid container>
						{map(siniestroTipoArchivoTipoReducer?.docExtraSiniestro,
							(doc) => (
								<Grid
									item
									key={doc.archivoTipo_id}
									xs={12}
									md={6}
									className={classes.listItemDocumentation}
								>
									<LabelImportantIcon
										className={classes.checkIcon}
									/>{" "}
									{doc.titulo}
									{siniestroTipoArchivoTipoReducer.isLoading ? (
										<CircularProgress size={20} />
									) : (
										<IconButton
											onClick={() => {
												dispatch(allActions.siniestroTipoArchivoTipoActions.deleteDocExtraBySiniestro(
													siniestroId, { archivoTipo_id: doc.archivoTipo_id }));
											}}
										>
											<Delete style={{ fontSize: "15px" }} />
										</IconButton>
									)}
								</Grid>
							))}
					</Grid>
				)}
			</div>
		</Grid>
	);

	useEffect(() => {
		if (!isEmpty(siniestroTipoArchivoTipoReducer?.newDocExtra)) {
			dispatch(allActions.siniestroTipoArchivoTipoActions.getDocExtraBySiniestro(siniestroId));
			dispatch({ type: ADD_SINIESTRO_ARCHIVO_TIPO_SUCCESS, payload: [] });
		}

		if (!isEmpty(siniestroTipoArchivoTipoReducer?.deletedDocExtra)) {
			dispatch(allActions.siniestroTipoArchivoTipoActions.getDocExtraBySiniestro(siniestroId));
			dispatch({ type: DELETE_SINIESTRO_ARCHIVO_TIPO_SUCCESS, payload: [] });
		}
	}, [dispatch, siniestroTipoArchivoTipoReducer, siniestroId]);

	useEffect(() => {
		if (isEqual(uploadedFiles.length + filesWithError.length, files.length) && files.length > 0) {
			if (!isEmpty(filesWithError)) {
				setOpenDialog(true);
				setFiles([]);
			} else {
				setUploadedFiles([]);
				setFiles([]);
			}
			dispatch({ type: LOAD_FILE_SUCCESS, payload: [] });
			dispatch(allActions.archivoActions.getAllFilesBySiniestro(siniestroId));
		}
		if (!isEmpty(filesMaxSize)) {
			setOpenDialog(true);
		}
	}, [filesMaxSize, uploadedFiles, filesWithError, files, siniestroId, dispatch]);

	const isEverythingDisabled =
		isEqual(siniestroData?.siniestro?.siniestroEstado_id, ESTADOS_ID.baja) ||
		isEqual(siniestroData?.siniestro?.siniestroEstado_id, ESTADOS_ID.liquidado) ||
		isEqual(siniestroData?.siniestro?.siniestroEstado_id, ESTADOS_ID.pagado) ||
		isEqual(siniestroData?.siniestro?.siniestroEstado_id, ESTADOS_ID.rechazado);
	const derivacionAseguradora = isEqual(siniestroData?.siniestro?.siniestroEstado_id, ESTADOS_ID.derivacionAseguradora);
	const analisisDocAjustadorCia = isEqual(siniestroData?.siniestro?.siniestroEstado_id, ESTADOS_ID.analisisDocAjustadorCia);

	const handleUploadFiles = (e) => {
		onUploadFilesGeneral(files, setFilesMaxSize, siniestroId, dispatch);
		setTimeout(() => {
			setFiles([])
		}, 3000);
	};

	const getTemplateClaimDoc = (ACCION_PEDIDO_DOCUMENTACION, siniestroId) => {
		dispatch(allActions.templatesActions.getTemplateDecoded(ACCION_PEDIDO_DOCUMENTACION, siniestroId))
	};
	const getTemplateCompany = (ACCION_DENUNCIA_COMPANIA, siniestroId) => {
		dispatch(allActions.templatesActions.getTemplateDecoded(ACCION_DENUNCIA_COMPANIA, siniestroId))
	};

	return (
		<FormStyled>
			<Paper elevation={4} className={classes.paperFiles}>
				{createSiniestroReducer.isLoading &&
					isEmpty(createSiniestroReducer.updateSiniestroDetails) ? (
					<SkeletonLoader numberOfSkeletons={10} />
				) : (
					<>
						<FirstForm
							onChange={onChange}
							{...siniestroData}
							isEverythingDisabled={isEverythingDisabled}
							modalFormData={modalFormData}
							setModalFormData={setModalFormData}
						/>
					</>
				)}
			</Paper>
			<Paper elevation={4} className={classes.paperFiles}>
				<Typography variant="h4">Info de la denuncia</Typography>
				<SecondForm
					onChange={onChange}
					isEverythingDisabled={isEverythingDisabled}
					modalFormData={modalFormData}
					setModalFormData={setModalFormData}
				/>
			</Paper>
			<Paper elevation={4} className={classes.paperFiles}>
				<Typography className={classes.title} variant="h4">
					Información ajustador</Typography>
				<AjustadorForm>
				</AjustadorForm>
			</Paper>
			<Paper elevation={4} className={classes.paperFiles}>
				<Grid className={classes.headerContainer}>
					<Typography variant="h4">
						Compañía
					</Typography>
					{derivacionAseguradora &&
						<Button
							onClick={() => {
								setModalReport(true);
								getTemplateCompany(ACCION_DENUNCIA_COMPANIA, siniestroId);
							}}
							variant="contained"
							color="primary"
						>
							Denunciar compañía
						</Button>}
					{analisisDocAjustadorCia &&
						<Button
							variant="contained"
							color="primary"
						>
							Denunciado
						</Button>
					}
				</Grid>
				<FourthForm isEverythingDisabled={isEverythingDisabled} />
			</Paper>
			{!createSiniestroReducer.isLoading &&
				!isEmpty(createSiniestroReducer.individualSiniestro) && (
					<>
						<Paper elevation={4} className={classes.paperFiles}>
							<Grid container>
								<Grid className={classes.docContainer}>
									<Grid
										item
										xs={12}
										className={classes.requiredDocumentationTitle}
									>
										<Typography variant="h4">
											Documentación
										</Typography>
										<IconButton onClick={handleExpandMoreFiles}>
											{expandMore ? (<ExpandMoreIcon />) : (<ExpandLessIcon />)}
										</IconButton>
									</Grid>
									{!isEverythingDisabled &&
										<Button
											onClick={() => {
												setModalClaimDoc(true);
												getTemplateClaimDoc(ACCION_PEDIDO_DOCUMENTACION, siniestroId)
											}}
											variant="contained"
											color="primary"
										>
											Reclamar documentación
										</Button>}
								</Grid>
								<Collapse in={!expandMore}>
									{requiredDocumentationList}
								</Collapse>
								<Grid item xs={12}></Grid>
								<Grid
									item
									xs={12}
									className={classes.filesGrid}
								>
									<div className={classes.docSubidaTitle}>
										<FormLabel text="Documentación subida" />
									</div>
									<FilesList
										files={archivoReducer.allFiles?.filter((fi) => !fi.segmentacionEntidad)}
										isEverythingDisabled={isEverythingDisabled}
										setDocReq={setDocReq}
										docReq={docReq}
									/>
								</Grid>
								{!isEverythingDisabled && (
									<>
										<Grid
											item
											xs={12}
											className={clsx(classes.filesGrid, classes.dropzoneGrid)}
										>
											<FormLabel text="Subir nueva documentación" />
											<Dropzone
												files={files}
												setFiles={setFiles}
											/>
											<Grid className={classes.buttonAdd}>
												<Button
													variant="contained"
													onClick={(e) => handleUploadFiles(e)}
													color="primary"
												>
													{archivoReducer.isLoading ? (
														<CircularProgress size={20} color={"inherit"} />
													) : (
														"Subir archivos"
													)}
												</Button>
											</Grid>
											{archivoReducer.error &&
												<Grid className={classes.errorContainer}>
													{archivoReducer.error ? archivoReducer.error : ''}
												</Grid>}
										</Grid>
									</>
								)}
							</Grid>
						</Paper>
						<Paper elevation={4} className={classes.paperComments}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="h4">
										Comentarios
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									className={classes.filesGrid}
								>
									<Comments idSiniestro={siniestroId} />
								</Grid>
							</Grid>
						</Paper>

						<CustomDialogFiles
							filesNames={!isEmpty(filesMaxSize) ? filesMaxSize : filesWithError}
							open={openDialog}
							setOpen={setOpenDialog}
							setFilesMaxSize={setFilesMaxSize}
							isSize={!isEmpty(filesMaxSize)}
							setFilesWithError={setFilesWithError}
							setUploadedFiles={setUploadedFiles}
						/>

						<ModalReport
							open={modalReport}
							setOpen={setModalReport}
							docReq={docReq}
							modalFormData={modalFormData}
							setModalFormData={setModalFormData}
						>
						</ModalReport>

						<ClaimDocumentation
							open={modalClaimDoc}
							setOpen={setModalClaimDoc}
							docReq={docReq}
						>
						</ClaimDocumentation>
					</>
				)}
		</FormStyled>
	);
};

export default IndividualSiniestro;
