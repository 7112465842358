import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
	container: {
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column",
	},
	tableHeader: {
		background: "#f8f8f8",
		width: "100%",
	},
	tableHead: {
		fontSize: "15px",
		fontWeight: "700",
	},
	rootRow: {
		"& > *": {
			borderBottom: "unset",
			fontSize: "14px",
		},
		"& > tr": {
			fontSize: "14px !important",
		},
	},
	tableCellRow: {
		fontSize: "14px",
	},
	tableHeaderRow: {
		fontSize: "14px !important",
	},
	
	expandTableRow: {
		marginTop: "10px",
	},
	tableCellHeadRow: {
		fontSize: "14px",
		fontWeight: "600",
	},
	tableCellRowItem: {
		fontSize: "13px",
	},
	button: {
		marginBottom: "30px",
		marginRight: "30px",
		borderRadius: "10px"
	}
});

export default useStyles;

