import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { includes, indexOf, isEqual } from "lodash";
import { decode } from "js-base64";
import { sliceText } from "../../../../common/constants";
import { Parser } from "html-to-react";
import { IconButton } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import RichTextEditor from "../../../../components/RichTextEditor";
import Button from "../../../../components/Button";
import ComposedFormGroup from "../../../../components/ComposedFormGroup";
import allActions from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FilesList from "../../../NewCommunication/components/FileList"
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "33.33%",
		flexShrink: 0,
		display: "flex",
		flexDirection: "column",
	},
	secondaryHeading: {
		justifyContent: "flex-end",
		alignItems: "center",
		width: "100%",
		display: "flex",

		letterSpacing: ".3px",
	},
	message: {
		font: "small/ 1.5 Arial,Helvetica,sans-serif",
		color: "#555555",
	},
	senderName: {
		width: "max-content",
		marginRight: "5px",
	},
	title: {
		display: "flex",
		alignItems: "center",
		marginBottom: "5px",
	},
	secondaryHeadingText: {
		fontFamily: "Roboto,RobotoDraft,Helvetica,Arial,sans-serif",
		fontSize: "14px !important",
		color: "#5f6368",
	},
	replyIcon: {
		fontSize: "18px !important",
	},
	richTextEditor: {
		height: "100px !important",
		marginBottom: "40px",
	},
	replyEmail: {
		display: "flex",
		flexDirection:"column",
		alignItems:"center",
		justifyContent:"center"
	},
	subject:{
		width:"80%",
		marginTop:"15px"
	},
	msj:{
		width:"80%",
		marginBottom:"15px"
	},
	containerBtnSubmit:{
		width:"80%",
		display:"flex",
		justifyContent:"flex-end",
		marginBottom:"15px"
	},
	flieList: {
		marginTop: "15px"
	}
}));

export default function EmailsDisplay({ messages, emailId, siniestroId }) {
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles();
	const [checked, setChecked] = useState([])
	const [expanded, setExpanded] = useState([emailId]);
	const [isReplying, setIsReplying] = useState(false);
	const [valueReply, setValueReply] = useState("");
	const [subject,setSubject] = useState("")
	const archivoReducer = useSelector((state) => state.archivoReducer);
	const handleChange = (panel) => (event, isExpanded) => {
		if (isEqual(panel, isReplying)) {
			setIsReplying(false);
		}
		setExpanded(
			isExpanded
			? [...expanded, panel]
			: expanded.filter((label) => !isEqual(label, panel))
			);
		};
	
	const threadId = new URLSearchParams(window.location.search).get("threadId");
	const htmlParser = new Parser();
	const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value.archivo_id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value.archivo_id);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };
	const handleReplayButton = (panelId) => () => {
		let valueToSet = panelId;
		if (isEqual(panelId, isReplying)) {
			valueToSet = false;
		}
		setIsReplying(valueToSet);
	};
	const handleSendReponse = (from) => {
		const data = {
			message:valueReply,
			threadId,
			recipient:from,
			subject,
			files:checked
		}
		dispatch(allActions.comunicationsActions.sendEmailReply(data))
		setTimeout(() => {
			history.push(`/siniestro?id=${siniestroId}`)
		}, 1500);
	};

	return (
		<div className={classes.root}>
			{messages?.map((message) => {
				const panelId = message.id;
				const from = message.payload.headers.filter((header) =>
					isEqual(header.name, "From")
				)[0].value;
				const date = message.payload.headers.filter((header) =>
					isEqual(header.name, "Date")
				)[0];
				const messageToShow = message?.payload?.body?.data;

				return (
					<>
						<Accordion
							expanded={includes(expanded, panelId)}
							onChange={handleChange(panelId)}
							key={panelId}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className={classes.heading}>
									<div className={classes.title}>
										<strong className={classes.senderName}>
											{from?.slice(0, indexOf(from, "<"))}
										</strong>
										<span className={classes.message}>
											{from?.slice(indexOf(from, "<"))}
										</span>
									</div>
									{!includes(expanded, panelId) && (
										<span className={classes.message}>
											{sliceText(message.snippet, 50)}
										</span>
									)}
								</Typography>

								<div className={classes.secondaryHeading}>
									<IconButton
										onClick={handleReplayButton(panelId)}
									>
										<ReplyIcon
											className={classes.replyIcon}
										/>
									</IconButton>
									<Typography
										className={classes.secondaryHeadingText}
									>
										{date.value}
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>
									{messageToShow
										? htmlParser.parse(
												decode(messageToShow)
										  )
										: message.snippet}
								</Typography>
							</AccordionDetails>
						</Accordion>
						{isEqual(isReplying, panelId) && (
							<Paper className={classes.replyEmail}>
								<div className={classes.subject}>
								<ComposedFormGroup
									placeholder={"Asunto"}
									onChange={(_,value) => {setSubject(value)}}
									isRequired
									
								/>
								</div>
								<div className={classes.msj} >
								<RichTextEditor

									value={valueReply}
									customStyles={classes.richTextEditor}
									onChange={(_, value) =>
										setValueReply(value)
									}
								/>
								</div>
								<FilesList
									customStyles={classes.flieList}
									files={archivoReducer.allFiles?.filter(
										(fi) => !fi.segmentacionEntidad
									)}
									checked={checked}
									handleToggle={handleToggle}
								/>
								<div className={classes.containerBtnSubmit}>
								<Button
									className={classes.submitReply}
									onClick={() =>{handleSendReponse(from,date)}}
									text={"Enviar"}
								/>
								</div>
							</Paper>
						)}
					</>
				);
			})}
		</div>
	);
}
