import React, { useEffect, useState } from "react";
import ComposedFormGroup from "../../components/ComposedFormGroup";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Dropzone from "../../components/Dropzone";
import FormGroup from "../../components/FormGroup";
import FormLabel from "../../components/FormLabel";
import moment from "moment";
import {
	ContainerStyled,
	FormContainerStyled,
	ButtonContainerStyled,
	useStyles,
} from "./styles";
import CustomAccordion from "./CustomAccordion";
import CustomDialog from "./CustomDialog";
import { useHistory, Link } from "react-router-dom";
import { FormControl } from 'react-bootstrap';
import { forEach, gt, isEmpty, isEqual, map } from "lodash";
import { Backdrop, CircularProgress, Grid, IconButton, Snackbar } from "@material-ui/core";
import allActions from "../../actions";
import { createBreadcrumbData, getBase64, SUCCESS } from "../../common/constants";
import { CREATE_SINIESTRO_SUCCESS, LOAD_FILE_SUCCESS, UPDATE_SINIESTRO_SUCCESS } from "../../actions/types";
import { Alert } from "@material-ui/lab";
import { Typography, TextField } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import StarsIcon from "@material-ui/icons/Stars";
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

function Denuncia(props) {
	const { setBreadcrumbs } = props;
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const createSiniestroReducer = useSelector(
		(state) => state.createSiniestroReducer
	);
	const archivoReducer = useSelector((state) => state.archivoReducer);
	const [openDialog, setOpenDialog] = useState(false);
	const [showNOperacion, setShowNOperacion] = useState(false);

	const [alert, setAlert] = useState({
		open: false,
		type: "error",
		message: "",
	});
	const [expandMore, setExpandMore] = useState(true);
	const [filesWithError, setFilesWithError] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const handleAlertOpen = () => setAlert({ ...alert, open: !alert.open });
	const siniestroData = createSiniestroReducer.createdSiniestro?.result || {};
	const [files, setFiles] = useState([]);
	const [checkFilesUploaded, setCheckFilesUploaded] = useState(0);
	const [filesMaxSize, setFilesMaxSize] = useState(false);
	const [data, setData] = useState({
		lugar: "",
		montoReclamo: "",
		fecha: moment.utc().format(),
		descripcion: "",
		idSiniestro: "",
		gtm: ""
	});
	const [openBackdrop, setOpenBackdrop] = useState(false);
	const handleCloseBackdrop = () => setOpenBackdrop(!openBackdrop);
	useEffect(() => {
		setBreadcrumbs([
			createBreadcrumbData(`/siniestros`, `Siniestros`),
			createBreadcrumbData(`/alta-siniestro`, `Nuevo siniestro`),
			createBreadcrumbData(`/denuncia`, `Información del siniestro`),
		]);
	}, [setBreadcrumbs]);
	//para select de horas
	let horas = [
		{
			pais: "Argentina",
			valor: "-03:00"
		},
		{
			pais: "México",
			valor: "-06:00"
		},
		{
			pais: "Perú",
			valor: "-05:00"
		},
		{
			pais: "España",
			valor: "+01:00"
		},
		{
			pais: "Ecuador",
			valor: "-05:00"
		},
		{
			pais: "Costa Rica",
			valor: "-06:00"
		},
	]
	const onSubmit = (e) => {
		e.preventDefault();

		const filesMaxSize = files.filter((file) => file.size > 15000000);

		if (!isEmpty(filesMaxSize)) {
			setFilesMaxSize(filesMaxSize.map((file) => file.name));
		} else {
			//completa información del alta de siniestro
			dispatch(
				allActions.siniestroActions.updateSiniestro({
					...data,
					idSiniestro: siniestroData.siniestro?.idSiniestro,
					cert_id: siniestroData?.siniestro?.idCert,
				})
			);
			let aux = 0;
			const filesReadyToPost = [];
			forEach(files, (file) => {
				aux += 1;
				getBase64(file).then((base64) => {
					filesReadyToPost.push({
						idEntidad: siniestroData.siniestro.idSiniestro,
						nombre: file.name,
						archivo: base64,
						entidad: "siniestro",
					})
					if (files.length === filesReadyToPost.length) {
						dispatch(allActions.archivoActions.newFiles(filesReadyToPost, dispatch))
					}
				});
			});
			setCheckFilesUploaded(aux);
		}
	};
	const onChange = (prop, value) => {
		setData({ ...data, [prop]: value });
	};
	const onChangeH = (e) => {
		setData({ ...data, [e.target.id]: e.target.value })
	}
	const handleExpandMoreFiles = () => setExpandMore(!expandMore);

	useEffect(() => {
		if (isEmpty(createSiniestroReducer.createdSiniestro)) {
			history.push("/alta-siniestro");
		} else if (
			!isEmpty(createSiniestroReducer.createdSiniestro) &&
			!createSiniestroReducer.isLoading
		) {
			if (createSiniestroReducer.error) {
				setAlert({
					open: true,
					type: "error",
					message: "Hubo un error",
				});
			}
		}
	}, [createSiniestroReducer, history]);

	useEffect(() => {
		if (
			isEqual(createSiniestroReducer.updatedSiniestro?.status, SUCCESS) &&
			isEqual(checkFilesUploaded, files.length) &&
			isEqual(uploadedFiles.length + filesWithError.length, files.length)
		) {
			if (!isEmpty(filesWithError)) {
				setOpenDialog(true);
			} else {
				dispatch({ type: CREATE_SINIESTRO_SUCCESS, payload: [] });
				dispatch({ type: UPDATE_SINIESTRO_SUCCESS, payload: {} });
				dispatch({ type: LOAD_FILE_SUCCESS, payload: [] });
				history.push("/siniestros", {
					changesSaved: true,
				});
			}
		}
	}, [
		createSiniestroReducer,
		history,
		dispatch,
		files,
		checkFilesUploaded,
		archivoReducer,
		filesWithError,
		uploadedFiles,
	]);

	useEffect(() => {
		if (
			archivoReducer.error &&
			isEmpty(
				filesWithError.find(
					(e) => e === archivoReducer?.error?.response?.data?.result
				)
			)
		) {
			setFilesWithError((prevState) => [
				...prevState,
				archivoReducer.error?.response?.data?.result,
			]);
		}
		if (
			!isEmpty(archivoReducer.uploadedFile) &&
			isEmpty(
				uploadedFiles.find(
					(e) => e.nombre === archivoReducer.uploadedFile?.nombre
				)
			)
		) {
			setUploadedFiles((prevState) => [
				...prevState,
				archivoReducer.uploadedFile,
			]);
		}
	}, [archivoReducer, dispatch, uploadedFiles, filesWithError]);

	useEffect(() => {
		if (!isEmpty(filesMaxSize)) {
			setOpenDialog(true);
		}
	}, [filesMaxSize]);

	const nroOperacion = (
		<Link
			href={`http://sistema.siwcargo.com/adm/cert_.php?ID=${siniestroData.operacion?.idCert}`}
			className={classes.typographyData}
			onMouseOver={() => setShowNOperacion(!showNOperacion)}
			onMouseLeave={() => setShowNOperacion(!showNOperacion)}
		>
			&nbsp;
			{showNOperacion
				? siniestroData.operacion?.nroOperacion
				: gt(siniestroData.operacion?.nroOperacion?.length, 16)
					? siniestroData.operacion?.nroOperacion?.slice(0, 16) + "..."
					: siniestroData.operacion?.nroOperacion}
		</Link>
	);

	return (
		<ContainerStyled data-testid="denuncia-view">
			<FormContainerStyled onSubmit={onSubmit}>
				<Typography variant="h5" className={classes.nOperacionLabel}>
					Número de operación:
					{nroOperacion}
				</Typography>
				<CustomAccordion {...siniestroData?.operacion} />
				<ComposedFormGroup
					placeholder="Lugar del siniestro"
					id="lugar"
					onChange={onChange}
					value={data.lugar}
				/>
				<Grid style={{ margin: "5px 0px 5px 0px" }}>
					<FormLabel text={"Fecha del siniestro"} />
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							ampm={false}
							inputFormat='dd/MM/yyyy HH:mm'
							value={data.fecha}
							onChange={(e) => {
								setData({
									...data,
									fecha: moment.utc(e).format()
								})
							}}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid style={{ margin: "12px 0px 15px 0px", width: "100%" }}>
					<FormLabel text={"Zona horaria"} />
					<FormControl
						id="gtm"
						as="select"
						onChange={onChangeH}
					>
						<option value={null}>Seleccionar</option>
						{horas.map((hora) => (
							<option value={hora.valor} key={hora.pais}>{hora.pais}</option>
						))}
					</FormControl>
				</Grid>
				<ComposedFormGroup
					placeholder="Monto del reclamo"
					typeInput="number"
					sideElement="USD"
					id="montoReclamo"
					onChange={onChange}
					value={data.montoReclamo}
				/>
				<ComposedFormGroup
					placeholder="Descripción del hecho"
					inputAs="textarea"
					rows={3}
					id="descripcion"
					onChange={onChange}
					value={data.descripcion}
				/>
				<FormGroup>
					<Grid className={classes.filesLabelContainer}>
						<FormLabel text="Archivos" />
						<IconButton onClick={handleExpandMoreFiles}>
							{expandMore ? (
								<ExpandMoreIcon />
							) : (
								<ExpandLessIcon />
							)}
						</IconButton>
					</Grid>
					<Collapse in={!expandMore}>
						<Grid container className={classes.documentationList}>
							{!isEmpty(siniestroData.documentacionRequerida) &&
								map(
									siniestroData.documentacionRequerida,
									(doc) => (
										<Grid
											item
											key={doc.archivoTipo_id}
											xs={12}
											md={6}
											className={
												classes.listItemDocumentation
											}
										>
											<StarsIcon
												className={classes.checkIcon}
											/>{" "}
											{doc.titulo}
										</Grid>
									)
								)}
						</Grid>
					</Collapse>
					<Dropzone
						files={files}
						setFiles={setFiles}
						isLoading={archivoReducer.isLoading}
					/>
					{archivoReducer.error &&
						<Grid className={classes.errorContainer}>
							{archivoReducer.error ? archivoReducer.error : ''}
						</Grid>}
				</FormGroup>
				<ButtonContainerStyled>
					<Button
						variant="primary"
						text="Enviar"
						type="submit"
						isLoading={
							createSiniestroReducer.isLoading ||
							archivoReducer.isLoading
						}
					/>
				</ButtonContainerStyled>
			</FormContainerStyled>
			<Snackbar
				open={alert.open}
				autoHideDuration={6000}
				onClose={handleAlertOpen}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert onClose={handleAlertOpen} severity={alert.type}>
					{alert.message}
				</Alert>
			</Snackbar>
			<CustomDialog
				filesNames={
					!isEmpty(filesMaxSize) ? filesMaxSize : filesWithError
				}
				open={openDialog}
				setOpen={setOpenDialog}
				setFilesMaxSize={setFilesMaxSize}
				isSize={!isEmpty(filesMaxSize)}
			/>
			<Backdrop
				className={classes.backdrop}
				open={
					isEqual(
						createSiniestroReducer.updatedSiniestro?.status,
						SUCCESS
					) &&
					!isEqual(
						uploadedFiles.length + filesWithError.length,
						files.length
					)
				}
				onClick={handleCloseBackdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</ContainerStyled>
	);
}

export default Denuncia;
