import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormContainerStyled, useStyles } from "./styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Grid, Button } from "@material-ui/core";
import FormControl from "react-bootstrap/FormControl";
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@mui/material/TextField';
import ComposedFormGroup from "../../components/ComposedFormGroup";
import Dropzone from '../Dropzone';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from "moment";
import allActions from "../../actions";
import { onUploadFilesLiquidate } from "../../views/IndividualSiniestro/forms/FourthForm/functions";
import { FormHelperText } from "@material-ui/core";
import FormLabel from "../FormLabel";

const Liquidate = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const archivoReducer = useSelector((state) => state.archivoReducer);
    const { openLiquidate, onClose, reserva } = props;
    const [dataLiquidate, setDataLiquidate] = useState({
        emisor: '',
        montoPagado: '',
        comentario: '',
        fecha: moment.utc().format(),
    });
    const [helperText, setHelperText] = useState("");
    const [filesLiquidate, setFilesLiquidate] = useState([]);
    const [filesMaxSize, setFilesMaxSize] = useState(false);
    const idSiniestro = new URLSearchParams(window.location.search).get("id");

    const HandleOnChangeSelect = (select) => {
        setDataLiquidate({ ...dataLiquidate, emisor: select.target.value });
    };
    const HandleOnChangeAmountPaid = (id, value) => {
        setDataLiquidate({ ...dataLiquidate, [id]: value });
    };
    const sendFiles = () => {
        onUploadFilesLiquidate(filesLiquidate, setFilesMaxSize, idSiniestro, dispatch);
    }
    const onSubmit = (e) => {
        e.preventDefault();

        if (dataLiquidate.montoPagado && dataLiquidate.fecha && dataLiquidate.comentario && dataLiquidate.emisor) {
            setHelperText("");
            dispatch(
                allActions.siniestroActions.sendDataLiquidate(idSiniestro, {
                    ...dataLiquidate,
                }))
        } else {
            setHelperText("Complete todos los campos para proseguir");
        }
    };

    return (
        <FormContainerStyled onSubmit={onSubmit}>
            <Dialog
                open={openLiquidate}
                fullWidth={true}
                maxWidth={'sm'}>
                <DialogTitle>
                    <h1 className={classes.title}>Pago</h1>
                </DialogTitle>
                <DialogContent>
                    <Grid className={classes.selectContainer}>
                        Emisor
                        <FormControl
                            as="select"
                            className={classes.select}
                            value={dataLiquidate.emisor}
                            onChange={HandleOnChangeSelect}
                        >
                            <option value=''>Seleccionar</option>
                            <option value={'SW'}>SW</option>
                            <option value={'Company'}>Compañía</option>
                        </FormControl>
                    </Grid>
                    <Grid className={classes.montoPagado} aria-required="true">
                        <ComposedFormGroup
                            placeholder={`Monto pagado  (Reserva: $${reserva === null ? 0 : reserva})`}
                            typeInput="number"
                            sideElement="USD"
                            id={'montoPagado'}
                            value={dataLiquidate.montoPagado}
                            onChange={HandleOnChangeAmountPaid}
                        />
                    </Grid>
                    <Grid className={classes.fechaPago}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormLabel text={"Fecha de pago"} />
                            <DatePicker
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat='dd/MM/yyyy'
                                value={dataLiquidate.fecha}
                                onChange={(e) => {
                                    setDataLiquidate({
                                        ...dataLiquidate,
                                        fecha: moment.utc(e).format()
                                    });
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid className={classes.añadirComentarios}>
                        <ComposedFormGroup
                            placeholder="Añadir comentarios"
                            inputAs="textarea"
                            rows={6}
                            id={"comentario"}
                            onChange={(id, value) => {
                                setDataLiquidate({
                                    ...dataLiquidate,
                                    [id]: value
                                })
                            }}
                            value={dataLiquidate.comentario}
                        />
                    </Grid>
                    <Grid className={classes.añadirArchivos}>
                        <Dropzone
                            files={filesLiquidate}
                            setFiles={setFilesLiquidate}
                        />
                    </Grid>
                    {archivoReducer.isLoading ? 'Subiendo..' :
                        <Button
                            color="primary"
                            onClick={sendFiles}
                            id='sendFileLiquidate'
                        >
                            Subir
                        </Button>}
                    {archivoReducer.error &&
                        <Grid className={classes.errorContainer}>
                            {archivoReducer.error ? archivoReducer.error : ''}
                        </Grid>}
                    {helperText && (
                        <FormHelperText
                            style={{ fontSize: "13px", marginTop: "10px" }}
                            error
                        >
                            {helperText}
                        </FormHelperText>
                    )}
                    <DialogActions>
                        {!archivoReducer.isLoading &&
                            <Button
                                color="primary"
                                onClick={onSubmit}
                                id='saveFileLiquidate'
                            >
                                Guardar
                            </Button>}
                        <Button
                            onClick={onClose}
                            color="primary"
                        >
                            Cerrar
                        </Button>
                    </DialogActions>
                </DialogContent >
            </Dialog >
        </FormContainerStyled>
    )
}


export default Liquidate;