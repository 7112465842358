import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const ContainerStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CardStyled = styled.div`
	width: 60%;
	background: #fff;
	padding: 50px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media only screen and (max-width: 600px) {
		width: 90%;
	}
`;

export const FormStyled = styled.form`
	display: flex;
	flex-direction: column;
	width: 90%;
`;

export const InputStyled = styled.input`
	margin-bottom: 10px;
`;

export const ButtonContainerStyled = styled.div`
	width: 100%;
	padding-top: 15px;
	text-align: right;
`;

export const useStyles = makeStyles((theme) => ({
	divider: {
		width: "100%",
		marginBottom: "40px",
		marginTop: "10px",
		height: "2px",
	},
	radioGroup: {
		flexDirection: "row",
	},
	breadCrumbsContainer: {
		marginBottom: "40px",
		display: "flex",
		justifyContent: "flex-start",
		width: "60%",
	},
	buttomEmail: {
		marginBottom: "15px"
	},
	quill: {
		margin: "15px 0px 15px 0px",
	},
	flieList: {
		marginTop: "15px"
	},
	selectTemplate: {
		marginBottom: "2%",
	},
	buttonsContainer: {
		display: "flex",
		width: "40%",
		justifyContent: "space-between",
		margin: "1% 0% 1.5% 0%",
	}
}));
