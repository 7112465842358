import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import FormLabel from "../../../components/FormLabel";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  rootAccordion: {
    boxShadow: "1px 1px 3px grey;",
    margin: "20px 0",
    "& .MuiAccordionSummary-root": {
      background: theme.palette.background.paper,
    },
  },
  typography: {
    fontSize: "14px",
  },
  divider: {
    width: "100%",
    margin: "10px 0",
  },
  grid: {
    margin: "10px 0",
  },
}));

function CustomAccordion(props) {
  const {
    consignee,
    destino,
    formaVenta,
    mercaderiaTransportada,
    origen,
    shipper,
    tipoRiesgo,
    sumaAsegurada,
  } = props;
  const classes = useStyles();
  return (
    <Accordion className={classes.rootAccordion} elevation={2}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <FormLabel text={"Detalles de la operación"} />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Consignee:</strong> {consignee}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Shipper:</strong> {shipper}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Forma de venta:</strong> {formaVenta}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Mercadería transportada:</strong> {mercaderiaTransportada}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Origen:</strong> {origen}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Destino:</strong> {destino}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Tipo de riesgo:</strong> {tipoRiesgo}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid}>
            <Typography variant="body2" className={classes.typography}>
              <strong>Suma asegurada:</strong> ${sumaAsegurada}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

CustomAccordion.propTypes = {};

export default CustomAccordion;
