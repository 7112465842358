import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import ChatIcon from "@material-ui/icons/Chat";
import useStyles from "./styles";
import { isArray, isEmpty, isEqual } from "lodash";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import notInfoFace from "../../assets/images/noInfoFace.svg";
import { sliceText } from "../../common/constants";
import { Button } from "@material-ui/core"
import allActions from "../../actions";
import { LoadingButton } from "@mui/lab"

const SideBar = (props) => {
	const {
		data = [],
		title,
		customStylesPaper,
		customStylesAppbar,
		onClose,
		isCloseButton,
		handleFabButtonClick,
		handleListItemClick,
		isDisabled,
		isLoading,
		unreadMessages,
		setOpenThreads,
		openThreads,
		tipo
	} = props;
	const dispatch = useDispatch()
	const LabelGmailSiniestro = useSelector((state) => state.createSiniestroReducer?.individualSiniestro?.result?.siniestro?.labelGmail_id)
	const siniestroId = useSelector((state) => state.createSiniestroReducer?.individualSiniestro?.result?.siniestro?.idSiniestro)
	const classes = useStyles();
	const [loading, setLoading] = useState(false)
	let unread = unreadMessages?.resultSizeEstimate
	let dataFormattedAndSorted =
		(isArray(data) && data.map((item) => ({
			...item,
			fecha: new Date(item.fecha),
		}))
			.sort(function (a, b) {
				return (
					new Date(b.fecha) - new Date(a.fecha)
				);
			})) ||
		[];

	const handleCommunicationType = (communicationType) => {
		switch (communicationType) {
			case "llamada":
				return <PhoneIcon />;
			case "mensaje":
				return <ChatIcon />;
			default:
				return <MailIcon />;
		}
	};

	const subheaderDateComparison = (date, index) => {
		if (isEqual(index, 0) ||
			!isEqual(dataFormattedAndSorted[index - 1].fecha.toLocaleDateString(), date.toLocaleDateString())) {
			return (
				<ListSubheader className={classes.subheader}>
					{date.toLocaleDateString()}
				</ListSubheader>
			);
		}
	};
	const getThreadsUncreated = () => {
		dispatch(allActions.comunicationsActions.getThreadsUncreated(LabelGmailSiniestro, siniestroId, tipo, 'cliente'))
		dispatch(allActions.comunicationsActions.getThreadsUncreated(LabelGmailSiniestro, siniestroId, tipo, 'compania'))
		dispatch(allActions.comunicationsActions.getThreadsUncreated(LabelGmailSiniestro, siniestroId, tipo, 'ajustador'))
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
			window.location.reload(true)
		}, 4000);
	}

	const onListItemClick = (comunicacion) => (ev) =>
		handleListItemClick(comunicacion);

	const nothingHere = () => (
		<div className={classes.noInfoContainer}>
			{isLoading ? (
				<CircularProgress size={50} />
			) : (
				<>
					<img src={notInfoFace} alt="No info" />
					<Typography variant="body1" className={classes.noInfoText}>
						Nada por aqui
					</Typography>
				</>
			)}
		</div>
	);

	return (
		<React.Fragment>
			<CssBaseline />
			<Paper
				data-testid="sidebar"
				square
				className={clsx(classes.paper, customStylesPaper)}
				elevation={3}
			>
				<div className={classes.titleContainer}>
					<Typography variant="h5" gutterBottom>
						{title}
					</Typography>
					<Button
						variant="contained"
						aria-label="add"
						color="primary"
						onClick={() => getThreadsUncreated()}
					>
						{
							loading ?
								<LoadingButton
									loading
									variant="contained"
									color="primary"
									aria-label="add"
								>
									Actualizar mensajes
								</LoadingButton>

								: "Actualizar mensajes"
						}
					</Button>
					{isCloseButton && (
						<IconButton
							onClick={onClose}
							className={classes.closeButton}
						>
							<CloseIcon className={classes.closeIcon} />
						</IconButton>
					)}
				</div>
				{isEmpty(dataFormattedAndSorted) ? (
					<>{nothingHere()}</>
				) : (
					<List className={classes.list}>
						{dataFormattedAndSorted.map((comunicacion, index) => (
							<React.Fragment key={comunicacion.comunicacion_id || comunicacion.emailId}>
								{subheaderDateComparison(comunicacion.fecha, index)}
								<ListItem
									button
									onClick={onListItemClick(comunicacion)}
								>
									<ListItemAvatar>
										<Avatar alt={comunicacion.titulo}>
											{handleCommunicationType(comunicacion.titulo)}
										</Avatar>
									</ListItemAvatar>
									<Typography variant="h5">
										{sliceText(comunicacion.asunto, 45)}
									</Typography>
								</ListItem>
							</React.Fragment>
						))}
					</List>
				)}
			</Paper>
			{!isDisabled && (
				<AppBar
					position="fixed"
					color="primary"
					className={clsx(classes.appBar, customStylesAppbar)}
				>
					<Toolbar>
						<Fab
							color="secondary"
							aria-label="add"
							onClick={handleFabButtonClick}
							className={classes.fabButton}
						>
							<AddIcon />
						</Fab>
						<div className={classes.grow}>
							Tienes {unread} mensajes sin leer
							<Button
								className={classes.buttonMessage}
								variant="contained"
								aria-label="add"
								color="secondary"
								onClick={() => { setOpenThreads(!openThreads) }}
							>
								Ver
							</Button>
						</div>
					</Toolbar>
				</AppBar>
			)}
		</React.Fragment>
	);
};

SideBar.propTypes = {
	data: PropTypes.array,
	title: PropTypes.string,
};

export default SideBar;
