import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Snackbar, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";
import { Alert } from "@material-ui/lab";
import { CREATE_SINIESTRO_NUMBER_SUCCESS } from "../../actions/types";
import { createBreadcrumbData, getDaysDifference } from "../../common/constants";
import { isEqual } from "lodash";
import MaterialTable from "material-table";
import Search from '@material-ui/icons/Search';
import { Oval } from 'react-loader-spinner'
import * as XLSX from 'xlsx';
import { FcDownload } from "react-icons/fc";
function Inicio(props) {
	const { setBreadcrumbs } = props;
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const siniestroReducer = useSelector((state) => state.siniestroReducer);
	const [alert, setAlert] = useState({
		open: false,
		type: "error",
		message: "",
	});

	useEffect(() => {
		setBreadcrumbs([createBreadcrumbData(`/siniestros`, `Siniestros`)]);
	}, [setBreadcrumbs]);

	useEffect(() => {
		dispatch(allActions.siniestroActions.getAllSiniestros());
		dispatch({ type: CREATE_SINIESTRO_NUMBER_SUCCESS, payload: [] });

		if (history.location.state?.changesSaved)
			setAlert({
				open: true,
				type: "success",
				message: "Cambios guardados exitosamente",
			});
	}, [dispatch, history]);

	const handleAlertOpen = () => setAlert({ ...alert, open: !alert.open });

	const columns = [
		{ field: "siniestroNro", title: "N°siniestro", cellStyle: { width: "15%" } },
		{ field: "idSiniestro", title: "ID", cellStyle: { width: "10%" } },
		{ field: "tomador", title: "Tomador", cellStyle: { width: "35%" } },
		{ field: "dias", title: "Días", cellStyle: { width: "25%" } },
	];
	const columnsProgress = [
		...columns, { field: "estadoTitulo", title: "Estado", cellStyle: { width: "15%" } }
	];

	const siniestros = siniestroReducer.allSiniestros?.result?.siniestros?.map((siniestro) => ({
		...siniestro,
		id: Math.ceil(Math.random() * 1000),
		dias: getDaysDifference(new Date(), siniestro.fechaDenuncia),
	}));

	const newSiniestros = siniestros?.filter((siniestro) => isEqual(siniestro.estado, 2) || isEqual(siniestro.estado, 1));
	const orderDescNewSiniestros = newSiniestros?.reverse();

	const progressSiniestros = siniestros?.filter((siniestro) => {
		if (siniestro.estado === 10 || siniestro.estado === 11 || siniestro.estado === 12) {
			return false
		}
		return true;
	});
	const orderDescProgressSiniestros = progressSiniestros?.reverse();

	const searchIndividualSiniestro = (idSiniestro) => {
		window.location.assign(`/siniestro?id=${idSiniestro}`)
	};

	function exportToExcel(data, fileName) {
		const filteredData = data.map(row => {
			const filteredRow = {};
			Object.keys(row).forEach(key => {
				if (key !== 'tableData') {
					filteredRow[key] = row[key];
				}
			});
			return filteredRow;
		});
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(filteredData);
		XLSX.utils.book_append_sheet(workbook, worksheet, "Siniestros");
		XLSX.writeFile(workbook, fileName);
	}

	return (
		<Grid container className={classes.container}>
			<Typography variant="h6" className={classes.title}>
				Nuevos siniestros
			</Typography>
			{!orderDescNewSiniestros?.length ?
				<Grid className={classes.ovalContainer}>
					<Oval
						height={80}
						width={80}
						color="#1467A1"
						wrapperStyle={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%"
						}}
						secondaryColor="#1467A1"
					/>
				</Grid> :
				<MaterialTable
					style={{ width: "100%" }}
					columns={columns}
					data={orderDescNewSiniestros}
					title=""
					icons={{
						Search: () => <Search style={{ fontSize: "18px" }} />,
						Filter: 'none'
					}}
					actions={[
						{
							icon: "search",
							cellStyle: { backgroundColor: "red" },
							iconProps: { style: { fontSize: "20px", color: "blue" } },
							tooltip: "visualizar",
							onClick: (_, rowData) => { searchIndividualSiniestro(rowData.idSiniestro) },
						},
						{
							icon: ({ color }) => <FcDownload style={{ color }} />,
							tooltip: 'Descargar Excel',
							isFreeAction: true,
							onClick: () => exportToExcel(orderDescNewSiniestros, 'Nuevos-siniestros.xlsx')
						}
					]}
					options={{
						filtering: true,
						headerStyle: { whiteSpace: 'nowrap' },
						paginationType: "stepped",
						columnsButton: true,
					}}
				/>}
			<Typography variant="h6" className={classes.title}>
				Siniestros en proceso
			</Typography>
			{!orderDescProgressSiniestros?.length ?
				<Grid className={classes.ovalContainer}>
					<Oval
						height={80}
						width={80}
						color="#1467A1"
						wrapperStyle={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%"
						}}
						secondaryColor="#1467A1"
					/>
				</Grid> :
				<MaterialTable
					style={{ width: "100%" }}
					columns={columnsProgress}
					data={orderDescProgressSiniestros}
					title=""
					icons={{
						Search: () => <Search style={{ fontSize: "18px" }} />,
						Filter: 'none'
					}}
					actions={[
						{
							icon: "search",
							iconProps: { style: { fontSize: "20px", color: "blue" } },
							tooltip: "visualizar",
							onClick: (_, rowData) => { searchIndividualSiniestro(rowData.idSiniestro) },
						}, {
							icon: ({ color }) => <FcDownload style={{ color }} />,
							tooltip: 'Descargar Excel',
							isFreeAction: true,
							onClick: () => exportToExcel(orderDescProgressSiniestros, 'Siniestros-proceso.xlsx')
						}
					]}
					options={{
						filtering: true,
						headerStyle: { whiteSpace: 'nowrap' },
						paginationType: "stepped",
						columnsButton: true,
					}}
				/>}
			<Snackbar
				open={alert.open}
				autoHideDuration={6000}
				onClose={handleAlertOpen}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert onClose={handleAlertOpen} severity={alert.type}>
					{alert.message}
				</Alert>
			</Snackbar>
		</Grid>
	);
}

Inicio.propTypes = {};

export default Inicio;
