import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "../../../../components/Select";
import Delete from "@material-ui/icons/Delete";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import AlertDialog from "../../../../components/Dialog";
import { gte, isEmpty, isEqual } from "lodash";
import notInfoFace from "../../../../assets/images/noInfoFace.svg";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";
import { useEffect } from "react";
import { CATEGORIZE_ARCHIVO_SUCCESS, DELETE_ARCHIVO_SUCCESS } from "../../../../actions/types";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 900,
		backgroundColor: theme.palette.background.paper,
		border: "3px solid #ccc",
		borderRadius: "4px",
		padding: "10px",
		maxHeight: 300,
		overflowY: "scroll",
	},
	listItem: {
		"& .MuiTypography-root": {
			fontSize: "15px !important",
		},
	},
	secondaryActionContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "400px",
	},
	deleteIconButton: {
		marginLeft: "15px",
	},
	deleteIcon: {
		fontSize: "20px",
	},
	noInfoContainer: {
		width: "100%",
		padding: "90px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& img": {
			marginBottom: "20px",
			width: "90px",
			height: "90px",
		},
	},
	noInfoText: {
		fontSize: "16.5px !important",
	},
}));

const FilesList = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { files, isEverythingDisabled, setDocReq, docReq } = props;
	const archivoTipoReducer = useSelector((state) => state.archivoTipoReducer);
	const archivoReducer = useSelector((state) => state.archivoReducer);
	const siniestroId = new URLSearchParams(window.location.search).get("id");
	const [archivoUpdating, setArchivoUpdating] = useState(false);
	const [fileToDelete, setFileToDelete] = useState({});

	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const handleOpenDialog = (fileToDelete) => () => {
		setFileToDelete(fileToDelete);
		setIsOpenDialog(!isOpenDialog);
	};

	const handleDeleteItem = (e) => {
		setArchivoUpdating(fileToDelete.archivo_id);
		setIsOpenDialog(!isOpenDialog);
		dispatch(allActions.archivoActions.deleteArchivo(fileToDelete.archivo_id, `${fileToDelete.ruta}/${fileToDelete.nombre}`)
		);
	};

	const nothingHere = (
		<div className={classes.noInfoContainer}>
			<img src={notInfoFace} alt="No info" />
			<Typography variant="body1" className={classes.noInfoText}>
				Nada por aqui
			</Typography>
		</div>
	);

	const onChangeSelect = (archivoId, archivoTipoIdPrevious) => (e, archivoTipo) => {
		setArchivoUpdating(archivoId);

		const newDocReq = docReq?.map((docReqItem) => {
			if (docReqItem.archivoTipo_id === archivoTipo.archivoTipo_id) {
				docReqItem.isSelected = true;
			}
			if (docReqItem.archivoTipo_id === archivoTipoIdPrevious) {
				docReqItem.isSelected = false;
			} return docReqItem;
		})
		setDocReq(newDocReq)

		dispatch(allActions.archivoActions.categorizeArchivo(archivoId, archivoTipo?.archivoTipo_id || null));
	};

	useEffect(() => {
		if (!isEmpty(archivoReducer.archivoCategorized)) {
			dispatch(allActions.archivoActions.getAllFilesBySiniestro(siniestroId));
			dispatch({ type: CATEGORIZE_ARCHIVO_SUCCESS, payload: [] });
			setArchivoUpdating(false);
		}

		if (!isEmpty(archivoReducer.archivoDeleted)) {
			dispatch(allActions.archivoActions.getAllFilesBySiniestro(siniestroId));
			dispatch({ type: DELETE_ARCHIVO_SUCCESS, payload: [] });
			setArchivoUpdating(false);
		}
	}, [archivoReducer, dispatch, siniestroId]);

	return (
		<>
			<List className={classes.root}>
				{!isEmpty(files)
					? files.map((value) => {
						const archivoTitulo = archivoTipoReducer.archivos?.find((ar) =>
							ar?.archivoTipo_id === value?.archivoTipo_id)?.titulo;
						return (
							<ListItem
								key={value.archivo_id}
								className={classes.listItem}
								dense
								button
							>
								<ListItemText primary={value.nombre} />

								<ListItemSecondaryAction className={classes.secondaryActionContainer}>
									{!isEverythingDisabled ? (
										<>
											<Select
												onChange={onChangeSelect(value.archivo_id, value.archivoTipo_id)}
												options={archivoTipoReducer.archivos}
												id={"archivoTitulo"}
												keyToShow={"titulo"}
												value={archivoTitulo}
												customStyles={classes.selectCategorize}
											/>
											<IconButton
												className={classes.deleteIconButton}
												onClick={handleOpenDialog(value)}
											>
												{archivoReducer.isLoading && isEqual(archivoUpdating, value.archivo_id) ?
													<CircularProgress size={20} /> :
													<Delete className={classes.deleteIcon} />}
											</IconButton>
										</>
									) : (
										<ListItemText
											secondary={archivoTitulo ? gte(archivoTitulo.length, 50) ?
												archivoTitulo?.slice(0, 50) + "..." : archivoTitulo : "Sin categoría"}
										/>
									)}
									<a
										href={value.url}
										target="_blank"
										rel="noreferrer"
									>
										<IconButton>
											<VisibilityIcon className={classes.deleteIcon} />
										</IconButton>
									</a>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})
					: nothingHere}
			</List>
			<AlertDialog
				text="Eliminando este ítem no podrás recuperar la información del mismo"
				title="¿Estás seguro de eliminar el archivo?"
				isOpen={isOpenDialog}
				handleOpen={handleOpenDialog(null)}
				onClickAccept={handleDeleteItem}
			/>
		</>
	);
};

export default FilesList;
