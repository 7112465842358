import {
    SEND_TEMPLATE_START,
    SEND_TEMPLATE_SUCCESS,
    SEND_TEMPLATE_ERROR,
    GET_TEMPLATE_START,
    GET_TEMPLATE_SUCCESS,
    IS_LOADED_SUCCESS,
    GET_TEMPLATE_ERROR,
    DELETE_TEMPLATE_START,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_ERROR,
    UP_DATE_TEMPLATE_START,
    UP_DATE_TEMPLATE_SUCCESS,
    UP_DATE_TEMPLATE_ERROR,
    GET_TEMPLATES_START,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_ERROR,
    GET_ACTION_START,
    GET_ACTION_SUCCESS,
    GET_ACTION_ERROR,
    ACTIVETE_IS_VIEW_TEMPLATE,
    ACTIVETE_IS_EDIT_TEMPLATE,
    GET_VARIABLES_START,
    GET_VARIABLES_SUCCESS,
    GET_VARIABLES_ERROR,
    GET_TEMPLATE_DECODED_START,
    GET_TEMPLATE_DECODED_SUCCESS,
    GET_TEMPLATE_DECODED_ERROR,
} from "../actions/types";

let initialState = {
    newTemplate: [],
    individualTemplate: [],
    isSelectedView: false,
    isSelectedEdit: false,
    templateDeleted: [],
    updatedTemplate: [],
    allTemplates: [],
    allActions: [],
    allVariables: [],
    templateSiniestro: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

const templatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_TEMPLATE_START:
            return {
                ...state,
                isLoading: true,
            };
        case SEND_TEMPLATE_SUCCESS:
            return {
                ...state,
                newTemplate: action.payload,
                isLoading: false,
                error: null,
            };
        case SEND_TEMPLATE_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case GET_TEMPLATE_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                individualTemplate: action.payload,
                isLoading: false,
                error: null,
            };
        case IS_LOADED_SUCCESS:
            return {
                ...state,
                isLoaded: true,
            };
        case GET_TEMPLATE_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case ACTIVETE_IS_EDIT_TEMPLATE:
            return {
                ...state,
                isSelectedEdit: action.payload,
            };
        case ACTIVETE_IS_VIEW_TEMPLATE:
            return {
                ...state,
                isSelectedView: action.payload,
            };
        case DELETE_TEMPLATE_START:
            return {
                ...state,
                isLoading: true,
            };
        case DELETE_TEMPLATE_SUCCESS:
            return {
                ...state,
                templateDeleted: action.payload,
                isLoading: false,
                error: null,
            };
        case DELETE_TEMPLATE_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case UP_DATE_TEMPLATE_START:
            return {
                ...state,
                isLoading: true,
            };
        case UP_DATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                updatedTemplate: action.payload,
                isLoading: false,
                error: null,
            };
        case UP_DATE_TEMPLATE_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case GET_TEMPLATES_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                allTemplates: action.payload,
                isLoading: false,
                error: null,
            };
        case GET_TEMPLATES_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case GET_ACTION_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_ACTION_SUCCESS:
            return {
                ...state,
                allActions: action.payload,
                isLoading: false,
                error: null,
            };
        case GET_ACTION_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case GET_VARIABLES_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_VARIABLES_SUCCESS:
            return {
                ...state,
                allVariables: action.payload,
                isLoading: false,
                error: null,
            };
        case GET_VARIABLES_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case GET_TEMPLATE_DECODED_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_TEMPLATE_DECODED_SUCCESS:
            return {
                ...state,
                templateSiniestro: action.payload,
                isLoading: false,
                error: null,
            };
        case GET_TEMPLATE_DECODED_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default templatesReducer;