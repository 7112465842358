import React, { useEffect, useState } from 'react'
import { createBreadcrumbData } from "../../common/constants";
import { Grid, Paper } from '@mui/material'
import useStyles from "./styles";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormLabel } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Button, TextField } from '@mui/material'
import { useHistory } from "react-router-dom"
import TableEstado from "./components/Table/TableEstado"
import TableTipo from "./components/Table/TableTipo"
import TableClientes from './components/Table/TableClientes'
import TablePolizas from './components/Table/TablePolizas'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { clientWithMoreSiniestros, moreDelay, datosPorPoliza2, lessDelay, totalReserva, moreDelayId, lessDelayId, compSelect, polizaSiniestro, estadoSiniestro, tipoSiniestro } from "./functions/index"
import allActions from "../../actions"
import Graph from './components/Chart';

export default function Dashboard(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles();
    const { setBreadcrumbs } = props
    const allSiniestros = useSelector((state) => state.siniestroReducer?.allSiniestros?.result?.siniestros);
    const siniestros = useSelector((state) => state.siniestroReducer?.siniestrosFilter?.result?.siniestros);
    const siniestrosL = siniestros?.filter((s) => s.estadoTitulo === "Liquidado" || s.estadoTitulo === "Rechazado" || s.estadoTitulo === "Dado de baja");
    const [date, setDate] = useState({
        initialDate: moment("01-01-2021").format(),
        finalDate: moment.utc().format(),
        comp: null
    });
    const [data, setData] = useState({
        lessDelay: '',
        moreDelay: '',
        cantSiniestros: '',
        totalReserva: '',
        clientWithMoreSiniestros: '',
        compSelect: '',
        infoComps: '',
    });

    useEffect(() => {
        setBreadcrumbs([
            createBreadcrumbData(`/dashboard`, `Dashboard`),
        ]);

    }, [setBreadcrumbs]);

    useEffect(() => {
        dispatch(allActions.siniestroActions.getAllSiniestros())
    }, []);

    useEffect(() => {
        if (siniestros) {
            setData({
                lessDelay: lessDelay(siniestros),
                moreDelay: moreDelay(siniestros),
                cantSiniestros: siniestros?.length,
                totalReserva: totalReserva(siniestros),
                clientWithMoreSiniestros: clientWithMoreSiniestros(siniestros),
                compSelect: compSelect(allSiniestros),
                infoComps: datosPorPoliza2(siniestros),
                estados: [],
                tipos: [],
            })
        };
    }, [siniestros]);

    useEffect(() => {
        if (siniestros) {
            var estados = estadoSiniestro(siniestros)
            var tipos = tipoSiniestro(siniestros)
            if (data.estados) {
                estados.map((arr) => {
                    return data.estados.push(Object.values(arr))
                })
                data.estados.unshift(["Tipo", "Cantidad", "Reserva"])
            };
            if (data.tipos) {
                tipos.map((arr) => {
                    return data.tipos.push(Object.values(arr))
                })
                data.tipos.unshift(["Tipo", "Cantidad", "Reserva"])
            };
        };
    }, [data]);

    const optionsEstados = { title: "Estados de siniestros" };
    const optionsTipos = { title: "Tipos de siniestros" };

    const onClick = (id) => { history.push(`/siniestro?id=${id}`) }

    const onClickFilter = () => {
        dispatch(allActions.siniestroActions.getAllSiniestrosFilter(date))
    };

    const dataPoliza1 = (comp) => {
        return (
            <Grid key={comp.name}>
                <FormLabel className={classes.compañia}>{comp.name}</FormLabel>
                {dataPoliza2(comp.polizas)}
            </Grid>
        )
    };

    const dataPoliza2 = (polizas) => {
        let polizaData = [];
        polizaData = polizaSiniestro(siniestros);
        return polizas.map((p) => {
            const find = polizaData.find(pd => pd.name === p.numero)
            return (
                <Grid key={p.numero}>
                    <span className={classes.poliza}>Poliza: <span style={{ fontSize: "21px" }}>{p.numero}</span></span>
                    <Grid className={classes.dataPolizaContainer}>
                        <Grid style={{ textAlign: "center" }}>
                            <Grid className={classes.dataPoliza}>{find?.cantidad}</Grid>
                            <span>Cantidad</span>
                        </Grid>
                        <Grid style={{ textAlign: "center" }}>
                            <Grid className={classes.dataPoliza}>${(Math.round(find?.reserva * 100) / 100).toLocaleString()}</Grid>
                            <span>Reserva</span>
                        </Grid>
                    </Grid>
                    {dataPoliza3(p.siniestros)}
                </Grid>
            )
        })
    };

    const dataPoliza3 = (siniestros) => {
        return (
            <Grid className={classes.graphics} container spacing={4}>
                <Grid className={classes.graphics} container item sm={6}>
                    <TableEstado siniestros={siniestros}></TableEstado>
                    <TableTipo siniestros={siniestros}></TableTipo>
                </Grid>
                <Grid className={classes.graphics} container item sm={6}>
                    <TableClientes isForCompany={true} siniestros={siniestros}></TableClientes>
                </Grid>
            </Grid>
        )
    };

    return (
        <Grid className={classes.container} container spacing={4}>
            <FormLabel className={classes.title}>Datos generales</FormLabel>
            <Grid item xs={12} className={classes.filterContainer}>
                <Paper className={classes.filter} elevation={4}>
                    <Grid className={classes.gridDate}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat='dd/MM/yyyy'
                                className={classes.date}
                                label="Desde"
                                id="initialDate"
                                value={date.initialDate}
                                onChange={(e) => setDate({
                                    ...date,
                                    initialDate: moment.utc(e).format()
                                })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                className={classes.date}
                                label="Hasta"
                                inputFormat='dd/MM/yyyy'
                                id="finalDate"
                                value={date.finalDate}
                                onChange={(e) => setDate({
                                    ...date,
                                    finalDate: moment.utc(e).format()
                                })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid className={classes.gridSelect}>
                        <Grid>
                            <label for="comp">Compañía</label>
                            <FormControl
                                id="comp"
                                as="select"
                                onChange={(e) => {
                                    setDate({
                                        ...date,
                                        comp: e.target.value,
                                    })
                                }}
                            >
                                <option value={null}>Seleccionar</option>
                                {Object.keys(data.compSelect).map((compañia) => {
                                    return <option value={compañia}>{compañia}</option>
                                })}
                            </FormControl>
                        </Grid>
                        <Button onClick={onClickFilter} className={classes.filterButton} variant="contained">Filtrar</Button>
                    </Grid>
                </Paper>
            </Grid>
            {siniestros ?
                <Grid container className={classes.infoContainer}>
                    <Paper elevation={4} className={classes.info}>
                        <FormLabel>Siniestro con más demora</FormLabel>
                        <a
                            className={classes.cursor}
                            onClick={() => onClick(moreDelayId(siniestros))}
                        >
                            <FormLabel className={classes.demoras}>{data.moreDelay}</FormLabel>
                        </a>
                        <FormLabel>Siniestro con menos demora</FormLabel>
                        <a
                            className={classes.cursor}
                            onClick={() => onClick(lessDelayId(siniestrosL))}
                        >
                            <FormLabel className={classes.demoras}>{data.lessDelay}</FormLabel>
                        </a>
                    </Paper>
                    <Paper elevation={4} className={classes.info}>
                        <FormLabel>Cantidad de siniestros</FormLabel>
                        <FormLabel className={classes.numbers}>{data.cantSiniestros}</FormLabel>
                    </Paper>
                    <Paper elevation={4} className={classes.info}>
                        <FormLabel>Total reserva</FormLabel>
                        <FormLabel className={classes.numbers}>${data.totalReserva}</FormLabel>
                    </Paper>
                    <Paper elevation={4} className={classes.info}>
                        <FormLabel>Clientes con mas siniestros</FormLabel>
                        <FormLabel className={classes.textImportant}>{data.clientWithMoreSiniestros}</FormLabel>
                    </Paper>
                </Grid>
                :
                <FormLabel>Falta Info</FormLabel>
            }
            <Grid className={classes.graphics} container item sm={6}>
                {data.estados && <Graph data={data.estados} options={optionsEstados} key={data.estados} />}
            </Grid>
            <Grid className={classes.graphics} container item sm={6}>
                {data.tipos && <Graph data={data.tipos} options={optionsTipos} key={data.tipos} />}
            </Grid>
            <Grid className={classes.graphics} container item sm={6}>
                <TableEstado siniestros={siniestros}></TableEstado>
                <TableTipo siniestros={siniestros}></TableTipo>
            </Grid>
            <Grid className={classes.graphics} container item sm={6}>
                <TableClientes isForCompany={false} siniestros={siniestros}></TableClientes>
                <TablePolizas siniestros={siniestros}></TablePolizas>
            </Grid>
            <FormLabel className={classes.title}>Datos por compañías</FormLabel>
            {data.infoComps ?
                data.infoComps.map((comp) => {
                    return dataPoliza1(comp)
                }) :
                <FormLabel className={classes.title}>Falta info</FormLabel>
            }
        </Grid>
    )
};
