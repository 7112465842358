import {
  GET_COMUNICATIONS_START,
  GET_COMUNICATIONS_SUCCESS,
  GET_COMUNICATIONS_ERROR,
  GET_COMUNICATIONS_TYPES_SUCCESS,
  NEW_COMUNICATION_SUCCESS,
  GET_SINGLE_COMUNICATION_SUCCESS,
  GET_UNREAD_MESSAGES_SUCCES,
  GET_UNREAD_MESSAGES_ERROR,
  GET_NEW_THREADS_START,
  GET_NEW_THREADS_ERROR,
  GET_NEW_THREADS_SUCCES
} from "../actions/types";

let initialState = {
  comunicationsBySiniestro: [],
  comunicationsTypes: [],
  newComunication: {},
  messagesUnread:{},
  comunication: {},
  isLoading: false,
  error: null,
};

const comunicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMUNICATIONS_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMUNICATIONS_SUCCESS:
      return {
        ...state,
        comunicationsBySiniestro: action.payload,
        isLoading: false,
      };
    case GET_COMUNICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_COMUNICATIONS_TYPES_SUCCESS:
      return {
        ...state,
        comunicationsTypes: action.payload,
        isLoading: false,
      };
    case NEW_COMUNICATION_SUCCESS:
      return {
        ...state,
        newComunication: action.payload,
        isLoading: false,
      };
    case GET_SINGLE_COMUNICATION_SUCCESS:
      return {
        ...state,
        comunication: action.payload,
        isLoading: false,
      };
    case GET_UNREAD_MESSAGES_SUCCES:
      return {
        ...state,
        messagesUnread: action.payload,
        isLoading:false,
        error: null
      }
    case GET_UNREAD_MESSAGES_ERROR:
      return {
        ...state,
        error:action.payload,
        isLoading:false
      }
    case GET_NEW_THREADS_START:
      return {
        ...state,
        isLoading:true
      }
    case GET_NEW_THREADS_SUCCES:
      return {
        ...state,
        isLoading:false
      }
    case GET_NEW_THREADS_ERROR:
      return {
        ...state,
        isLoading:false,
        error: action.payload
      }
    default:
      return state;
  }
};

export default comunicationsReducer;
