import React from "react";
import PropTypes from "prop-types";
import { LabelStyled } from "./styles";

const FormLabel = (props) => {
	const { text, customStyles } = props;

	return (
		<div data-testid="form-label">
			<LabelStyled className={customStyles}>{text}</LabelStyled>
		</div>
	);
};

FormLabel.propTypes = {
	text: PropTypes.string,
};

export default FormLabel;
